import React from 'react';
import { Switch, Route } from 'react-router-dom';
import DefaultLayout from './layout/DefaultLayout';
import UserLayout from './layout/UserLayout';
import AuthRoute from './components/AuthRoute';
import ViewTransition from './components/ViewTransition';

import Home from './views/Home';
import PasswordReset from './views/PasswordReset';
import ConfirmEmail from './views/ConfirmEmail';
import RegisterInitial from './views/register/Initial';
import RegisterConfirmEmail from './views/register/ConfirmEmail';
import RegisterReview from './views/register/Review';
import RegisterFinal from './views/register/Final';
import IdentityVerification from './views/IdentityVerification';
import NotFound from './views/NotFound';
import PlanCreate from './views/plans/Create';
import RegisterTransfer from './views/register/Transfer';
import FindOutMore from 'views/FindOutMore';

import UserInitialSetup from './views/user/InitialSetup';
import UserInfo from './views/user/Info';
import UserFaq from './views/user/info/Faq';
import UserSettings from './views/user/Settings';
import UserSettingsPersonalInfo from './views/user/settings/PersonalInformation';
import UserSettingsSecurity from './views/user/settings/Security';
import UserSettingsLegalNotices from './views/user/settings/security/LegalNotices';
import UserSettingsChangePassword from './views/user/settings/security/ChangePassword';
import UserAppSettings from './views/user/settings/AppSettings';
import UserAppSettingsLanguages from './views/user/settings/AppSettingsLanguages';
import UserTransfer from './views/user/Transfer';
import UserTransferWithdrawIndex from './views/user/transfer/withdraw/Index';
import UserTransferWithdrawCreate from './views/user/transfer/withdraw/Create';
import UserTransferTopUpIndex from './views/user/transfer/topup/Index';
import UserTransferTopUpCreate from './views/user/transfer/topup/Create';
import UserPlans from './views/user/plans/Index';
import UserPlansShow from './views/user/plans/Show';
import UserPlansClose from './views/user/plans/Close';
import UserPlansClosed from './views/user/plans/Closed';
import UserContact from './views/user/Contact';
import UserMessagesIndex from './views/user/messages/Index';

const Routes = () => {
  return (
    <Switch>
      <Route
        path="/user/:path?"
        render={({ location, history: { action } }) => (
          <UserLayout>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <ViewTransition pageKey={location.pathname} historyAction={action} {...location.state}>
              <Switch location={location}>
                <AuthRoute path="/user/initial-setup" onboardingRequired component={() => <UserInitialSetup />} />
                <AuthRoute path="/user/plans/close/:planId" onboardingRequired component={() => <UserPlansClose />} />
                <AuthRoute path="/user/plans/closed/:planId" onboardingRequired component={() => <UserPlansClosed />} />
                <AuthRoute path="/user/plans/:planId/:view?" onboardingRequired component={() => <UserPlansShow />} />
                <AuthRoute path="/user/plans" onboardingRequired component={() => <UserPlans />} />

                <AuthRoute
                  path="/user/transfer/top-up/:planId"
                  onboardingRequired
                  component={() => <UserTransferTopUpCreate />}
                />
                <AuthRoute
                  path="/user/transfer/top-up"
                  onboardingRequired
                  component={() => <UserTransferTopUpIndex />}
                />
                <AuthRoute
                  path="/user/transfer/withdraw/:planId"
                  onboardingRequired
                  component={() => <UserTransferWithdrawCreate />}
                />
                <AuthRoute
                  path="/user/transfer/withdraw"
                  onboardingRequired
                  component={() => <UserTransferWithdrawIndex />}
                />
                <AuthRoute path="/user/transfer" onboardingRequired component={() => <UserTransfer />} />

                <AuthRoute
                  path="/user/settings/personal"
                  onboardingRequired
                  component={() => <UserSettingsPersonalInfo />}
                />
                <AuthRoute
                  path="/user/settings/security/change-password"
                  onboardingRequired
                  component={() => <UserSettingsChangePassword />}
                />
                <AuthRoute
                  path="/user/settings/security/legal-notices"
                  onboardingRequired
                  component={() => <UserSettingsLegalNotices />}
                />
                <AuthRoute
                  path="/user/settings/security"
                  onboardingRequired
                  component={() => <UserSettingsSecurity />}
                />
                <AuthRoute
                  path="/user/settings/app/languages"
                  onboardingRequired
                  component={() => <UserAppSettingsLanguages />}
                />
                <AuthRoute path="/user/settings/app" onboardingRequired component={() => <UserAppSettings />} />
                <AuthRoute path="/user/settings" onboardingRequired component={() => <UserSettings />} />

                <Route path="/user/contact" component={() => <UserContact />} />

                <AuthRoute path="/user/messages" component={() => <UserMessagesIndex />} />

                <Route path="/user/info/faq" component={() => <UserFaq />} />
                <AuthRoute path="/user/info" onboardingRequired component={() => <UserInfo />} />
                <AuthRoute path="*" component={() => <NotFound />} />
              </Switch>
            </ViewTransition>
          </UserLayout>
        )}
      />
      <Route path="*">
        <DefaultLayout>
          <Switch>
            <Route exact path="/" component={() => <Home />} />
            <Route exact path="/password-reset/" component={PasswordReset} />
            <Route exact path="/confirm-email/:userId" component={ConfirmEmail} />
            <Route exact path="/register" component={() => <RegisterInitial />} />
            <Route exact path="/find-out-more" component={() => <FindOutMore />} />
            <AuthRoute exact path="/plans/create" component={() => <PlanCreate />} />
            <AuthRoute exact path="/register/transfer" component={() => <RegisterTransfer />} />
            <AuthRoute exact path="/register/confirm-email" component={() => <RegisterConfirmEmail />} />
            <AuthRoute exact path="/register/review" component={() => <RegisterReview />} />
            <AuthRoute exact path="/register/final/:view?" component={() => <RegisterFinal />} />
            <AuthRoute exact path="/identity-verification/:view?" component={() => <IdentityVerification />} />
            <Route path="*" component={() => <NotFound />} />
          </Switch>
        </DefaultLayout>
      </Route>
    </Switch>
  );
};

export default Routes;
