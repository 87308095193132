import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import AssetDetails from 'components/AssetDetails';

const StockDetails = () => {
  const {
    location: {
      state: { asset },
    },
  } = useHistory();

  useEffect(() => {
    setTimeout(() => {
      window.scroll({ top: 0, left: 0, behavior: 'auto' });
    }, 0);
  }, []);

  return <AssetDetails asset={asset} />;
};
StockDetails.propTypes = {
  data: PropTypes.object.isRequired,
};

export default StockDetails;
