import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Button, Grid, makeStyles, TextField, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { ReactComponent as GreenCheckboxIcon } from 'assets/icons/GreenCheckbox.svg';
import { ReactComponent as RedCheckboxIcon } from 'assets/icons/RedCheckbox.svg';
import { ReactComponent as CheckboxIcon } from 'assets/icons/Square.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/icons/SquareFilled.svg';
import useToggle from 'hooks/useToggle';
import LoaderWrapper from 'components/LoaderWrapper';

const useStyles = makeStyles((theme) => ({
  requirements: {
    padding: '10px 18px',
    borderRadius: 3,
    backgroundColor: '#EFEFEF',
  },
  requirementsTitle: {
    marginBottom: 15,
  },
  consent: {
    fontSize: 12,
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
  consentDisabled: {
    pointerEvents: 'none',
  },
  formControlLabelRoot: {
    margin: 0,
  },
  formControlLabel: {
    fontSize: 12,
  },
}));

const SetPassword = ({ handleNext, userData, updateUserData, creatingUser }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [showPassword, toggleShowPassword] = useToggle();
  const [showConfirmPassword, toggleShowConfirmPassword] = useToggle();
  const schema = useMemo(
    () =>
      yup.object().shape({
        password: yup
          .string()
          .matches('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})', t('form-validations.password'))
          .required(t('form-validations.required')),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref('password'), null], t('form-validations.confirmPassword'))
          .required(t('form-validations.required')),
      }),
    [t]
  );
  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      password: userData.password,
      confirmPassword: userData.confirmPassword,
    },
    resolver: yupResolver(schema),
  });

  const password = watch('password');
  const hasLength = Boolean(password.match('^(?=.{6,})'));
  const hasUppercase = Boolean(password.match('^(?=.*[A-Z])'));
  const hasLowercase = Boolean(password.match('^(?=.*[a-z])'));
  const hasNumber = Boolean(password.match('^(?=.*[0-9])'));

  const onSubmit = (data) => {
    setSubmitting(true);
    if (isSubmitting || submitting) return;
    updateUserData({
      ...userData,
      ...data,
    });

    setTimeout(() => {
      setSubmitting(false);
      handleNext();
    }, 250);
  };

  const renderRequirement = (requirementFulfilled, text) => (
    <Typography variant="body1">
      {requirementFulfilled ? <GreenCheckboxIcon /> : <RedCheckboxIcon />}
      {` ${text}`}
    </Typography>
  );

  return (
    <Grid container spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <TextField
          name="password"
          label={t('password')}
          type={showPassword ? 'text' : 'password'}
          inputRef={register}
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowPassword}
                  onMouseDown={toggleShowPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="confirmPassword"
          label={t('confirmPassword')}
          type={showConfirmPassword ? 'text' : 'password'}
          inputRef={register}
          error={Boolean(errors.confirmPassword)}
          helperText={errors.confirmPassword?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowConfirmPassword}
                  onMouseDown={toggleShowConfirmPassword}
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.requirements}>
          <Typography variant="subtitle2" color="primary" className={classes.requirementsTitle}>
            {t('register.step-3.requirements-title')}
          </Typography>
          <Typography variant="subtitle2" className={classes.requirementsTitle}>
            {t('register.step-3.requirements-subtitle')}
          </Typography>
          {renderRequirement(hasLength, t('register.step-3.requirements.length'))}
          {renderRequirement(hasUppercase, t('register.step-3.requirements.uppercase'))}
          {renderRequirement(hasLowercase, t('register.step-3.requirements.lowercase'))}
          {renderRequirement(hasNumber, t('register.step-3.requirements.number'))}
        </div>
      </Grid>
      <Grid item xs={12} container justify="center">
        <FormControlLabel
          disabled={creatingUser}
          control={<Checkbox required icon={<CheckboxIcon />} checkedIcon={<CheckboxCheckedIcon />} />}
          classes={{
            root: classes.formControlLabelRoot,
          }}
          label={
            <Typography variant="body2" display="inline" component="div" className={classes.formControlLabel}>
              <Trans
                i18nKey="register.step-3.consent"
                components={{
                  1: (
                    <Typography
                      variant="body2"
                      color="primary"
                      display="inline"
                      className={classNames(classes.consent, { [classes.consentDisabled]: creatingUser })}
                      onClick={() => handleNext(null, 'terms')}
                    />
                  ),
                }}
              />
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <LoaderWrapper loading={creatingUser}>
          <Button type="submit" disabled={creatingUser}>
            {t('createAccount')}
          </Button>
        </LoaderWrapper>
      </Grid>
    </Grid>
  );
};

SetPassword.propTypes = {
  handleNext: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  updateUserData: PropTypes.func.isRequired,
  creatingUser: PropTypes.bool.isRequired,
};

export default SetPassword;
