import { TRANSITION_TIME } from 'utils/constants';

export default {
  '@keyframes fadeOut': {
    to: { opacity: 0 },
  },
  '@keyframes fadeIn': {
    to: { opacity: 1 },
  },

  '@keyframes moveFromRight': {
    from: { transform: 'translateX(100%)' },
  },
  '@keyframes moveToLeft': {
    to: { transform: 'translateX(-100%)', opacity: 1 },
  },
  '@keyframes moveFromLeft': {
    from: { transform: 'translateX(-100%)' },
  },
  '@keyframes moveToRight': {
    to: { transform: 'translateX(100%)', opacity: 1 },
  },

  '.root-transition-element': {
    willChange: 'transform',
  },

  '.slide-right-transition-enter': {
    position: 'relative',
  },
  '.slide-right-transition-exit': {
    position: 'absolute',
    top: 40,
    width: '100vw',
  },
  '.slide-right-transition-enter-active': {
    animation: `moveFromRight ${TRANSITION_TIME}ms ease both`,
    zIndex: 2,
  },
  '.slide-right-transition-exit-active': {
    animation: `moveToLeft ${TRANSITION_TIME}ms ease both`,
    zIndex: 1,
  },

  '.slide-right-transition-inverted-enter': {
    position: 'relative',
  },
  '.slide-right-transition-inverted-exit': {
    position: 'absolute',
    top: 40,
    width: '100vw',
  },
  '.slide-right-transition-inverted-enter-active': {
    animation: `moveFromLeft ${TRANSITION_TIME}ms ease both`,
    zIndex: 2,
  },
  '.slide-right-transition-inverted-exit-active': {
    animation: `moveToRight ${TRANSITION_TIME}ms ease both`,
    zIndex: 1,
  },

  '.slide-left-transition-enter': {
    position: 'relative',
  },
  '.slide-left-transition-exit': {
    position: 'absolute',
    top: 40,
    width: '100vw',
  },
  '.slide-left-transition-enter-active': {
    animation: `moveFromLeft ${TRANSITION_TIME}ms ease both`,
    zIndex: 2,
  },
  '.slide-left-transition-exit-active': {
    animation: `moveToRight ${TRANSITION_TIME}ms ease both`,
    zIndex: 1,
  },

  '.slide-left-transition-inverted-enter': {
    position: 'relative',
  },
  '.slide-left-transition-inverted-exit': {
    position: 'absolute',
    top: 40,
    width: '100vw',
  },
  '.slide-left-transition-inverted-enter-active': {
    animation: `moveFromRight ${TRANSITION_TIME}ms ease both`,
    zIndex: 2,
  },
  '.slide-left-transition-inverted-exit-active': {
    animation: `moveToLeft ${TRANSITION_TIME}ms ease both`,
    zIndex: 1,
  },

  '.fade-transition-enter, .fade-transition-inverted-enter': {
    position: 'relative',
    opacity: 0,
  },
  '.fade-transition-exit, .fade-transition-inverted-exit': {
    position: 'absolute',
    top: 40,
    width: '100vw',
  },
  '.fade-transition-enter-active, .fade-transition-inverted-enter-active': {
    animation: `fadeIn ${TRANSITION_TIME}ms ease both`,
    zIndex: 2,
  },
  '.fade-transition-exit-active, .fade-transition-inverted-exit-active': {
    animation: `fadeOut ${TRANSITION_TIME}ms ease both`,
    zIndex: 1,
  },

  '.no-transition-enter, .no-transition-inverted-enter': {
    position: 'relative',
  },
  '.no-transition-exit, .no-transition-inverted-exit': {
    display: 'none',
  },
  '.no-transition-enter-active, .no-transition-inverted-enter-active': {
    zIndex: 2,
  },
  '.no-transition-exit-active, .no-transition-inverted-exit-active': {
    zIndex: 1,
  },
};
