import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Typography, Fab, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PointerForwardIcon } from 'assets/icons/PointerForward.svg';
import { REGISTER_TOP_BAR_HEIGHT } from '../../utils/constants';
import { requestAppPermissions, checkAppPermissions } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    flexGrow: 1,
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    height: `calc(100vh - ${REGISTER_TOP_BAR_HEIGHT}px - 80px)`,
    overflow: 'auto',
    padding: 30,
  },
  title: {
    marginBottom: 20,
  },
  body: {
    marginBottom: 20,
  },
  warning: {
    marginBottom: 20,
    color: theme.palette.accent.red,
  },
  footer: {
    padding: 20,
    height: 80,
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  },
  formControlLabel: {
    marginBottom: 10,
  },
  cameraLabel: {
    color: ({ cameraAccess }) => (cameraAccess ? theme.palette.secondary.light : '#000'),
  },
}));

const Permissions = ({ handleNext }) => {
  const { t } = useTranslation();
  const [cameraAccess, setCameraAccess] = useState(false);
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);
  const classes = useStyles({ cameraAccess });

  useEffect(() => {
    const checkAccess = async () => {
      setCameraAccess(await checkAppPermissions(['camera']));
      setPermissionsLoaded(true);
    };

    checkAccess();
  }, []);

  const renderCameraLabel = () => {
    return (
      <div className={classes.cameraLabel}>
        <Typography variant="h4">{t('identity-verification.permissions.type.camera.title')}</Typography>
        <Typography variant="body1">{t('identity-verification.permissions.type.camera.subtitle')}</Typography>
      </div>
    );
  };

  const handleCameraCheck = async () => {
    const permissionsGranted = await requestAppPermissions(['camera']);
    if (permissionsGranted) setCameraAccess(true);
  };

  return (
    <Grid container className={classes.root} direction="column">
      <Grid item className={classes.content}>
        <Typography variant="h3" className={classes.title}>
          {t('identity-verification.permissions.title')}
        </Typography>
        <Typography variant="body1" className={classes.body}>
          {t('identity-verification.permissions.body')}
        </Typography>
        <Typography variant="body1" className={classes.warning}>
          {t('identity-verification.permissions.warning')}
        </Typography>
        {permissionsLoaded && (
          <Grid container item alignItems="center" onClick={() => handleCameraCheck()}>
            <Grid item xs={2}>
              <Checkbox checked={cameraAccess} color="primary" disabled={cameraAccess} />
            </Grid>
            <Grid item xs={10}>
              {renderCameraLabel()}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item container alignItems="flex-end" direction="column" className={classes.footer}>
        <Fab color="primary" onClick={handleNext} size="small" disabled={!cameraAccess}>
          <PointerForwardIcon />
        </Fab>
      </Grid>
    </Grid>
  );
};

Permissions.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default Permissions;
