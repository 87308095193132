import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Can be used to set the color of safe area of the app wrapper
export function useSetAppNotchColor(color, colorOnUnmount) {
  const isWebViewApp = useSelector((state) => state.misc.isWebViewApp);
  useEffect(() => {
    if (isWebViewApp) {
      window.flutter_inappwebview.callHandler('notch_color', color);
    }
    return () => {
      if (colorOnUnmount && isWebViewApp) {
        window.flutter_inappwebview.callHandler('notch_color', colorOnUnmount);
      }
    };
  }, [color, colorOnUnmount, isWebViewApp]);
}
