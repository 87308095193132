import React, { useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ReactComponent as CocoaLogo } from 'assets/icons/CocoaLargeLogo.svg';
import useTransition from '../hooks/useTransition';
import Transition from './Transition';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 2000,
    position: 'fixed',
    height: '100vh',
  },
  content: {
    backgroundColor: '#298070',
    position: 'fixed',
    top: 0,
    width: '100vw',
    bottom: 0,
    zIndex: 2000,
  },
}));

const SplashScreen = () => {
  const classes = useStyles();
  const { showTransition, delay, handleHideAnimation } = useTransition(true, 500);

  useEffect(() => {
    const timer = setTimeout(handleHideAnimation, 500);
    return () => clearTimeout(timer);
  }, [handleHideAnimation]);

  return (
    <div className={classes.root}>
      <Transition show={showTransition} delay={{ enter: 0, exit: delay }}>
        <Grid container justify="center" alignItems="center" className={classes.content}>
          <CocoaLogo />
        </Grid>
      </Transition>
    </div>
  );
};

export default SplashScreen;
