import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import useAsyncFn from 'hooks/useAsyncFn';
import { fetchAllCountries } from 'transfer/countryApi';
import { fetchPortfoliosAsync, fetchPortfolioAsync } from 'transfer/user/portfoliosApi';
import { fetchInvestmentLimits } from 'transfer/investmentLimitsApi';
import { fetchDetailedCurrenciesAsync } from 'transfer/user/currenciesApi';
import { fetchCurrenciesAsync } from 'transfer/currenciesApi';
import { PLATFORM } from 'utils/constants';

const initialState = {
  countries: [],
  portfolios: null,
  investmentLimits: null,
  currencies: [],
  detailedCurrencies: [],

  // App related state.
  isWebViewApp: false,
  platform: PLATFORM.WEB,
  platformVersion: '0.0.0',
  buildNumber: '0',
};

const miscSlice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
    addCountries(state, { payload }) {
      state.countries = payload.sort((a, b) => a.name.localeCompare(b.name));
    },
    addPortfolios(state, { payload }) {
      state.portfolios = payload;
    },
    addInvestmentLimits(state, { payload }) {
      state.investmentLimits = payload;
    },
    addCurrencies(state, { payload }) {
      state.currencies = payload;
    },
    addDetailedCurrencies(state, { payload }) {
      state.detailedCurrencies = payload;
    },

    setIsWebViewApp(state, action) {
      state.isWebViewApp = action.payload;
    },
    setPlatform(state, action) {
      state.platform = action.payload;
    },
    setPlatformVersion(state, action) {
      state.platformVersion = action.payload;
    },
    setAppBuildNumber(state, action) {
      state.buildNumber = action.payload;
    },
  },
});

export const {
  addCountries,
  addPortfolios,
  addInvestmentLimits,
  addCurrencies,
  addDetailedCurrencies,
  setIsWebViewApp,
  setPlatform,
  setPlatformVersion,
  setAppBuildNumber,
} = miscSlice.actions;

export default miscSlice.reducer;

export function useFetchCountries() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const countries = await fetchAllCountries();
    dispatch(addCountries(countries));
    return countries;
  }, [dispatch]);
}

export function useFetchPortfolios() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const portfolios = await fetchPortfoliosAsync();
    dispatch(addPortfolios(portfolios));
    return portfolios;
  }, [dispatch]);
}

export function useFetchPortfolio() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (id) => {
      return await fetchPortfolioAsync(id);
    },
    [dispatch]
  );
}

export function useFetchInvestmentLimits() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const res = await fetchInvestmentLimits();
    dispatch(addInvestmentLimits(res));
    return res;
  }, [dispatch]);
}

export function useFetchCurrencies() {
  const dispatch = useDispatch();
  const currencies = useSelector((state) => state.misc.currencies);
  return useAsyncFn(async () => {
    if (currencies.length > 0) return;
    const res = await fetchCurrenciesAsync();
    dispatch(addCurrencies(res));
    return res;
  }, [dispatch]);
}

export function useFetchDetailedCurrencies() {
  const dispatch = useDispatch();
  const currencies = useSelector((state) => state.misc.detailedCurrencies);
  return useAsyncFn(async () => {
    if (currencies.length > 0) return;
    const res = await fetchDetailedCurrenciesAsync();
    dispatch(addDetailedCurrencies(res));
    return res;
  }, [dispatch]);
}
