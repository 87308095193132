import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import LoaderWrapper from 'components/LoaderWrapper';
import { formatPrice } from 'utils/helpers';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    margin: '20px',
  },
  section: {
    paddingBottom: 10,
    marginBottom: 20,
    borderBottom: '2px solid #b8b8b8',
    '& > div:first-child': {
      marginBottom: 20,
    },
  },
  bold: {
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  noBorder: {
    border: 'none',
    marginBottom: 0,
  },
  item: {
    marginBottom: 20,
  },
  continueBtn: {
    marginTop: 10,
    marginBottom: 20,
  },
}));

const Item = ({ label, value }) => {
  const classes = useStyles();
  if (!value) return null;
  return (
    <Grid item container justify="space-between" className={classes.item} wrap="nowrap">
      <Grid item component={Typography}>
        {label}
      </Grid>
      <Grid item component={Typography} className={classes.bold} align="right">
        {value}
      </Grid>
    </Grid>
  );
};

const TransferDetails = ({ handleNext, data, buttonsDisabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currencies = useSelector((state) => state.misc.detailedCurrencies);
  const selectedCurrency = currencies.find((c) => c.code === data.currencyCode);

  return (
    <div className={classes.root}>
      <Grid container className={classes.section}>
        <Grid item container justify="space-between">
          <Typography variant="h3" className={classes.bold}>
            {t('transferDetails')}
          </Typography>
        </Grid>
        <Item label={<b>{`${t('amount')}:`}</b>} value={formatPrice(data.initialInvestment, data.currencyCode)} />
        <Item label={<b>{`${t('reference')}:`}</b>} value={data?.transaction?.wireTransferMessageIdentifier} />
        {t('register-transfer.transfer-details.amount-notice')}
      </Grid>
      <Grid container className={classes.section}>
        <Grid item container justify="space-between">
          <Typography variant="h6" className={classes.bold}>
            {t('yourBankDetails')}
          </Typography>
        </Grid>
        <Item label={t('accountHolder')} value={data.accountHolderName} />
        <Item label={t('bankName')} value={data.bankName} />
        <Item label={t('ibanOrAccountNumber')} value={data.accountNumber} />
        <Item label={t('swiftCode')} value={data.swiftCode} />
      </Grid>
      <Grid container className={classNames(classes.section, classes.noBorder)}>
        <Grid item container justify="space-between">
          <Typography variant="h6" className={classes.bold}>
            {t('ourBankDetails')}
          </Typography>
        </Grid>
        <Item label={t('beneficiaryName')} value={selectedCurrency?.beneficiaryName} />
        <Item label={t('bankName')} value={selectedCurrency?.bankName} />
        <Item label={t('iban')} value={selectedCurrency?.iban} />
        <Item label={t('accNumber')} value={selectedCurrency?.accountNumber} />
        <Item label={t('sortCode')} value={selectedCurrency?.sortCode} />
        <Item label={t('swiftCode')} value={selectedCurrency?.swift} />
        {selectedCurrency?.address && selectedCurrency.address.length > 0 && (
          <Grid item container justify="space-between" className={classes.item} wrap="nowrap">
            <Typography>{t('address')}</Typography>
            <Typography className={classes.bold} align="right">
              {selectedCurrency?.address}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Typography align="center">{t('register-transfer.transfer-details.notice')}</Typography>
      <Grid className={classes.continueBtn}>
        <LoaderWrapper loading={buttonsDisabled}>
          <Button disabled={buttonsDisabled} onClick={handleNext}>
            {t('continue')}
          </Button>
        </LoaderWrapper>
      </Grid>
    </div>
  );
};

TransferDetails.propTypes = {
  data: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  buttonsDisabled: PropTypes.bool.isRequired,
};

export default TransferDetails;
