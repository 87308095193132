import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid, TextField, makeStyles, Typography } from '@material-ui/core';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '@material-ui/pickers';

import { useUpdatePersonalSettings } from 'store/reducers/user';
import { updateBackPath } from 'store/reducers/UI';
import useDelayHistory from 'hooks/useDelayHistory';
import LoaderWrapper from 'components/LoaderWrapper';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    padding: '20px 10px 30px',
    width: '100%',
    margin: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  submitBtn: {
    marginTop: 10,
  },
}));

const PersonalInformation = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user.data);
  const delayHistory = useDelayHistory();
  const [{ loading }, updatePersonalSettings] = useUpdatePersonalSettings();
  const [submitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const schema = useMemo(
    () =>
      yup.object().shape({
        givenName: yup.string().required(t('form-validations.required')),
        familyName: yup.string().required(t('form-validations.required')),
        phoneNumber: yup.string().required(t('form-validations.required')),
        dateOfBirth: yup
          .date()
          .max(
            new Date(new Date().setFullYear(new Date().getFullYear() - 18)), // TODO: There must be a better way to do this
            t('form-validations.ageRestriction18')
          )
          .required(t('form-validations.required')),
      }),
    [t]
  );

  const { register, handleSubmit, errors, control } = useForm({
    mode: 'onBlur',
    defaultValues: {
      givenName: userData.givenName,
      familyName: userData.familyName,
      dateOfBirth: userData.dateOfBirth,
      phoneNumber: userData.phoneNumber,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    if (submitting) return;
    setSubmitting(true);
    setErrorMsg(null);

    const res = await updatePersonalSettings(data);

    setSubmitting(false);
    if (res) {
      delayHistory.push('/user/settings');
    } else {
      setErrorMsg(t('error-messages.generic'));
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateBackPath({ path: '/user/settings' }));
  }, [dispatch]);

  return (
    <Grid container className={classes.root} spacing={1} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <TextField
          name="givenName"
          label={t('firstName')}
          inputRef={register}
          error={Boolean(errors.givenName)}
          helperText={errors.givenName?.message}
          type="text"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="familyName"
          label={t('lastName')}
          inputRef={register}
          error={Boolean(errors.familyName)}
          helperText={errors.familyName?.message}
          type="text"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="phoneNumber"
          label={t('mobileNumber')}
          inputRef={register}
          error={Boolean(errors.phoneNumber)}
          helperText={errors.phoneNumber?.message}
          type="text"
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          as={
            <DatePicker
              disableFuture
              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
              openTo="year"
              format="dd/MM/yyyy"
              label={t('dateOfBirth')}
              views={['year', 'month', 'date']}
              error={Boolean(errors.dateOfBirth)}
              helperText={errors.dateOfBirth?.message}
            />
          }
          control={control}
          name="dateOfBirth"
        />
      </Grid>
      {errorMsg && (
        <Grid item xs={12}>
          <Typography color="error">{errorMsg}</Typography>
        </Grid>
      )}
      <Grid item xs={12} className={classes.submitBtn}>
        <LoaderWrapper loading={submitting || loading}>
          <Button disabled={submitting || loading} type="submit">
            {t('update')}
          </Button>
        </LoaderWrapper>
      </Grid>
    </Grid>
  );
};

export default PersonalInformation;
