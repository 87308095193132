import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

const TermsAndConditions = () => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} component={Typography}>
        <Trans i18nKey="register.step-terms.body1" />
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary" component="a" target="_blank" href="/account-opening-agreement.pdf">
          {t('account-opening-agreement')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary" component="a" target="_blank" href="/platform-agreement.pdf">
          {t('platform-agreement')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary" component="a" target="_blank" href="/privacy-policy.pdf">
          {t('privacy-policy')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary" component="a" target="_blank" href="/cookie-policy.pdf">
          {t('cookie-policy')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <Trans i18nKey="register.step-terms.body2" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TermsAndConditions;
