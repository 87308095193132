export default {
  root: {
    minHeight: 48,
  },
  contained: {
    fontSize: 16,
  },
  outlinedPrimary: {
    color: 'black',
  },
};
