import axios from 'axios';
import { removeAuth } from 'store/reducers/user';
import { clearPlans } from 'store/reducers/plans';
import { baseApiUrl } from 'transfer/baseApiConfig';
import { addNotification } from 'store/reducers/UI';

console.log('process.env', process.env);

export const apiEndpoints = {
  account: '/account',
  countries: '/countries',
  investmentLimits: '/investmentLimits',
  currencies: '/currencies',
  user: {
    plans: '/user/plans',
    settings: '/user/settings',
    onfido: '/user/onfido',
    porfolios: '/user/portfolios',
    transaction: '/user/transactions',
    currencies: '/user/currencies',
  },
};

export const initApi = (history, store) => {
  // Response interceptor
  axios.defaults.baseURL = baseApiUrl;
  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      if (error?.response?.status === 401) {
        // Remove authentication status in redux
        store.dispatch(removeAuth());
        store.dispatch(clearPlans());
        if (window.location.pathname.includes('user')) {
          history.push('/');
        }
      } else {
        const errorResponse = error?.response?.data;
        if (errorResponse) {
          store.dispatch(
            addNotification({
              message: `Title: ${errorResponse.title}\nType: ${errorResponse.type}\nTraceId: ${errorResponse.traceId}\n Instance: ${errorResponse.instance}`,
              variant: 'error',
            })
          );
        }
      }
      return Promise.reject(error);
    }
  );
};
