import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: 0,
    position: 'relative',
    paddingBottom: '75%',
  },
  text: {
    padding: '0 30px',
    width: '100%',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    textAlign: 'center',
  },
}));

const TextCard = ({ text }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.text} variant="h4" color="secondary">
        {text}
      </Typography>
    </div>
  );
};

TextCard.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextCard;
