import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Routes from 'Routes';
import SplashScreen from 'components/SplashScreen';
import {
  useFetchCountries,
  useFetchCurrencies,
  useFetchDetailedCurrencies,
  useFetchInvestmentLimits,
  setIsWebViewApp,
  setPlatform,
  setPlatformVersion,
  setAppBuildNumber,
} from 'store/reducers/misc';
import { useAuthenticateUser } from 'store/reducers/user';
import { useFetchPlans } from 'store/reducers/plans';
import SandboxDisclaimerDialog from 'components/Dialogs/SandboxDisclaimerDialog';
import { addCookie } from 'utils/helpers';
import { PLATFORM } from 'utils/constants';

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { fetched: userFetched, authenticated, data: userData } = useSelector((state) => state.user);
  const { fetched: plansFetched } = useSelector((state) => state.plans);
  const { investmentLimits, countries, currencies, detailedCurrencies, isWebViewApp } = useSelector(
    (state) => state.misc
  );
  const [{ loading: fetchingCountries, error: errorCountries }, fetchCountries] = useFetchCountries();
  const [, fetchPlans] = useFetchPlans();
  const [, authenticate] = useAuthenticateUser();
  const [
    { loading: fetchingInvestmentLimits, error: errorInvestmentLimits },
    fetchInvestmentLimits,
  ] = useFetchInvestmentLimits();
  const [{ loading: fetchingCurrencies, error: errorCurrencies }, fetchCurrenciesAsync] = useFetchCurrencies();
  const [
    { loading: fetchingDetailedCurrencies, error: errorDetailedCurrencies },
    fetchDetailedCurrencies,
  ] = useFetchDetailedCurrencies();

  useEffect(() => {
    if (countries.length === 0 && !fetchingCountries && !errorCountries) {
      fetchCountries();
    }
    if (!investmentLimits && !fetchingInvestmentLimits && !errorInvestmentLimits) {
      fetchInvestmentLimits();
    }
    if (!fetchingCurrencies && currencies.length === 0 && !errorCurrencies) {
      fetchCurrenciesAsync();
    }
    if (
      !fetchingDetailedCurrencies &&
      userData.roles.includes('VerifiedClient') &&
      detailedCurrencies.length === 0 &&
      !errorDetailedCurrencies
    ) {
      fetchDetailedCurrencies();
    }
  }, [
    countries,
    currencies.length,
    detailedCurrencies.length,
    fetchCountries,
    fetchCurrenciesAsync,
    fetchDetailedCurrencies,
    fetchInvestmentLimits,
    fetchingCountries,
    fetchingCurrencies,
    fetchingDetailedCurrencies,
    fetchingInvestmentLimits,
    investmentLimits,
    userData.roles,
    errorInvestmentLimits,
    errorCountries,
    errorCurrencies,
    errorDetailedCurrencies,
  ]);

  useEffect(() => {
    if (window.flutterIsReady && !isWebViewApp) {
      dispatch(setIsWebViewApp(true));
      window.flutter_inappwebview.callHandler('check_platform').then((platform) => {
        dispatch(setPlatform(platform ?? PLATFORM.WEB));
      });
      window.flutter_inappwebview.callHandler('check_platform_version').then((platformVersion) => {
        dispatch(setPlatformVersion(platformVersion ?? '0.0.0'));
      });
      window.flutter_inappwebview.callHandler('check_app_build_number').then((appBuildNumber) => {
        dispatch(setAppBuildNumber(appBuildNumber ?? '0'));
      });
      window.flutter_inappwebview.callHandler('get_cookies').then((cookies) =>
        cookies
          ?.split(';')
          .map((cookie) => cookie.split('='))
          .forEach(([key, value]) => {
            if (key && value) {
              addCookie(key, value);
            }
          })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, window.flutterIsReady, isWebViewApp]);

  useEffect(() => {
    if (!userFetched) {
      authenticate();
    } else if (authenticated) {
      if (!plansFetched) fetchPlans();
      // redirectUser(history.push, userData, [], history.location.pathname);
    }
  }, [history, userFetched, plansFetched, fetchPlans, authenticate, authenticated, userData]);

  return (
    <>
      <SplashScreen />
      <Routes />
      <SandboxDisclaimerDialog />
    </>
  );
}

export default App;
