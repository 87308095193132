import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  fabProgress: {
    position: 'absolute',
    zIndex: 1,
  },
}));

const ProgressFab = ({ loading, thickness, size, children, className }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${className}`}>
      {children}
      {loading && (
        <CircularProgress
          size={size + thickness * 2}
          thickness={thickness}
          className={classes.fabProgress}
          style={{ top: -thickness, left: -thickness }}
        />
      )}
    </div>
  );
};

ProgressFab.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  thickness: PropTypes.number,
  size: PropTypes.number,
  className: PropTypes.string,
};

ProgressFab.defaultProps = {
  thickness: 4,
  size: 40,
  className: '',
};

export default ProgressFab;
