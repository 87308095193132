import { createSlice } from '@reduxjs/toolkit';

/* eslint-disable no-param-reassign */
const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    showTransition: true,
    transitions: [],
    backPath: '',
    /**
     * Action that is triggered when hitting the back button in the top bar, which is needed since the top bar component
     * is not accessible from the individual components. Should be a function or undefined.
     * Unserializable data shouldn't really be stored in redux since it will mess persistence and  time traveling debugging.
     * In this case the trade off is worth it but a different solution should probably be looked into in the future.
     */
    backAction: undefined,
    goBackOverride: false,
    notifications: [],
  },
  reducers: {
    showTransition(state) {
      return {
        ...state,
        showTransition: true,
      };
    },
    hideTransition(state) {
      return {
        ...state,
        showTransition: false,
      };
    },
    addTransition(state, { payload }) {
      state.transitions.push(payload);
    },
    removeLastTransition(state) {
      state.transitions.splice(state.transitions.length - 1, 1);
    },
    updateBackPath(state, { payload: { path, action } }) {
      state.backPath = path;
      state.backAction = action;
    },
    resetBackPath(state) {
      state.backPath = '';
      state.backAction = undefined;
    },
    setBackAction(state, { payload }) {
      state.backAction = payload;
    },
    clearBackAction(state) {
      state.backAction = undefined;
    },
    setGoBackOverride(state) {
      state.goBackOverride = true;
    },
    unsetGoBackOverride(state) {
      state.goBackOverride = false;
    },
    addNotification(state, action) {
      const { key, message, variant } = action.payload;
      state.notifications.push({
        key: key ?? new Date().getTime() + Math.random(),
        message,
        variant: variant ?? 'default',
      });
    },
    removeNotification(state, action) {
      state.notifications = state.notifications.filter((notification) => notification.key !== action.payload);
    },
  },
});

export const {
  showTransition,
  hideTransition,
  addTransition,
  removeLastTransition,
  updateBackPath,
  resetBackPath,
  setBackAction,
  clearBackAction,
  setGoBackOverride,
  unsetGoBackOverride,
  addNotification,
  removeNotification,
} = uiSlice.actions;

export default uiSlice.reducer;
