import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, CssBaseline } from '@material-ui/core';

import theme from './theme';
import useStyles from './styles';
import BottomNav from './components/BottomNav';
import TopBar from './components/TopBar';
import SnackbarProvider from './components/SnackbarProvider';
import { useSetAppSafeArea } from 'hooks/useSetAppSafeArea';
import { useSetAppNotchColor } from 'hooks/useSetAppNotchColor';

const UserLayout = ({ children }) => {
  const classes = useStyles(theme)();
  useSetAppSafeArea(true);
  useSetAppNotchColor('#FFFFFF');
  console.log(theme);
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <CssBaseline />
        <TopBar />
        <div className={classes.toolbar} />
        <main className={classes.mainUser}>{children}</main>
        <BottomNav />
      </SnackbarProvider>
    </ThemeProvider>
  );
};

UserLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserLayout;
