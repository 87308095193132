import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getRedirectUserPath, hasUserCompletedInitialSetup, hasUserCompletedOnboarding } from 'utils/helpers';

/* eslint-disable react/prop-types, react/display-name, react/jsx-props-no-spreading */
const AuthRoute = ({ component, onboardingRequired, ...rest }) => {
  const { pathname } = useLocation();
  const { authenticated, fetched: userFetched, data: userData } = useSelector((state) => state.user);
  const { fetched: plansFetched, plans } = useSelector((state) => state.plans);

  if (!userFetched) return <LinearProgress />;
  if (!authenticated) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
        from={pathname}
      />
    );
  }
  if (!plansFetched) return <LinearProgress />;

  const onboardingCompleted = hasUserCompletedOnboarding(userData);
  const isInitialPlanCompleted = hasUserCompletedInitialSetup(userData, plans);

  const handleRenderAuthComp = () => {
    if (onboardingRequired) {
      if (!onboardingCompleted) {
        return ({ location }) => (
          <Redirect
            to={{
              pathname: getRedirectUserPath(userData),
              state: { from: location },
            }}
            from={location.pathname}
          />
        );
      }
      if (!isInitialPlanCompleted && !pathname.includes('/user/initial-setup')) {
        return ({ location }) =>
          !location.pathname.includes('/user/initial-setup') && (
            <Redirect
              to={{
                pathname: '/user/initial-setup',
                state: { from: location },
              }}
              from={location.pathname}
            />
          );
      }
    }
    return component;
  };

  return (
    <Route
      {...rest}
      render={
        authenticated
          ? handleRenderAuthComp()
          : ({ location }) => (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: location },
                }}
                from={location.pathname}
              />
            )
      }
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired,
  onboardingRequired: PropTypes.bool,
};

AuthRoute.defaultProps = {
  onboardingRequired: false,
};

export default AuthRoute;
