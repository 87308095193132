export default {
  root: {
    minHeight: 'unset !important',
    '& .Mui-expanded': {
      minHeight: 'unset !important',
    },
  },
  expandIcon: {
    margin: '0 !important',
    '& .Mui-expanded': {
      minHeight: 'unset !important',
      margin: '0 !important',
    },
  },
};
