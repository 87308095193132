import axios from 'axios';

import { apiEndpoints } from '../config';

const transactionEndpoint = apiEndpoints.user.transaction;

export async function createTransactionAsync(data, type = 'wire') {
  const res = await axios.post(`${transactionEndpoint}/${type}`, data);
  return res.data;
}

export async function cancelWireTransaction(id) {
  const res = await axios.post(`${transactionEndpoint}/wire/${id}/cancel`);
  return res.data;
}
