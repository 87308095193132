import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Button, makeStyles, Typography, TextField, Fab, InputAdornment } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import { ReactComponent as PlusIcon } from 'assets/icons/PlusIcon.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/MinusIcon.svg';
import { REGISTER_TOP_BAR_HEIGHT } from 'utils/constants';
import { formatPrice, getCurrencySymbol } from 'utils/helpers';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.white,
    padding: 20,
    height: `calc(100vh - ${REGISTER_TOP_BAR_HEIGHT}px - 80px)`,
    overflowY: 'auto',
    paddingTop: '8vh',
    '& > div:first-child': {
      height: 'fit-content',
    },
  },
  title: {
    marginTop: 25,
    marginBottom: 15,
  },
  subtitle: {
    marginTop: 30,
  },
  fab: {
    boxShadow: 'unset',
    '&:active': {
      boxShadow: 'unset',
    },
  },
  footer: {
    padding: '16px 20px',
    height: 80,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[4],
    zIndex: 1,
  },
  input: {
    flexGrow: 1,
    '& fieldset': {
      borderRadius: 0,
    },
  },
  error: {
    marginTop: 5,
  },
}));
/* eslint-disable react/jsx-no-duplicate-props */

const increment = 50;
const InitialInvestment = ({ handleNext, data, updateData }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { detailedCurrencies } = useSelector((state) => state.misc);
  const [submitting, setSubmitting] = useState(false);

  const schema = useCallback(
    (data) =>
      yup.object().shape({
        initialInvestment: yup
          .number()
          .min(
            detailedCurrencies.find((c) => c.code === data?.currency)?.minimumDepositAmount ?? 0,
            t('form-validations.minimumPlanValue', {
              value: formatPrice(
                detailedCurrencies.find((c) => c.code === data?.currency)?.minimumDepositAmount,
                data?.currency
              ),
            })
          )
          .max(
            detailedCurrencies.find((c) => c.code === data?.currency)?.maximumDepositAmount ?? Infinity,
            t('form-validations.maximumTransactionLimit', {
              value: formatPrice(
                detailedCurrencies.find((c) => c.code === data?.currency)?.maximumDepositAmount,
                data?.currency
              ),
            })
          )
          .required(t('form-validations.required'))
          .typeError(t('form-validations.required')),
        /*monthlyTopUp: yup
          .number()
          .min(100, t('form-validations.minimumTransactionLimit', { value: formatPrice(100) }))
          .required(t('form-validations.required'))
          .typeError(t('form-validations.required')),*/
      }),
    [detailedCurrencies, t]
  );

  const { handleSubmit, register, errors, setValue, watch, trigger } = useForm({
    defaultValues: {
      initialInvestment: data.initialInvestment,
      currency: data.currencyCode,
      /*monthlyTopUp: data.monthlyTopUp,*/
    },
    resolver: async (data) => yupResolver(schema(data))(data),
  });
  const values = watch();

  useEffect(() => {
    trigger();
  }, [trigger, values.currency]);

  const handleIncrease = async (field) => {
    const value = parseInt(values[field], 10);
    if (Number.isNaN(value)) {
      setValue(field, 100);
    } else {
      setValue(field, value + increment);
    }
    await trigger(field);
  };
  const handleDecrease = async (field) => {
    const value = parseInt(values[field], 10);
    if (Number.isNaN(value)) {
      setValue(field, 0);
    } else if (value - increment < 0) {
      setValue(field, 0);
    } else {
      setValue(field, value - increment);
    }
    await trigger(field);
  };

  const onSubmit = (formData) => {
    if (submitting) return;
    setSubmitting(true);
    updateData({
      ...data,
      initialInvestment: formData.initialInvestment,
      currencyCode: formData.currency,
      // monthlyTopUp: formData.monthlyTopUp,
    });
    handleNext(() => setSubmitting(false));
  };

  return (
    <Grid container direction="column" className={classes.root} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid item container className={classes.content}>
        <Grid container justify="center" alignItems="flex-start">
          <Grid item xs={11}>
            <Typography align="center" variant="h5" className={classes.title}>
              {t('plan.set-investment.change-currency')}
            </Typography>
          </Grid>
          <Grid item xs={8} sm={10} container justify="center">
            <FormControl variant="outlined" className={classes.input}>
              <Select
                native
                inputProps={{
                  name: 'currency',
                }}
                inputRef={register}
              >
                {detailedCurrencies.map((currency) => (
                  <option key={currency.code} value={currency.code}>
                    {currency.code}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {['initialInvestment'].map((field) => (
            <React.Fragment key={`initial-investment-${field}`}>
              <Grid item xs={10}>
                <Typography align="center" variant="h5" className={classes.title}>
                  {t(`register-transfer.initial-investment.${field}-title`)}
                </Typography>
              </Grid>
              <Grid item xs={12} container justify="space-between" alignItems="center">
                <Grid item xs={2} sm={1} container justify="flex-start">
                  <Fab className={classes.fab} size="small" color="primary" onClick={() => handleDecrease(field)}>
                    <MinusIcon />
                  </Fab>
                </Grid>
                <Grid item xs={8} sm={10} container justify="center">
                  <TextField
                    variant="outlined"
                    name={field}
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                    inputRef={register}
                    className={classes.input}
                    error={Boolean(errors[field])}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" disablePointerEvents disableTypography>
                          {getCurrencySymbol(values.currency)}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={1} container justify="flex-end">
                  <Fab className={classes.fab} size="small" color="primary" onClick={() => handleIncrease(field)}>
                    <PlusIcon />
                  </Fab>
                </Grid>
              </Grid>
              {Boolean(errors[field]) && (
                <Grid item xs={9}>
                  <Typography className={classes.error} color="error" variant="body2" align="center">
                    {errors[field] ? errors[field].message : ''}
                  </Typography>
                </Grid>
              )}
            </React.Fragment>
          ))}
          <Grid item xs={10}>
            <Typography align="center" variant="body1" className={classes.subtitle}>
              {t('register-transfer.initial-investment.subtitle', {
                value: formatPrice(
                  detailedCurrencies.find((c) => c.code === values?.currency)?.minimumDepositAmount ?? 0,
                  values.currency
                ),
              })}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container alignItems="center" justify="space-between" direction="row" className={classes.footer}>
        <Button color="primary" type="submit">
          {t('next')}
        </Button>
      </Grid>
    </Grid>
  );
};

InitialInvestment.propTypes = {
  handleNext: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default InitialInvestment;
