import React from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemIcon, ListItemAvatar, Avatar, makeStyles } from '@material-ui/core';
import { ReactComponent as InfoOutlinedIcon } from 'assets/icons/InfoOutlined.svg';
import InfoFilledItalicImage from 'assets/images/InfoFilledItalic.png';
import TextCard from '../../components/Cards/TextCard';
import DelayLink from '../../components/DelayLink';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 24,
    width: 24,
  },
  list: {
    padding: 0,
  },
  listItem: {
    height: 80,
    backgroundColor: theme.palette.common.white,
    textTransform: 'uppercase',
    paddingLeft: 24,
  },
}));

const Info = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      <TextCard text={t('user.info.index.title')} />
      <List className={classes.list}>
        <ListItem
          button
          className={classes.listItem}
          divider
          component={DelayLink}
          to="/user/info/faq"
          transition="slide-right"
        >
          <ListItemIcon>
            <InfoOutlinedIcon />
          </ListItemIcon>
          {t('readOurFaq')}
        </ListItem>
        <ListItem
          button
          className={classes.listItem}
          divider
          component={DelayLink}
          to="/user/contact"
          transition="fade"
        >
          <ListItemAvatar>
            <Avatar src={InfoFilledItalicImage} className={classes.avatar} />
          </ListItemAvatar>
          {t('talkToUs')}
        </ListItem>
        {/*<ListItem
          button
          className={classes.listItem}
          divider
          component={DelayLink}
          to="/user/messages"
          transition="fade"
        >
          <ListItemAvatar>
            <Avatar src={InfoFilledItalicImage} className={classes.avatar} />
          </ListItemAvatar>
          {t('dropUsALine')}
        </ListItem>*/}
      </List>
    </div>
  );
};

export default Info;
