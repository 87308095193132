import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, List, ListItem, ListItemSecondaryAction, SwipeableDrawer, makeStyles } from '@material-ui/core';

import { ReactComponent as CocoaLogo } from 'assets/icons/CocoaLargeLogo.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import DelayLink from 'components/DelayLink';
import { useLogoutUser } from 'store/reducers/user';
import { useSelector } from 'react-redux';
import {
  getRedirectUserPath,
  isUserVerified,
  hasUserCompletedOnboarding,
  hasUserCompletedInitialSetup,
} from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
    maxWidth: 400,
    width: '80vw',
  },
  backdrop: {
    backgroundColor: 'rgba(255,255,255,0.9)',
  },
  logo: {
    margin: '50px 0',
  },
  list: {
    borderTop: '1px solid white',
    color: 'white',
    padding: 0,
  },
  listItem: {
    height: 52,
    borderBottom: '1px solid white',
    ...theme.typography.subtitle2,
    fontWeight: 500,
  },
}));

const SideDrawer = ({ open, toggleOpen }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const { fetched: userFetched, authenticated, data: userData } = useSelector((state) => state.user);
  const { fetched: plansFetched, plans } = useSelector((state) => state.plans);
  const [{ loading: loggingOutUser }, logoutUser] = useLogoutUser();

  const handleLogoutUser = async () => {
    const userLoggedOut = await logoutUser();
    if (userLoggedOut) {
      history.push('/');
    }
  };

  const onboardingCompleted = hasUserCompletedOnboarding(userData);
  const initialSetupCompleted = hasUserCompletedInitialSetup(userData, plans);

  const items = useMemo(
    () =>
      [
        !authenticated && {
          title: t('home'),
          type: 'link',
          path: '/',
        },
        onboardingCompleted &&
          initialSetupCompleted && {
            title: t('dashboard'),
            type: 'link',
            path: '/user/plans',
          },
        !(onboardingCompleted && initialSetupCompleted) && {
          title: authenticated
            ? !onboardingCompleted
              ? t('continueRegistration')
              : t('completeInitialSetup')
            : t('openAccount'),
          type: 'link',
          path: authenticated ? getRedirectUserPath(userData, plans) || pathname : '/register',
        },
        {
          title: 'FAQ',
          type: 'link',
          path: '/user/info/faq',
        },
        isUserVerified(userData) &&
          hasUserCompletedInitialSetup(userData, plans) && {
            title: t('settings'),
            type: 'link',
            path: '/user/settings',
          },
        /*authenticated && {
          title: t('messages'),
          type: 'link',
          path: '/user/messages',
        },*/
        authenticated && {
          title: t('talkToUs'),
          type: 'link',
          path: '/user/contact',
        },
        authenticated && {
          title: t('signOut'),
          type: 'func',
          func: handleLogoutUser,
        },
      ].filter(Boolean),
    // eslint-disable-next-line
    [authenticated, t, onboardingCompleted, initialSetupCompleted, userData, plans, pathname]
  );

  if (!userFetched || (authenticated && !plansFetched)) return null;

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={toggleOpen}
      onOpen={toggleOpen}
      classes={{
        paper: classes.paper,
      }}
      BackdropProps={{
        className: classes.backdrop,
      }}
    >
      <Grid container justify="center">
        <Grid item xs={8} container justify="center" className={classes.logo}>
          <CocoaLogo />
        </Grid>
        <Grid item xs={12}>
          <List className={classes.list}>
            {items.map((item) =>
              item.type === 'link' ? (
                <ListItem
                  key={item.title}
                  button
                  className={classes.listItem}
                  component={DelayLink}
                  to={item.path}
                  onDelayEnd={toggleOpen}
                  disabled={loggingOutUser}
                >
                  {item.title}
                  <ListItemSecondaryAction>
                    <ArrowRightIcon />
                  </ListItemSecondaryAction>
                </ListItem>
              ) : (
                <ListItem
                  key={item.title}
                  button
                  className={classes.listItem}
                  onClick={async () => {
                    if (item.type === 'func') await item.func();
                    toggleOpen();
                  }}
                  disabled={loggingOutUser}
                >
                  {item.title}
                  <ListItemSecondaryAction>
                    <ArrowRightIcon />
                  </ListItemSecondaryAction>
                </ListItem>
              )
            )}
          </List>
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
};

SideDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default SideDrawer;
