import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core';

import { DelayIconButton } from 'components/DelayButtons';
import { ReactComponent as ArrowBack } from 'assets/icons/PointerBack.svg';
import { REGISTER_TOP_BAR_HEIGHT } from 'utils/constants';
import { ReactComponent as BurgerMenuIcon } from 'assets/icons/BurgerMenu.svg';
import SideDrawer from 'components/Layout/SideDrawer';
import useToggle from 'hooks/useToggle';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: REGISTER_TOP_BAR_HEIGHT,
    paddingBottom: 10,
    boxShadow: theme.shadows[4],
    backgroundColor: theme.palette.background.default,
    zIndex: 1,
  },
  topBar: {
    height: 50,
  },
  backButton: {
    marginLeft: 8,
    '& svg': {
      height: '26px',
    },
  },
  menuButton: {
    marginRight: 8,
    '& svg': {
      height: '26px',
    },
  },
  hiddenButton: {
    marginLeft: 8,
    visibility: 'hidden',
    pointerEvents: 'none',
  },
  progress: {
    height: 4,
    backgroundColor: 'rgba(216,216,216,0.22)',
  },
  progressBar: {
    height: 2,
    marginTop: 1,
  },
}));

const RegisterTopBar = ({ title, handleBack, disabled, hideBackBtn, progressValue, hideProgressBar }) => {
  const classes = useStyles();
  const [sideDrawerOpen, toggleSideDrawerOpen] = useToggle();

  const renderProgressBar = () => {
    if (hideProgressBar) return null;

    if (typeof progressValue === 'number') {
      return (
        <LinearProgress
          value={progressValue}
          variant="determinate"
          className={classes.progress}
          classes={{ bar: classes.progressBar }}
        />
      );
    }

    const value = (progressValue.max - progressValue.min) * progressValue.percent + progressValue.min;
    return (
      <LinearProgress
        value={value}
        variant="determinate"
        className={classes.progress}
        classes={{ bar: classes.progressBar }}
      />
    );
  };

  return (
    <Grid container alignItems="center" className={classes.root}>
      <SideDrawer open={sideDrawerOpen} toggleOpen={toggleSideDrawerOpen} />
      <Grid item xs={12} container justify="space-between" alignItems="center" className={classes.topBar}>
        <DelayIconButton
          onClick={handleBack}
          className={classNames(classes.backButton, { [classes.hiddenButton]: hideBackBtn })}
          disabled={disabled}
        >
          <ArrowBack />
        </DelayIconButton>
        <Typography variant="body1" color="primary">
          {title}
        </Typography>
        <DelayIconButton onClick={toggleSideDrawerOpen} className={classes.menuButton} disabled={disabled}>
          <BurgerMenuIcon />
        </DelayIconButton>
      </Grid>
      <Grid item xs={12}>
        {renderProgressBar()}
      </Grid>
    </Grid>
  );
};

RegisterTopBar.propTypes = {
  hideBackBtn: PropTypes.bool,
  handleBack: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  progressValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
      percent: PropTypes.number,
    }),
  ]),
  hideProgressBar: PropTypes.bool,
};

RegisterTopBar.defaultProps = {
  hideBackBtn: false,
  handleBack: () => {},
  disabled: false,
  title: '',
  progressValue: 0,
  hideProgressBar: false,
};

export default RegisterTopBar;
