import axios from 'axios';

import { apiEndpoints } from '../config';

const settingsEndpoint = apiEndpoints.user.settings;

export async function updateAddressAsync(data) {
  const res = await axios.put(`${settingsEndpoint}/address`, data);
  return res.data;
}

export async function updatePersonalSettingsAsync(data) {
  const res = await axios.put(`${settingsEndpoint}/personal`, data);
  return res.data;
}

export async function updateTaxInfoAsync(data) {
  const res = await axios.put(`${settingsEndpoint}/tax`, data);
  return res.data;
}

export async function updateFinancialInfoAsync(data) {
  const res = await axios.put(`${settingsEndpoint}/financial`, data);
  return res.data;
}

export async function updateLegalComplianceAsync(data) {
  const res = await axios.put(`${settingsEndpoint}/legal-compliance`, data);
  return res.data;
}

export async function updateSuitabilityQuestionAnswersAsync(data) {
  const res = await axios.put(`${settingsEndpoint}/suitability-question-answers`, data);
  return res.data;
}

export async function updateNotifications(data) {
  const res = await axios.put(`${settingsEndpoint}/notifications`, data);
  return res.data;
}

export async function createBankAccountAsync(data) {
  const res = await axios.post(`${settingsEndpoint}/bank-account`, data);
  return res.data;
}
