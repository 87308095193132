import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Fab,
  Grid,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { ReactComponent as PointerForwardIcon } from 'assets/icons/PointerForward.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/ArrowDown.svg';
import useMultipleValues from 'hooks/useMultipleValues';
import { calcPortfolio } from 'utils/SuitabilityQuestions';
import { useUpdateSuitabilityQuestionAnswers } from 'store/reducers/user';
import ProgressFab from 'components/ProgressFab';
import { REGISTER_TOP_BAR_HEIGHT } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    height: `calc(100vh - ${REGISTER_TOP_BAR_HEIGHT}px)`,
    scrollBehavior: 'smooth',
    overflowX: 'hidden',
  },
  content: {
    backgroundColor: theme.palette.accent.seaMist,
    padding: 20,
    overflowY: 'hidden',
    '& > div:first-child': {
      height: '100%',
    },
  },
  title: {
    marginBottom: 50,
  },
  footer: {
    padding: 20,
    boxShadow: theme.shadows[4],
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  },
  expansionPanel: {
    borderRadius: 4,
    marginBottom: '10px',
  },
  expansionPanelSummary: {
    margin: '12px 0 !important',
  },
  formControl: {
    width: '100%',
  },
  formControlLabel: {
    marginBottom: 10,
  },
}));

/* eslint-disable react/no-array-index-key */
const Questionnaire = ({ handleNext, data, updateData, surveyId, questionnaire, setDisabled }) => {
  const classes = useStyles();
  const { t } = useTranslation(['translation', 'suitability-questions']);
  const {
    values: expanded,
    toggleValue: toggleExpanded,
    setMultipleValues: setMultipleExpanded,
    setNewValues: setNewExpanded,
  } = useMultipleValues([0]);
  const [{ loading }, updateAnswers] = useUpdateSuitabilityQuestionAnswers();

  const surveyAnswers = {};
  data.surveyAnswers.forEach((answer, i) => {
    surveyAnswers[`question${i}`] = answer;
  });
  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      ...surveyAnswers,
    },
  });
  const formAnswers = useWatch({ control });

  const onSubmit = async (formData) => {
    setDisabled(true);
    const answers = Object.values(formData).map((value) =>
      typeof value === 'string' ? parseInt(value, 10) : typeof value === 'number' ? value : 0
    );
    const answerRes = await updateAnswers({
      answers,
      version: questionnaire.version,
      identifier: surveyId,
    });
    const portfolio = calcPortfolio(answers, questionnaire);
    updateData({
      ...data,
      surveyAnswers: Object.values(formData),
      recommendedPortfolio: portfolio,
      selectedPortfolio: portfolio,
    });
    setDisabled(false);
    if (answerRes) {
      handleNext();
    }
  };

  const scrollToQuestionId = (id) => {
    const element = document.getElementById(`question-${id}`);
    if (!element) return;
    const scrollElement = document.getElementById('questionnaire-form');
    if (scrollElement) {
      const { top } = element.getBoundingClientRect();
      const scrollTop = scrollElement.scrollTop + top - 72; // window.scrollY
      scrollElement.scroll({ top: scrollTop, left: 0, behavior: 'smooth' }); // window.scroll
    }
  };

  useEffect(() => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const errorQuestion = parseInt(errorKeys[0].charAt(errorKeys[0].length - 1), 10);
      setMultipleExpanded([errorQuestion]);
      setTimeout(() => {
        scrollToQuestionId(errorQuestion);
      }, 750);
    }
    // eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    const nextQuestionIndex = Object.entries(formAnswers).findIndex(([, value]) => value === null);
    setNewExpanded([nextQuestionIndex]);
    setTimeout(() => {
      scrollToQuestionId(nextQuestionIndex);
    }, 750);
    // eslint-disable-next-line
  }, [formAnswers]);

  return (
    <Grid
      container
      direction="row"
      className={classes.root}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      id="questionnaire-form"
    >
      <Grid item className={classes.content} container direction="column" alignItems="stretch">
        <Grid item xs={12}>
          {questionnaire.questions.map((question, questionIndex) => {
            const transKey = `suitability-questions:${surveyId}.version${questionnaire.version}.questions`;
            const questionText = t(`${transKey}.${questionIndex}.question`);
            return (
              <Accordion
                id={`question-${questionIndex}`}
                key={`question-${questionText}`}
                className={classes.expansionPanel}
                expanded={expanded.includes(questionIndex)}
                onChange={() => toggleExpanded(questionIndex)}
              >
                <AccordionSummary classes={{ content: classes.expansionPanelSummary }} expandIcon={<ArrowDownIcon />}>
                  {questionText}
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl error={Boolean(errors[`question${questionIndex + 1}`])} className={classes.formControl}>
                    <Controller
                      as={
                        <RadioGroup>
                          {question.pointsPerAnswer.map((answer, answerIndex) => (
                            <FormControlLabel
                              key={`question-${questionIndex}-answer-${answerIndex}`}
                              label={t(`${transKey}.${questionIndex}.answers.${answerIndex}`)}
                              value={`${answerIndex}`}
                              control={<Radio color="primary" />}
                              classes={{
                                root: classes.formControlLabel,
                              }}
                            />
                          ))}
                        </RadioGroup>
                      }
                      control={control}
                      rules={{
                        required: t('form-validations.required-question'),
                      }}
                      name={`question${questionIndex}`}
                    />
                    <Typography color="error">{errors[`question${questionIndex}`]?.message}</Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      </Grid>
      <Grid item container alignItems="center" justify="space-between" direction="row" className={classes.footer}>
        <Grid item xs={10}>
          <Typography variant="body2" color="error">
            {Object.keys(errors).length > 0 ? t('plan.questionnaire.error') : ''}
          </Typography>
        </Grid>
        <Grid item xs={2} container justify="flex-end">
          <ProgressFab loading={loading}>
            <Fab color="primary" type="submit" size="small" disabled={loading}>
              <PointerForwardIcon />
            </Fab>
          </ProgressFab>
        </Grid>
      </Grid>
    </Grid>
  );
};

Questionnaire.propTypes = {
  handleNext: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  questionnaire: PropTypes.object.isRequired,
  surveyId: PropTypes.string.isRequired,
  setDisabled: PropTypes.func.isRequired,
};

export default Questionnaire;
