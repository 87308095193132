import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarProvider as NotistackSnackbarProvider, useSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';

import { ReactComponent as ClearIcon } from 'assets/icons/Cross.svg';
import { removeNotification } from 'store/reducers/UI';

let displayed = [];
function SnackbarChild() {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.UI);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(({ key, message, variant }) => {
      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;
      enqueueSnackbar(message, {
        key,
        variant,
        onExited: (event, myKey) => {
          dispatch(removeNotification(myKey));
          removeDisplayed(myKey);
        },
      });
      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
  return null;
}

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    '& svg path': {
      fill: theme.palette.common.white,
    },
  },
  containerRoot: {
    maxWidth: '40vw',
    [theme.breakpoints.down('md')]: {
      maxWidth: '50vw',
    },
    [theme.breakpoints.down('sm')]: {
      left: `${theme.spacing(2)}px !important`,
      bottom: `${2}px !important`,
      maxWidth: `calc(100vw - ${theme.spacing(2) * 2}px)`,
    },
  },
  root: {
    '& > div': {
      flexWrap: 'nowrap',
      alignItems: 'center',
      whiteSpace: 'pre-line',
      fontSize: 12,
    },
  },
  variantError: {
    color: `${theme.palette.error.contrastText} !important`,
    backgroundColor: `${theme.palette.error.main} !important`,
    '& svg': {
      fill: `${theme.palette.error.contrastText} !important`,
    },
  },
  variantSuccess: {
    color: `${theme.palette.success.contrastText} !important`,
    backgroundColor: `${theme.palette.success.main} !important`,
    '& svg': {
      fill: `${theme.palette.success.contrastText} !important`,
    },
  },
  variantInfo: {
    color: `${theme.palette.info.contrastText} !important`,
    backgroundColor: `${theme.palette.info.main} !important`,
    '& svg': {
      fill: `${theme.palette.info.contrastText} !important`,
    },
  },
  variantWarning: {
    color: `${theme.palette.warning.contrastText} !important`,
    backgroundColor: `${theme.palette.warning.main} !important`,
    '& svg': {
      fill: `${theme.palette.warning.contrastText} !important`,
    },
  },
}));

export default function SnackbarProvider({ children }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notistackRef = useRef(null);

  const handleCloseNotification = (key) => () => {
    dispatch(removeNotification(key));
    if ('closeSnackbar' in notistackRef.current) {
      notistackRef.current.closeSnackbar(key);
    }
  };

  // Disable snackbar messages in production as the content is not user friendly and only used to notice errors in developemnt
  if (process.env.NODE_ENV === 'production') return children;

  return (
    <NotistackSnackbarProvider
      maxSnack={3}
      ref={notistackRef}
      action={(key) => (
        <IconButton size="small" className={classes.closeBtn} onClick={handleCloseNotification(key)}>
          <ClearIcon />
        </IconButton>
      )}
      classes={{
        root: classes.root,
        containerRoot: classes.containerRoot,
        variantError: classes.variantError,
        variantSuccess: classes.variantSuccess,
        variantInfo: classes.variantInfo,
        variantWarning: classes.variantWarning,
      }}
    >
      <SnackbarChild />
      {children}
    </NotistackSnackbarProvider>
  );
}
