import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { formatPrice } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '20px 20px 0',
  },
  title: {
    lineHeight: '29px',
    textTransform: 'capitalize',
    width: '100%',
  },
  subtitle: {
    lineHeight: '29px',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    '@supports (-webkit-line-clamp: 2)': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'initial',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
  },
  text: {
    lineHeight: '29px',
    textTransform: 'capitalize',
  },
  header: {
    position: 'relative',
    marginBottom: 10,
  },
  circle: {
    position: 'relative',
    display: 'inline-block',
    borderRadius: '50%',
    minWidth: 64,
    minHeight: 20,
    padding: 5,
    border: `6px solid ${theme.palette.accent.melanie}`,
    textAlign: 'center',
    lineHeight: 1,
    boxSizing: 'content-box',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',

    '&:before': {
      content: '""',
      display: 'inline-block',
      verticalAlign: 'middle',
      paddingTop: '100%',
      height: 0,
    },
  },
  valueSubtitle: {
    fontSize: 12,
    textTransform: 'uppercase',
  },
  totalValueText: {
    color: theme.palette.accent.melanie,
  },
  totalValueContainer: {
    padding: '10px 0',
    borderRight: '1px solid white',
  },
  totalGrowthContainer: {
    padding: '10px 0',
  },
}));

const PlanPerformanceCard = ({ subtitle, totalValue, totalGrowth, performancePercent, className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container className={classNames(classes.root, className)}>
      <Grid item xs={12} container className={classes.header}>
        <Grid item xs={7} container justify="flex-start" direction="column">
          <Grid item component={Typography} variant="h6" className={classes.title}>
            {t('planPerformance')}
          </Grid>
          <Grid item component={Typography} variant="h3" className={classes.subtitle}>
            {subtitle}
          </Grid>
        </Grid>
        <Grid item xs={5} container justify="center" alignItems="center">
          {performancePercent !== undefined && (
            <Typography variant="h5" className={classes.circle}>
              {performancePercent === 0 ? '' : performancePercent > 0 ? '+' : '-'}
              {Math.abs(performancePercent).toFixed(2)}%
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} container justify="space-between">
        <Grid item xs={6} className={classes.totalValueContainer}>
          <Typography variant="h3" className={`${classes.text} ${classes.totalValueText}`}>
            {formatPrice(totalValue)}
          </Typography>
          <Typography className={classes.valueSubtitle}>{t('totalValue')}</Typography>
        </Grid>
        <Grid item xs={5} container justify="center" className={classes.totalGrowthContainer}>
          <Grid item>
            <Typography variant="h3" className={classes.text}>
              {formatPrice(totalGrowth)}
            </Typography>
            <Typography className={classes.valueSubtitle}>{t('totalGrowth')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

PlanPerformanceCard.propTypes = {
  subtitle: PropTypes.string.isRequired,
  totalValue: PropTypes.number.isRequired,
  totalGrowth: PropTypes.number.isRequired,
  performancePercent: PropTypes.number,
  className: PropTypes.string,
};

export default PlanPerformanceCard;
