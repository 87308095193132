import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Grow ref={ref} {...props} timeout={{ enter: 500, exit: 250 }} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    margin: 10,
  },
  title: {
    ...theme.typography.h3,
    fontWeight: 400,
    textAlign: 'center',
  },
  message: {
    textAlign: 'center',
  },
  actions: {
    padding: '0 15% 5px',
  },
  confirmButton: {
    marginTop: 5,
    minHeight: 40,
    borderRadius: 20,
  },
  cancelButton: {
    minHeight: 40,
    borderRadius: 20,
    backgroundColor: '#DDD',
    '&:hover': {
      backgroundColor: '#AAA',
    },
  },
}));

const ConfirmDialog = ({ open, onConfirm, onCancel, message, title }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.root,
      }}
      onBackdropClick={onCancel}
      TransitionComponent={Transition}
    >
      <DialogTitle disableTypography>
        <Typography className={classes.title}>{title || t('confirmAction')}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.message}>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} className={classes.actions}>
          <Grid item xs={12} md={6}>
            <Button color="secondary" onClick={onCancel} className={classes.cancelButton}>
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button className={classes.confirmButton} onClick={onConfirm}>
              {t('confirm')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
};

ConfirmDialog.defaultProps = {
  title: null,
};

export default ConfirmDialog;
