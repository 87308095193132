import React from 'react';
import { useSetAppSafeArea } from 'hooks/useSetAppSafeArea';
import { useSetAppNotchColor } from 'hooks/useSetAppNotchColor';

const NotFound = () => {
  useSetAppSafeArea(true);
  useSetAppNotchColor('#FFFFFF');
  return <div>Page couldn't be found.</div>;
};

export default NotFound;
