import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger/src';

import rootReducer from './reducers';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    [
      ...getDefaultMiddleware(),
      process.env.NODE_ENV === 'development' && createLogger(),
    ].filter(Boolean),
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducers/index.js', () => {
    const newRootReducer = require('./reducers/index.js').default;
    store.replaceReducer(newRootReducer);
  });
}

export default store;
