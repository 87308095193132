import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fade, Grid, makeStyles, Typography, useTheme } from '@material-ui/core';

import { ReactComponent as FindOutMoreImage } from 'assets/images/find-out-more.svg';
import { DelayIconButton } from 'components/DelayButtons';
import { ReactComponent as PointerBackIcon } from 'assets/icons/PointerBack.svg';
import { ReactComponent as PointerForwardIcon } from 'assets/icons/PointerForward.svg';
import { useSetAppNotchColor } from 'hooks/useSetAppNotchColor';
import { useSetAppSafeArea } from 'hooks/useSetAppSafeArea';
import useDelayHistory from 'hooks/useDelayHistory';

const useStyles = makeStyles((theme) => ({
  topBar: {
    padding: '0 30px 0 18px',
    height: 50,
    background: 'white',
    boxShadow: theme.shadows[2],
  },
  root: {
    height: '100vh',
  },
  image: {
    paddingTop: 20,
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    background: 'white',
    height: 250,
    padding: '30px 30px 20px',
    borderRadius: 25,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  textContent: {
    flexGrow: 1,
  },
  title: {
    marginBottom: 12,
    fontSize: 23,
    lineHeight: '26px',
  },
  body: {
    marginBottom: 22,
  },
  dots: {
    marginBottom: 24,
  },
  dot: {
    height: 6,
    width: 6,
    margin: '0 5px',
    backgroundColor: fade(theme.palette.accent.rockBlu, 0.5),
    borderRadius: '100%',
  },
  dotActive: {
    height: 10,
    width: 10,
    backgroundColor: fade(theme.palette.primary.main, 0.5),
  },
  signInLink: {
    marginTop: 6,
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  forwardBtn: {
    position: 'absolute',
    bottom: 30,
    right: 30,
    width: 42,
    height: 42,
    padding: 10,
    backgroundColor: theme.palette.primary.main + '!important',
  },
}));

const FindOutMore = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useDelayHistory();
  const {
    palette: { accent },
  } = useTheme();
  useSetAppNotchColor('#FFFFFF');
  useSetAppSafeArea(true);

  const steps = t('find-out-more-view.steps', { returnObjects: true });
  const [activeStep, setActiveStep] = useState(0);
  const stepColors = useMemo(() => [accent.pinkLady, accent.seaMist, accent.paleBlue, accent.melanie], [accent]);

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      wrap="nowrap"
      style={{
        backgroundColor: stepColors[activeStep],
      }}
    >
      <Grid container alignItems="center" className={classes.topBar}>
        <DelayIconButton onClick={() => (history.action === 'PUSH' ? history.goBack() : history.replace('/'))}>
          <PointerBackIcon />
        </DelayIconButton>
      </Grid>
      <div className={classes.image}>
        <FindOutMoreImage />
      </div>
      <Grid container direction="column" alignItems="center" className={classes.container}>
        <Grid item className={classes.textContent}>
          <Typography variant="h3" align="center" className={classes.title}>
            {steps[activeStep].title}
          </Typography>
          <Typography variant="body1" align="center" className={classes.body}>
            {steps[activeStep].body}
          </Typography>
        </Grid>
        <Grid container className={classes.dots} justify="center" alignItems="center">
          {new Array(steps.length).fill(null).map((_, i) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <span
              key={`dot-${i}`}
              className={classNames(classes.dot, { [classes.dotActive]: activeStep === i })}
              onClick={() => setActiveStep(i)}
            />
          ))}
        </Grid>
        <Typography variant="body2" align="center">
          {t('find-out-more-view.already-have-an-account')}
        </Typography>
        <Typography variant="body2" align="center" className={classes.signInLink} component={Link} to="/">
          {t('find-out-more-view.sign-in-here')}
        </Typography>
        <DelayIconButton
          className={classes.forwardBtn}
          onClick={() =>
            activeStep === steps.length - 1 ? history.push('/register') : setActiveStep((prev) => prev + 1)
          }
        >
          <PointerForwardIcon />
        </DelayIconButton>
      </Grid>
    </Grid>
  );
};

export default FindOutMore;
