import React, { useEffect } from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { updateBackPath } from 'store/reducers/UI';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import TextCard from 'components/Cards/TextCard';
import DelayLink from 'components/DelayLink';
import { formatPrice } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  icon: {
    '& path': {
      fill: '#8e8e93',
      stroke: '#8e8e93',
    },
  },
  list: {
    backgroundColor: theme.palette.common.white,
    padding: 0,
  },
  listItem: {
    minHeight: 80,
  },
  disabledPlansText: {
    padding: '5px 10px',
  },
}));

const Index = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { plans } = useSelector((state) => state.plans);

  const disabledPlans = [];
  const activePlans = [];
  // eslint-disable-next-line
  plans?.forEach((plan) => {
    if (plan.planStatus === 'Created' || plan.value === 0) {
      disabledPlans.push(plan);
    } else {
      activePlans.push(plan);
    }
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateBackPath({ path: '/user/transfer' }));
  }, [dispatch]);
  return (
    <>
      <TextCard text={t('user.transfer.withdraw.index.title')} />
      {activePlans.length > 0 && (
        <List className={classes.list}>
          {activePlans?.map((plan) => (
            <ListItem
              key={plan.id}
              className={classes.listItem}
              button
              divider
              component={DelayLink}
              to={`/user/transfer/withdraw/${plan.id}`}
              transition="slide-right"
            >
              <ListItemText primary={plan.name} secondary={`${t('planValue')}: ${formatPrice(plan.value)}`} />
              <ListItemSecondaryAction>
                <ArrowRightIcon className={classes.icon} />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
      {disabledPlans.length > 0 && (
        <>
          <div className={classes.disabledPlansText}>{t('user.transfer.withdraw.index.disabled-plans-title')}</div>
          <List className={classes.list}>
            {disabledPlans?.map((plan) => (
              <ListItem key={plan.id} className={classes.listItem} button divider disabled={plan.value === 0}>
                <ListItemText primary={plan.name} secondary={`${t('planValue')}: ${formatPrice(plan.value)}`} />
                <ListItemSecondaryAction>
                  <ArrowRightIcon className={classes.icon} />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </>
  );
};

export default Index;
