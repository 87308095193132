import React from 'react';
import classNames from 'classnames';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ReactComponent as ArrowRightIcon } from '../assets/icons/ArrowRight.svg';

const useStyles = makeStyles(() => ({
  arrowIcon: {
    '& path': {
      stroke: '#AAAAAA',
    },
  },
  accordionRoot: {
    margin: '0 !important',
    boxShadow: 'none',
  },
  accordionSummary: {
    margin: '0 28px',
    padding: 0,
    borderBottom: '1px solid #dddddd',
    cursor: 'pointer',
  },
  accordionExpandIcon: {
    '&$expanded': {
      transform: 'rotate(90deg) !important',
    },
  },
  expanded: {},
  accordionSummaryNoBorder: {
    borderBottom: '1px solid transparent',
  },
  accordionSummarySelected: {
    color: '#1c406b',
  },
  accordionDetails: {
    padding: '0 16px',
    margin: '0 12px 12px',
    backgroundColor: '#f3f3f3',
  },
  list: {
    backgroundColor: '#f3f3f3',
    width: '100%',
    padding: 0,
  },
  listItem: {
    padding: '15px 30px 15px 0',
  },
  listAction: {
    right: 12,
  },
  stockTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: 5,
  },
}));

const StockAccordion = ({ stockData, handleStockClick, setSelectedAccordion, selectedAccordion }) => {
  const classes = useStyles();
  if (!stockData) return null;
  return stockData.map((category, i) => (
    <Accordion
      key={`accordion-${category.label}`}
      square
      className={classes.accordionRoot}
      expanded={i === selectedAccordion}
      onChange={() => setSelectedAccordion((prev) => (i === prev ? null : i))}
    >
      <AccordionSummary
        className={classNames({
          [classes.accordionSummaryNoBorder]: i === selectedAccordion && category.assets.length > 0,
          [classes.accordionSummarySelected]: i === selectedAccordion,
        })}
        expandIcon={category.assets.length > 0 && <ArrowRightIcon className={classes.arrowIcon} />}
        classes={{
          root: classes.accordionSummary,
          expandIcon: classes.accordionExpandIcon,
          expanded: classes.expanded,
        }}
      >
        <Grid container justify="space-between">
          <div>{category.label}</div>
          <div>{category.percentage.toFixed(2)}%</div>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <List className={classes.list}>
          {category.assets.map((asset, j) => (
            <ListItem
              key={`list-${asset.name}`}
              divider={j + 1 < category.assets.length}
              className={classes.listItem}
              button
              onClick={() => handleStockClick(asset)}
            >
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant="body2">{(asset.fractionOfPortfolio * 100).toFixed(2)}%</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" className={classes.stockTitle}>
                    {asset.name}
                  </Typography>
                </Grid>
              </Grid>
              <ArrowRightIcon className={classes.arrowIcon} />
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  ));
};

export default StockAccordion;
