export const fundingMethods = [
  {
    id: 'wireTransfer',
    transKey: 'payment-methods.wireTransfer',
  },
];

export const cocoaFees = [
  {
    between: [100, 49999],
    percent: 0.99,
  },
  {
    between: [50000, 249999],
    percent: 0.79,
  },
  {
    between: [250000, 499999],
    percent: 0.49,
  },
  {
    between: [500000],
    percent: 0.29,
  },
];
