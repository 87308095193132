import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { allCountries } from 'country-telephone-data';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Transition from 'components/Transition';
import useTransition from 'hooks/useTransition';
import { useStepValidation } from 'hooks/useStepValidation';
import { useRegisterUser } from 'store/reducers/user';
import RegisterTopBar from 'components/Layout/RegisterTopBar';
import { REGISTER_TOP_BAR_HEIGHT } from 'utils/constants';
import { redirectUser } from 'utils/helpers';
import NavPrompt from 'components/NavPrompt';

import GetStarted from './initial/GetStarted';
import PersonalInfo from './initial/PersonalInfo';
import MobilePhoneNumber from './initial/MobilePhoneNumber';
import SetPassword from './initial/SetPassword';
import TermsAndConditions from './initial/TermsAndConditions';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.contrastText,
  },
  headerInfo: {
    margin: `${20 + REGISTER_TOP_BAR_HEIGHT}px 0 30px`,
  },
  content: {
    padding: '0 20px',
  },
}));

const Register = () => {
  const classes = useStyles();
  const { toggleAnimation, showTransition, delay } = useTransition();
  const [{ value, error, loading: creatingUser }, registerUser, resetRegisterUser] = useRegisterUser();
  const countries = useSelector((state) => state.misc.countries);
  const { data: userData, authenticated } = useSelector((state) => state.user);
  const [disabled, setDisabled] = useState(false);
  const [done, setDone] = useState(false);
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    dateOfBirth: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
    dialCode:
      countries.length > 0 &&
      `+${allCountries.find((c) => c.iso2.toLowerCase() === countries[0].id.toLowerCase()).dialCode}`,
    notifications: false,
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    country: countries.length > 0 && countries[0].id,
  });
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const step = history.location.state?.step ?? 0;

  const steps = [
    (handleNext) => (
      <PersonalInfo handleNext={handleNext} userData={data} updateUserData={setData} setDisabled={setDisabled} />
    ),
    (handleNext) => <MobilePhoneNumber handleNext={handleNext} userData={data} updateUserData={setData} />,
    (handleNext) => (
      <SetPassword
        handleNext={handleNext}
        userData={data}
        updateUserData={setData}
        creatingUser={creatingUser || done}
      />
    ),
  ];
  const { stepsValidated, validateStep, invalidateStep } = useStepValidation(steps.length);
  const [progressPercent, setProgressPercent] = useState(step / steps.length);

  const handleNext = async (callback = () => {}, customStep) => {
    if (!customStep && step === steps.length) {
      resetRegisterUser();
      setDone(true);
    } else {
      toggleAnimation(() => {
        if (typeof callback === 'function') callback();
        validateStep(step);
        let nextStep = step + 1;
        if (customStep) {
          nextStep = customStep;
        }
        history.push(`/register`, { step: nextStep });
        window.scrollTo(0, 0);
      });
    }
  };

  useEffect(() => {
    if (typeof step === 'number') {
      setProgressPercent((step + 1) / steps.length);
    }
  }, [step, steps]);

  const handleBack = () => {
    if (step === 0) {
      history.goBack();
    } else {
      toggleAnimation(() => {
        invalidateStep(step);
        history.goBack();
      });
    }
  };

  const renderStep = () => {
    switch (step) {
      case 'terms':
        return <TermsAndConditions />;
      default:
        return steps[step - 1](handleNext);
    }
  };

  useEffect(() => {
    if (done && !creatingUser && !value && !error) {
      const res = registerUser(data);
      if (!res) {
        setDone(false);
      }
    } else if (!creatingUser && (value || error)) {
      setDone(false);
    }
  }, [done, creatingUser, registerUser, error, value, data]);

  useEffect(() => {
    if (value) {
      redirectUser(history.push, userData);
    } else if (error) {
      console.log('error', error);
    }
  }, [userData, history, value, error]);

  useEffect(() => {
    if (authenticated) {
      redirectUser(history.push, userData);
    } else if (step > 0 && !stepsValidated[step]) {
      history.push('/register');
    }
    // eslint-disable-next-line
  }, []);

  if (countries.length === 0) return null;

  if (step === 0) {
    return <GetStarted handleNext={handleNext} userData={data} updateUserData={setData} />;
  }

  return (
    <div className={classes.root}>
      <NavPrompt
        when={(crntLocation, nextLocation) =>
          !nextLocation.pathname.startsWith('/plans/create') &&
          (!nextLocation || !nextLocation.pathname.startsWith(crntLocation.pathname))
        }
      />
      <RegisterTopBar
        handleBack={handleBack}
        disabled={creatingUser || done || disabled}
        progressValue={{ min: 5, max: 20, percent: progressPercent }}
      />
      <Transition show={showTransition} delay={delay}>
        <div className={classes.headerInfo}>
          <Typography variant="h3" align="center">
            {t(`register.step-${step}.title`)}
          </Typography>
          {i18n.exists(`register.step-${step}.subtitle`) && (
            <Typography variant="body1" color="primary" align="center">
              {t(`register.step-${step}.subtitle`)}
            </Typography>
          )}
        </div>
        <div className={classes.content}>{renderStep()}</div>
      </Transition>
    </div>
  );
};

export default Register;
