import React, { useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Checkbox, FormControl, Grid, Select, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { allCountries } from 'country-telephone-data';
import { ReactComponent as CheckboxIcon } from 'assets/icons/Square.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/icons/SquareFilled.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/ArrowDown.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  input: {
    height: '100%',
  },
  notificationsText: {
    fontSize: 12,
    '& a': {
      textDecoration: 'underline',
    },
  },
  selectFormControl: {
    height: '100%',
    '& > div': {
      height: '100%',
    },
    '& select': {
      fontSize: 12,
      paddingTop: 0,
      paddingBottom: 0,
      height: '100%',
    },
  },
}));
/*eslint-disable jsx-a11y/anchor-has-content */
const MobilePhoneNumber = ({ handleNext, userData, updateUserData }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const schema = useMemo(
    () =>
      yup.object().shape({
        dialCode: yup.string().required(t('form-validations.required')),
        phoneNumber: yup.string().required(t('form-validations.required')),
        notifications: yup.bool(),
      }),
    [t]
  );
  const { register, handleSubmit, errors, control, formState } = useForm({
    defaultValues: {
      dialCode: userData.dialCode,
      phoneNumber: userData.phoneNumber,
      notifications: userData.notifications,
    },
    resolver: yupResolver(schema),
  });
  const { isSubmitting } = formState;

  const onSubmit = (data) => {
    setSubmitting(true);
    if (isSubmitting || submitting) return;
    updateUserData({
      ...userData,
      ...data,
    });

    setTimeout(() => {
      setSubmitting(false);
      handleNext();
    }, 250);
  };

  return (
    <Grid container spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={4}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <Select
            native
            inputProps={{
              name: 'dialCode',
              inputRef: register,
            }}
            IconComponent={ArrowDownIcon}
          >
            {allCountries.map((country) => (
              <option key={`country-${country.iso2}`} value={`+${country.dialCode}`}>
                +{country.dialCode} - {country.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <TextField
          name="phoneNumber"
          placeholder={t('mobileNumber')}
          type="number"
          inputRef={register}
          error={Boolean(errors.phoneNumber)}
          helperText={errors.phoneNumber?.message}
          className={classes.input}
          InputProps={{
            classes: {
              root: classes.input,
            },
          }}
        />
      </Grid>
      <Grid item xs={4} container justify="center">
        <Controller
          as={<Checkbox color="primary" icon={<CheckboxIcon />} checkedIcon={<CheckboxCheckedIcon />} />}
          control={control}
          name="notifications"
        />
      </Grid>
      <Grid item xs={8} container alignItems="center">
        <Typography variant="body2" className={classes.notificationsText}>
          <Trans
            i18nKey="register.step-2.notifications"
            components={{
              1: <a target="_blank" href="/privacy-policy.pdf" />,
            }}
          />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button type="submit">{t('continue')}</Button>
      </Grid>
    </Grid>
  );
};

MobilePhoneNumber.propTypes = {
  handleNext: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  updateUserData: PropTypes.func.isRequired,
};

export default MobilePhoneNumber;
