import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, makeStyles, Dialog, Typography, Slide, useTheme } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg';
import { DelayIconButton, DelayButton } from 'components/DelayButtons';
import { generatePortfoliosMap } from 'utils/portfolios';
import { REGISTER_TOP_BAR_HEIGHT } from 'utils/constants';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import { formatPrice } from 'utils/helpers';
import LineGradientFixedChart from 'components/Charts/LineGradientFixedChart';
import { portfolioPerformanceMockedData } from 'utils/mocked-data';

const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...props} timeout={{ enter: 500, exit: 250 }} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100vw',
  },
  content: {
    position: 'relative',
    flexGrow: 1,
    backgroundColor: theme.palette.accent.seaMist,
    height: `calc(100vh - ${REGISTER_TOP_BAR_HEIGHT}px - 145px)`,
    overflowY: 'hidden',
  },
  textContainer: {
    flexGrow: 1,
  },
  title: {
    fontSize: 21,
    marginTop: 30,
    marginBottom: 30,
  },
  subtitle: {
    marginTop: 15,
    marginBottom: 25,
  },
  footer: {
    padding: '16px 20px 8px',
    height: 145,
    boxShadow: theme.shadows[4],
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  },
  dialogPaper: {
    width: '100%',
    margin: 0,
    padding: 40,
    borderRadius: 25,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  dialogContainer: {
    alignItems: 'flex-end',
  },
  disclaimerText: {
    color: theme.palette.grey['600'],
  },
  closeDialogBtn: {
    position: 'absolute',
    right: 12,
    top: 12,
  },
  chart: {
    position: 'absolute',
    height: '40% !important',
    bottom: 0,
  },
  button: {
    minHeight: 40,
    marginBottom: 10,
  },
  disclaimerButton: {
    minHeight: 30,
    color: 'black',
    textDecoration: 'underline !important',
  },
}));

const Portfolio = ({ handleNext, updateTitle, data }) => {
  const classes = useStyles();
  const [showDisclaimerDialog, setShowDisclaimerDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const portfoliosMap = generatePortfoliosMap(theme);

  const selectedPortfolio = data.accountType === 'sukuk' ? 'sukuk' : data.selectedPortfolio;
  const recommendedPortfolio = data.accountType === 'sukuk' ? 'sukuk' : data.recommendedPortfolio;
  const portfolio = useSelector((state) =>
    state.misc.portfolios?.find((p) => p.name.toLowerCase() === selectedPortfolio.toLowerCase())
  );
  useEffect(() => {
    updateTitle(
      selectedPortfolio === 'sukuk' ? '' : recommendedPortfolio === selectedPortfolio ? t('recommendedPortfolio') : ''
    );
    return () => updateTitle('');
  }, [data, updateTitle, recommendedPortfolio, selectedPortfolio, t]);

  const handleNextStep = () => {
    if (selectedPortfolio !== recommendedPortfolio) {
      setShowConfirmDialog(true);
    } else {
      handleNext();
    }
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <ConfirmDialog
        onConfirm={() => {
          setShowConfirmDialog(false);
          handleNext();
        }}
        open={showConfirmDialog}
        onCancel={() => setShowConfirmDialog(false)}
        message={t('dialog-messages.confirmSelectNonRecommendedPortfolio')}
      />
      <Grid
        item
        className={classes.content}
        container
        alignItems="center"
        direction="column"
        style={{ backgroundColor: portfoliosMap[selectedPortfolio].color }}
      >
        <Grid item container alignItems="center" direction="column" className={classes.textContainer}>
          <Typography variant="subtitle1" className={classes.title}>
            {t(`portfolios.${selectedPortfolio}`)}
          </Typography>
          <Typography variant="subtitle1">{t('performance')}</Typography>
          <Typography variant="subtitle1" gutterBottom>
            {portfolio?.averageYoyHistoricalPerformance
              ? `${(portfolio?.averageYoyHistoricalPerformance * 100).toFixed(2)}%`
              : ''}
          </Typography>
          <Typography color="primary">{t('averageReturnPerYear')}</Typography>
          <Typography className={classes.subtitle}>{t('plan.portfolio.subtitle')}</Typography>
          <Typography gutterBottom>
            <Trans i18nKey="plan.portfolio.ifYouStartedWith" values={{ value: formatPrice(10000) }} />
          </Typography>
          <Typography>
            <Trans
              i18nKey="plan.portfolio.todayItWouldBeWorth"
              values={{
                value: formatPrice(
                  10000 *
                    (portfolio?.averageYoyHistoricalPerformance ? portfolio?.averageYoyHistoricalPerformance + 1 : 1) **
                      10
                ),
              }}
            />
          </Typography>
        </Grid>
        <LineGradientFixedChart data={portfolioPerformanceMockedData} className={classes.chart} />
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        justify="center"
        direction="column"
        wrap="nowrap"
        className={classes.footer}
      >
        <Button className={classes.button} onClick={handleNextStep}>
          {t('plan.portfolio.selectPortfolio')}
        </Button>
        <Button className={classes.button} variant="outlined" onClick={() => handleNext(null, 'portfolio-details')}>
          {t('portfolioDetails')}
        </Button>
        <DelayButton
          variant="text"
          className={classes.disclaimerButton}
          fullWidth={false}
          onClick={() => setShowDisclaimerDialog(true)}
        >
          <Typography color="inherit">{t('disclaimer')}</Typography>
        </DelayButton>
      </Grid>
      <Dialog
        open={showDisclaimerDialog}
        onClose={() => setShowDisclaimerDialog(false)}
        TransitionComponent={Transition}
        fullWidth
        classes={{
          paper: classes.dialogPaper,
          container: classes.dialogContainer,
        }}
      >
        <DelayIconButton onClick={() => setShowDisclaimerDialog(false)} className={classes.closeDialogBtn}>
          <CrossIcon />
        </DelayIconButton>
        <Typography variant="subtitle1" align="center" gutterBottom>
          {t('disclaimer')}
        </Typography>
        <Typography align="center" className={classes.disclaimerText}>
          <Trans i18nKey="plan.portfolio.disclaimerBody" />
        </Typography>
      </Dialog>
    </Grid>
  );
};

Portfolio.propTypes = {
  handleNext: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  updateTitle: PropTypes.func.isRequired,
};

export default Portfolio;
