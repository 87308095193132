export default {
  root: {
    borderRadius: 4,
    height: 48,
    '& select': {
      borderRadius: 4,
      padding: '16px 12px',
    },
  },
  colorSecondary: {
    backgroundColor: '#FFF',
    '& :hover': {
      backgroundColor: '#9b9b9b',
      transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    },
    '&.Mui-focused': {
      backgroundColor: '#cccccc !important',
    },
  },
};
