import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Fab, Grid, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { ReactComponent as PointerForwardIcon } from '../../../assets/icons/PointerForward.svg';
import { REGISTER_TOP_BAR_HEIGHT } from '../../../utils/constants';
import ProgressFab from '../../../components/ProgressFab';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    flexGrow: 1,
    padding: 20,
    backgroundColor: theme.palette.accent.pinkLady,
    paddingTop: '12vh',
    height: `calc(100vh - ${REGISTER_TOP_BAR_HEIGHT}px - 80px)`,
    '& > div:first-child': {
      width: '100%',
    },
  },
  footer: {
    padding: 20,
    boxShadow: theme.shadows[4],
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  },
}));

const PlanDataForm = ({ planData, updatePlanData, handleNext }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('form-validations.required')),
        targetValue: yup
          .number()
          .typeError(t('form-validations.required'))
          .positive(t('form-validations.greaterThanZero'))
          .required(t('form-validations.required')),
      }),
    [t]
  );
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      name: planData.name,
      targetValue: planData.targetValue,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setSubmitting(true);
    if (submitting) return;
    updatePlanData({
      ...planData,
      ...data,
    });

    handleNext(() => setSubmitting(false));
  };

  return (
    <Grid container component="form" direction="column" onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid item className={classes.content} container>
        <div>
          <Grid container spacing={2} justify="center">
            <Grid item xs={11}>
              <Typography variant="h4">{t('plan.create.plan-data-form.title')}</Typography>
            </Grid>
            <Grid item xs={11}>
              <TextField
                name="name"
                label={`${t('name')} *`}
                inputRef={register}
                type="text"
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            </Grid>
            <Grid item xs={11}>
              <TextField
                name="targetValue"
                label={`${t('targetGoal')} *`}
                inputRef={register}
                type="number"
                error={Boolean(errors.targetValue)}
                helperText={errors.targetValue?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" disablePointerEvents disableTypography>
                      $
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item container alignItems="flex-end" direction="column" className={classes.footer}>
        <ProgressFab loading={submitting}>
          <Fab color="primary" type="submit" size="small" disabled={submitting}>
            <PointerForwardIcon />
          </Fab>
        </ProgressFab>
      </Grid>
    </Grid>
  );
};

PlanDataForm.propTypes = {
  planData: PropTypes.object.isRequired,
  updatePlanData: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
};

export default PlanDataForm;
