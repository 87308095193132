import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { TRANSITION_TIME } from 'utils/constants';
import { useDispatch } from 'react-redux';
import { removeLastTransition } from 'store/reducers/UI';

// the childFactory allows to change the transition of the leaving component
// https://github.com/reactjs/react-transition-group/issues/182
const childFactoryCreator = (props) => (child) => React.cloneElement(child, props);

const ViewTransition = ({ transition, duration: durationProp, pageKey, historyAction, children }) => {
  const dispatch = useDispatch();
  // const { transitions } = useSelector((state) => state.UI);
  /* const animationClassName =
    historyAction !== 'POP'
      ? `${transition}-transition`
      : transitions[transitions.length - 1]
      ? `${transitions[transitions.length - 1]}-transition-inverted`
      : 'fade-transition'; */
  const animationClassName = 'fade-transition';

  const duration = ['no-transition', 'no-transition-inverted'].includes(animationClassName) ? 0 : durationProp;

  return (
    <TransitionGroup
      childFactory={childFactoryCreator({
        classNames: animationClassName,
        timeout: duration,
      })}
    >
      <CSSTransition
        key={pageKey}
        timeout={duration}
        onEntering={() => {
          if (transition !== 'no') {
            document.body.style.overflowY = 'hidden';
          }
          const element = document.getElementsByClassName(`${animationClassName}-enter`)[0];
          if (element && historyAction !== 'POP') {
            element.style.top = `${window.scrollY}px`;
          }
        }}
        onEntered={() => {
          if (transition !== 'no') {
            document.body.style.overflowY = 'auto';
          }
          const element = document.getElementsByClassName(`${animationClassName}-enter-done`)[0];
          if (element && historyAction !== 'POP') {
            element.style.top = '40px';
            window.scrollTo(0, 0);
          }
          if (historyAction === 'POP') {
            dispatch(removeLastTransition());
          }
        }}
      >
        {/* you should wrap CSSTransition child in a div in case it could be null
      see https://github.com/reactjs/react-transition-group/issues/208 */}
        <div className="root-transition-element">{children}</div>
      </CSSTransition>
    </TransitionGroup>
  );
};

ViewTransition.propTypes = {
  transition: PropTypes.oneOf(['no', 'fade', 'slide-right', 'slide-left']),
  duration: PropTypes.number,
  pageKey: PropTypes.string.isRequired,
  historyAction: PropTypes.oneOf(['POP', 'PUSH', 'REPLACE']).isRequired,
  children: PropTypes.node.isRequired,
};

ViewTransition.defaultProps = {
  transition: 'no',
  duration: TRANSITION_TIME,
};

export default ViewTransition;
