import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import DonutChart from '../Charts/DonutChart';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 20,
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
  },
  title: {
    paddingBottom: 20,
    textAlign: 'center',
    width: '100%',
  },
  donut: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  label: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    textAlign: 'center',
    '& > span': {
      width: 100,
      '& h3': {
        fontWeight: 300,
      },
    },
  },
}));

const DonutCard = ({ data, selectedIndex, setSelectedIndex, title, bgColor, labelTitle, labelSubtitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ backgroundColor: bgColor ?? undefined }}>
      {title && (
        <Typography variant="h4" className={classes.title} color="secondary">
          {title}
        </Typography>
      )}

      <div className={classes.donut}>
        <DonutChart data={data} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
        <div className={classes.label}>
          <span>
            {labelTitle && (
              <Typography variant="h3" color="secondary">
                {labelTitle}
              </Typography>
            )}
            {labelSubtitle && (
              <Typography variant="body2" color="secondary">
                {labelSubtitle}
              </Typography>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

DonutCard.propTypes = {
  data: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func.isRequired,
  title: PropTypes.string,
  bgColor: PropTypes.string,
  labelTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  labelSubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

DonutCard.defaultProps = {
  selectedIndex: undefined,
  title: null,
  labelTitle: undefined,
  bgColor: null,
  labelSubtitle: undefined,
};

export default DonutCard;
