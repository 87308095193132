import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { IconButton, Button, Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { Trans, useTranslation } from 'react-i18next';
import { portfolios, generatePortfoliosMap } from 'utils/portfolios';
import { REGISTER_TOP_BAR_HEIGHT } from 'utils/constants';

import { ReactComponent as ArrowRight } from 'assets/icons/ArrowRight.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100vw',
  },
  content: {
    position: 'relative',
    flexGrow: 1,
    backgroundColor: theme.palette.accent.seaMist,
    height: `calc(100vh - ${REGISTER_TOP_BAR_HEIGHT}px - 80px)`,
    overflowY: 'auto',
    '& > div:first-child': {
      height: '100%',
    },
  },
  changePortfolioText: {
    fontStyle: 'italic',
    fontSize: 12,
  },
  swiper: {
    '& > div > div:nth-child(1)': {
      backgroundColor: (props) => props.porfolioColors[0].color,
    },
    '& > div > div:nth-child(2)': {
      backgroundColor: (props) => props.porfolioColors[1].color,
    },
    '& > div > div:nth-child(3)': {
      backgroundColor: (props) => props.porfolioColors[2].color,
    },
    '& > div > div:nth-child(4)': {
      backgroundColor: (props) => props.porfolioColors[3].color,
    },
    '& > div > div:nth-child(5)': {
      backgroundColor: (props) => props.porfolioColors[4].color,
    },
  },
  stepItem: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '100%',
    flexGrow: 1,
    height: '100%',
    padding: 30,
    paddingTop: '12vh',
  },
  title: {
    marginBottom: 30,
  },
  stepper: {
    width: '100vw',
    position: 'absolute',
    bottom: 0,
    paddingBottom: 8,
    backgroundColor: 'transparent',
  },
  stepDot: {
    height: 8,
    width: 8,
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.26)',
    margin: '0 4px',
  },
  stepDotActive: {
    backgroundColor: theme.palette.primary.main,
  },
  nextButton: {
    position: 'absolute',
    right: 0,
    top: '50%',
    zIndex: 1,
    transition: 'opacity 300ms ease',
    '& svg': {
      height: 35,
    },
    '& path': {
      stroke: theme.palette.common.black,
    },
  },
  prevButton: {
    position: 'absolute',
    left: 0,
    top: '50%',
    zIndex: 1,
    transition: 'opacity 300ms ease',
    '& svg': {
      height: 35,
      transform: 'rotate(180deg)',
    },
    '& path': {
      stroke: theme.palette.common.black,
    },
  },
  buttonHidden: {
    pointerEvents: 'none',
    opacity: 0,
  },
  footer: {
    padding: '16px 20px',
    height: 80,
    boxShadow: theme.shadows[4],
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  },
}));

const ChoosePortfolio = ({ handleNext, data, updateData, updateTitle }) => {
  const theme = useTheme();
  const portfoliosMap = generatePortfoliosMap(theme);
  const classes = useStyles({
    porfolioColors: Object.values(portfoliosMap),
  });
  const { t } = useTranslation();
  const [selectedPortfolioIndex, setSelectedPortfolioIndex] = useState(portfolios.indexOf(data.selectedPortfolio));

  const selectedPortfolio = data.accountType === 'sukuk' ? 'sukuk' : data.selectedPortfolio;

  const handleStepChange = (step) => {
    updateData({
      ...data,
      selectedPortfolio: portfolios[step],
    });
    setSelectedPortfolioIndex(step);
  };

  useEffect(() => {
    updateTitle(
      data.accountType === 'sukuk'
        ? ''
        : data.selectedPortfolio === data.recommendedPortfolio
        ? t('recommendedPortfolio')
        : ''
    );
    return () => updateTitle('');
  }, [t, data, updateTitle]);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={classes.content} container alignItems="center" direction="column">
        {selectedPortfolio === 'sukuk' ? (
          <Grid
            key={selectedPortfolio}
            container
            direction="column"
            className={classes.stepItems}
            style={{ backgroundColor: portfoliosMap[selectedPortfolio].color }}
          >
            <Typography variant="h2" className={classes.title} component="div">
              <Trans
                i18nKey="plan.choosePortfolio.title"
                values={{ portfolio: t(`portfolios.${selectedPortfolio}`) }}
                components={{
                  1: <div style={{ color: theme.palette.primary.main }} />,
                }}
              />
            </Typography>
            <Typography variant="body1">{t(`plan.choosePortfolio.${selectedPortfolio}Text`)}</Typography>
          </Grid>
        ) : (
          <>
            <IconButton
              className={classNames(classes.nextButton, {
                [classes.buttonHidden]: selectedPortfolioIndex === portfolios.length - 1,
              })}
              size="small"
              onClick={() => handleStepChange(selectedPortfolioIndex + 1)}
              disabled={selectedPortfolioIndex === portfolios.length - 1}
            >
              <ArrowRight />
            </IconButton>
            <IconButton
              className={classNames(classes.prevButton, {
                [classes.buttonHidden]: selectedPortfolioIndex === 0,
              })}
              size="small"
              onClick={() => handleStepChange(selectedPortfolioIndex - 1)}
              disabled={selectedPortfolioIndex === 0}
            >
              <ArrowRight />
            </IconButton>
            <SwipeableViews
              axis="x"
              index={selectedPortfolioIndex}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              containerStyle={{ height: '100%', width: '100vw' }}
              style={{ flex: 1 }}
              className={classes.swiper}
            >
              {portfolios.map((portfolio) => (
                <div
                  key={portfolio}
                  className={classes.stepItem}
                  style={{ backgroundColor: portfoliosMap[portfolio].color }}
                >
                  <Typography variant="h2" className={classes.title} component="div">
                    <Trans
                      i18nKey={
                        portfolio === data.recommendedPortfolio
                          ? 'plan.choosePortfolio.suggestedTitle'
                          : 'plan.choosePortfolio.title'
                      }
                      values={{ portfolio: t(`portfolios.${portfolio}`) }}
                      components={{
                        1: <div style={{ color: theme.palette.primary.main }} />,
                      }}
                    />
                  </Typography>
                  {portfolio === data.recommendedPortfolio && (
                    <Typography variant="body1">
                      {t('plan.choosePortfolio.suggestionText')}
                      <br />
                      <br />
                    </Typography>
                  )}
                  <Typography variant="body1">{t(`plan.choosePortfolio.${portfolio}Text`)}</Typography>
                  <br />
                  <Typography variant="body1" className={classes.changePortfolioText}>
                    {t(`plan.choosePortfolio.changePortfolioText`)}
                  </Typography>
                </div>
              ))}
            </SwipeableViews>
            <Grid container className={classes.stepper} justify="center">
              {[0, 1, 2, 3, 4].map((stepValue) => (
                <Grid
                  item
                  key={`step-dot-${stepValue}`}
                  onClick={() => handleStepChange(stepValue)}
                  className={classNames(classes.stepDot, {
                    [classes.stepDotActive]: stepValue === selectedPortfolioIndex,
                  })}
                />
              ))}
            </Grid>
          </>
        )}
      </Grid>
      <Grid item container alignItems="flex-end" justify="center" direction="column" className={classes.footer}>
        <Button onClick={() => handleNext()}>{t('plan.choosePortfolio.viewPortfolio')}</Button>
      </Grid>
    </Grid>
  );
};

ChoosePortfolio.propTypes = {
  handleNext: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  updateTitle: PropTypes.func.isRequired,
};

export default ChoosePortfolio;
