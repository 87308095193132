import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DotIcon } from 'assets/icons/Dot.svg';
import { REGISTER_TOP_BAR_HEIGHT } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: `calc(100vh - ${REGISTER_TOP_BAR_HEIGHT}px)`,
  },
  title: {
    height: 100,
    padding: '0 20px',
    fontSize: 16,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.accent.pinkLady,
    padding: '15vw 40px 40px',
  },
  performance: {
    marginTop: 40,
    borderBottom: '1px solid black',
    '& > div': {
      marginBottom: 30,
    },
  },
  allocation: {
    margin: '15px 0 30px',
  },
  description: {
    whiteSpace: 'pre-line',
  },
}));

const AssetDetails = ({ asset }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={classes.title} container alignItems="center" justify="center">
        <Typography>{asset.name ?? ''}</Typography>
      </Grid>
      <Grid item className={classes.content} style={{ backgroundColor: asset.color }}>
        <Typography variant="h4" align="center">
          {t('performance')}
        </Typography>
        <div className={classes.performance}>
          {Object.entries(asset.staticHistoricalPerformance ?? {}).map(([period, percentage]) => (
            <Grid key={`stock-data-${period + percentage}`} container justify="space-between" alignItems="center">
              <Grid item xs={5} container justify="space-between">
                <Grid item xs={2} container alignItems="center">
                  <DotIcon />
                </Grid>
                <Grid item>
                  <Typography variant="h5">{percentage}%</Typography>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h5" align="center">
                  {period}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </div>
        <Grid container justify="space-between" className={classes.allocation}>
          <Typography variant="h4" align="center">
            {t('allocation')}
          </Typography>
          <Typography variant="h4" align="center">
            {asset.fractionOfPortfolio ? (asset.fractionOfPortfolio * 100).toFixed(2) + '%' : ''}
          </Typography>
        </Grid>
        <Typography variant="h6" gutterBottom>
          {t('details')}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {asset.description}
        </Typography>
      </Grid>
    </Grid>
  );
};

AssetDetails.propTypes = {
  asset: PropTypes.object.isRequired,
};

export default AssetDetails;
