import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';

import CustomButton from 'components/UI/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { setBackAction, clearBackAction } from 'store/reducers/UI';
import { cancelWireTransaction } from 'transfer/user/transactionApi';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 40px - 60px)',
    paddingTop: '12vh',
    paddingBottom: 10,
    backgroundColor: theme.palette.primary.main,
  },
  header: {
    margin: '0 20px',
  },
  title: {
    marginBottom: 15,
    fontWeight: 300,
  },
  body: {
    fontWeight: 300,
    marginBottom: 30,
  },
  button: {
    marginTop: 10,
    borderRadius: 0,
    height: 50,
    textTransform: 'uppercase',
  },
}));

const Details = ({ handleNext, data, updateData }) => {
  const classes = useStyles();
  const { accountNumber } = useSelector((state) => state.user.data.client.bankAccount);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Sets the action to cancel the transaction if hitting the back button and removes it if going to the next step
  useEffect(() => {
    if (data?.transaction?.id) {
      dispatch(
        setBackAction(() => {
          const newData = { ...data };
          delete newData.transaction;
          updateData(newData);
          cancelWireTransaction(data.transaction.id);
        })
      );
    }
    return () => {
      dispatch(clearBackAction());
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container className={classes.root} direction="column" justify="space-between">
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.header}>
            <Typography variant="h3" align="center" color="secondary" className={classes.title}>
              {t('user.transfer.top-up.create.summary.title')}
            </Typography>
            <Typography variant="body1" align="center" color="secondary" className={classes.body}>
              <Trans
                i18nKey="user.transfer.top-up.create.summary.body"
                values={{ accountNumber: `****${accountNumber.substring(accountNumber.length - 4)}` }}
              />
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={6}>
          <CustomButton bgcolor="melanie" onClick={handleNext} className={classes.button}>
            {t('understood')}
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

Details.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default Details;
