export const REGISTER_TOP_BAR_HEIGHT = 64;
export const USER_TOP_BAR_HEIGHT = 40;
export const TRANSITION_TIME = 450;
export const APP_COOKIES = {
  EMAIL: 'app_cookie_email',
  HIDE_SANDBOX_DISCLAIMER: 'app_cookie_hide_sandbox_disclaimer',
};
export const PLATFORM = {
  WEB: 'web',
  IOS: 'ios',
  ANDROID: 'android',
};
