import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const LoaderWrapper = ({ loading, color, className, children }) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, className)}>
      {children}
      {loading && <CircularProgress size={24} className={classes.buttonProgress} color={color} />}
    </div>
  );
};

LoaderWrapper.propTypes = {
  loading: PropTypes.bool.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

LoaderWrapper.defaultProps = {
  color: 'primary',
  className: '',
};

export default LoaderWrapper;
