import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NavPrompt from 'components/NavPrompt';

import Transition from 'components/Transition';
import useTransition from 'hooks/useTransition';
import { useStepValidation } from 'hooks/useStepValidation';
import { REGISTER_TOP_BAR_HEIGHT } from 'utils/constants';
import RegisterTopBar from 'components/Layout/RegisterTopBar';
import { useCreateBankAccount } from 'store/reducers/user';
import { redirectUser } from 'utils/helpers';
import useDelayHistory from 'hooks/useDelayHistory';
import { cancelWireTransaction } from 'transfer/user/transactionApi';

import Intro from './transfer/Intro';
import InitialInvestment from './transfer/InitialInvestment';
import SelectFundingMethod from './transfer/SelectFundingMethod';
import BankDetails from './transfer/BankDetails';
import TransferDetails from './transfer/TransferDetails';
import WelcomeBack from './transfer/WelcomeBack';
import { useSetAppSafeArea } from 'hooks/useSetAppSafeArea';
import { useSetAppNotchColor } from 'hooks/useSetAppNotchColor';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.contrastText,
  },
  headerInfo: {
    margin: '20px 0 30px',
  },
  content: {
    minHeight: `calc(100vh - ${REGISTER_TOP_BAR_HEIGHT}px)`,
    width: '100%',
    paddingTop: REGISTER_TOP_BAR_HEIGHT,
  },
}));

const RegisterTransfer = () => {
  const classes = useStyles();
  const delayHistory = useDelayHistory();
  const { t } = useTranslation();
  const history = useHistory();
  useSetAppSafeArea(true);
  useSetAppNotchColor('#FFFFFF');

  const { toggleAnimation, showTransition, delay } = useTransition();
  const [done, setDone] = useState(false);
  const [
    { loading: creatingBankAccount, value: valueCreateBankAccount, error: errorCreateBankAccount },
    createBankAccount,
    resetBankReqState,
  ] = useCreateBankAccount();

  const userData = useSelector((state) => state.user.data);
  const { plans } = useSelector((state) => state.plans);
  const { bankAccount } = userData.client;
  const { accountHolderName, bankName, accountNumber, swiftCode } = bankAccount ?? {};
  const [data, setData] = useState({
    initialInvestment: plans.length > 0 ? plans[0].initialSpecInvestment ?? 500 : 500,
    currencyCode: plans.length > 0 ? plans[0].initialSpecInvestmentCurrencyCode : undefined,
    monthlyTopUp: 100, // TODO: Initialize this from user data later.
    accountHolderName: accountHolderName ?? '',
    bankName: bankName ?? '',
    accountNumber: accountNumber ?? '',
    swiftCode: swiftCode ?? '',
  });

  const step = history.location.state?.step ?? 0;
  const buttonsDisabled = done || creatingBankAccount;

  const steps = [
    (handleNext) => <WelcomeBack handleNext={handleNext} />,
    (handleNext) => <Intro handleNext={handleNext} />,
    (handleNext) => <InitialInvestment handleNext={handleNext} data={data} updateData={setData} />,
    (handleNext) => <SelectFundingMethod handleNext={handleNext} data={data} updateData={setData} />,
    (handleNext) => <BankDetails handleNext={handleNext} data={data} updateData={setData} />,
    (handleNext) => (
      <TransferDetails handleNext={handleNext} data={data} updateData={setData} buttonsDisabled={buttonsDisabled} />
    ),
  ];
  const bankDetailsStep = 4;
  const { stepsValidated, validateStep, invalidateStep } = useStepValidation(steps.length);

  const handleNext = async (callback = () => {}, customStep) => {
    if (!customStep && step + 1 === steps.length) {
      resetBankReqState();
      setDone(true);
    } else {
      toggleAnimation(() => {
        if (typeof callback === 'function') callback();
        validateStep(step);
        let nextStep = step + 1;
        if (customStep) {
          nextStep = customStep;
        }
        history.push(`/register/transfer`, { step: nextStep });
        window.scrollTo(0, 0);
      });
    }
  };

  const handleBack = () => {
    if (step === 0) {
      // history.goBack();
    } else if (step === bankDetailsStep && data?.transaction?.id && data?.transaction?.method === 'WireTransfer') {
      // This will cancel the wire transaction if user navigates backwards and has selected the wire transfer method.
      const newData = { ...data };
      delete newData.transaction;
      setData(newData);
      cancelWireTransaction(data.transaction.id);
      toggleAnimation(() => {
        invalidateStep(step);
        history.goBack();
      });
    } else {
      toggleAnimation(() => {
        invalidateStep(step);
        history.goBack();
      });
    }
  };

  useEffect(() => {
    async function createBankAccountAndTransaction() {
      try {
        await createBankAccount({
          accountHolderName: data.accountHolderName,
          bankName: data.bankName,
          accountNumber: data.accountNumber,
          swiftCode: data.swiftCode,
        });
      } catch {
        setDone(false);
      }
    }
    if (done && !creatingBankAccount && !valueCreateBankAccount && !errorCreateBankAccount && plans.length > 0) {
      createBankAccountAndTransaction();
    } else {
      setDone(false);
    }
  }, [done, creatingBankAccount, valueCreateBankAccount, errorCreateBankAccount, createBankAccount, data, plans]);

  useEffect(() => {
    if (valueCreateBankAccount) {
      redirectUser(delayHistory.push, userData);
    } else if (errorCreateBankAccount) {
      console.log('error', errorCreateBankAccount);
    }
  }, [delayHistory, valueCreateBankAccount, errorCreateBankAccount, userData]);

  useEffect(() => {
    if (step > 0 && !stepsValidated[step]) {
      history.push('/register/transfer');
    } else {
      redirectUser(history.push, userData);
    }
    // eslint-disable-next-line
  }, []);

  const [progressPercent, setProgressPercent] = useState(step / steps.length);
  useEffect(() => {
    if (typeof step === 'number') {
      setProgressPercent((step + 1) / steps.length);
    }
  }, [step, steps]);

  return (
    <Grid container className={classes.root} direction="column">
      <NavPrompt
        when={(crntLocation, nextLocation) =>
          step !== 0 &&
          !nextLocation.pathname.startsWith('/user/plans') &&
          (!nextLocation || !nextLocation.pathname.startsWith('/register/transfer'))
        }
      />
      <RegisterTopBar
        progressValue={{ min: 85, max: 98, percent: progressPercent }}
        title={t('transfer')}
        handleBack={handleBack}
        hideBackBtn={step === 0}
        backBtnDisabled={buttonsDisabled}
      />
      <Grid item className={classes.content}>
        <Transition show={showTransition} delay={delay}>
          {steps[step](handleNext)}
        </Transition>
      </Grid>
    </Grid>
  );
};

export default RegisterTransfer;
