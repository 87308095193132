import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { hideTransition, showTransition, addTransition } from 'store/reducers/UI';

const DelayLink = ({
  to: toProp,
  delay,
  onDelayStart,
  onDelayEnd,
  transition,
  linkClassName,
  className,
  children,
  ...rest
}) => {
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const to =
    typeof toProp === 'string'
      ? { pathname: toProp, state: { from: window.location.pathname, transition } }
      : {
          ...toProp,
          state: { ...toProp.state, from: window.location.pathname, transition },
        };
  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    setDisabled(true);
    dispatch(addTransition(transition));
    dispatch(hideTransition());
    onDelayStart(e, to);
    if (e.defaultPrevented) {
      return;
    }
    e.preventDefault();

    setTimeout(() => {
      const samePath = typeof to === 'string' ? to === pathname : to.pathname === pathname;
      if (!samePath) {
        history.push(to);
      }
      dispatch(showTransition());
      onDelayEnd(e, to);
      setDisabled(false);
    }, delay);
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Link {...rest} to={to} onClick={(ev) => handleClick(ev)} className={(className ?? '') + (linkClassName ?? '')}>
      {children}
    </Link>
  );
};

DelayLink.propTypes = {
  // Milliseconds to wait before registering the click.
  delay: PropTypes.number,

  // Called after the link is clicked and before the delay timer starts.
  onDelayStart: PropTypes.func,

  // Called after the delay timer ends.
  onDelayEnd: PropTypes.func,

  // Link to path
  to: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object]).isRequired,

  transition: PropTypes.string,

  linkClassName: PropTypes.string,

  className: PropTypes.string,

  children: PropTypes.node.isRequired,
};

DelayLink.defaultProps = {
  delay: 200,
  transition: 'no',
  linkClassName: undefined,
  className: undefined,
  onDelayStart: () => {},
  onDelayEnd: () => {},
};

export default DelayLink;
