import React from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import DelayLink from '../../components/DelayLink';

const useStyles = makeStyles((theme) => ({
  icon: {
    '& path': {
      fill: '#8e8e93',
      stroke: '#8e8e93',
    },
  },
  list: {
    backgroundColor: theme.palette.common.white,
    padding: 0,
  },
  listItem: {
    minHeight: 80,
  },
}));

const Settings = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <List className={classes.list}>
        <ListItem
          className={classes.listItem}
          button
          divider
          component={DelayLink}
          to="/user/settings/personal"
          transition="slide-right"
        >
          <ListItemText
            primary={t('user.settings.index.primaryPersonalSettings')}
            secondary={t('user.settings.index.secondaryPersonalSettings')}
          />
          <ListItemSecondaryAction>
            <ArrowRightIcon className={classes.icon} />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          className={classes.listItem}
          button
          divider
          component={DelayLink}
          to="/user/settings/app"
          transition="slide-right"
        >
          <ListItemText
            primary={t('user.settings.index.primaryAppSettings')}
            secondary={t('user.settings.index.secondaryAppSettings')}
          />
          <ListItemSecondaryAction>
            <ArrowRightIcon className={classes.icon} />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          className={classes.listItem}
          button
          divider
          component={DelayLink}
          to="/user/settings/security"
          transition="slide-right"
        >
          <ListItemText
            primary={t('user.settings.index.primarySecurity')}
            secondary={t('user.settings.index.secondarySecurity')}
          />
          <ListItemSecondaryAction>
            <ArrowRightIcon className={classes.icon} />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
};

export default Settings;
