import React from 'react';
import PropTypes from 'prop-types';
import { Fade, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}));

const AnimationInner = ({ children, ...rest }) => {
  const classes = useStyles();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <div {...rest} className={classes.root}>
      {children}
    </div>
  );
};

AnimationInner.propTypes = {
  children: PropTypes.node,
};

AnimationInner.defaultProps = {
  children: null,
};

const Transition = ({ show, delay, children }) => (
  <Fade in={show} timeout={delay}>
    <AnimationInner>{children}</AnimationInner>
  </Fade>
);

Transition.propTypes = {
  show: PropTypes.bool.isRequired,
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.object,]),
  children: PropTypes.node,
};

Transition.defaultProps = {
  delay: 250,
  children: null,
};

export default Transition;
