export default {
  root: {
    '&$selected': {
      paddingTop: 8,
      '& path': {
        fill: '#298070',
        transition: 'fill 500ms cubic-bezier(0.4, 0, 0.2, 1)',
      },
    },
    '&$iconOnly': {
      paddingTop: 8,
    },
  },
};
