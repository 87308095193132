export default {
  root: {
    textTransform: 'unset',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: '0.4px',
  },
  icon: {
    top: '50%',
    transform: 'translateY(-50%)',
  },
};
