import React, { useState } from 'react';
import { Grid, List, ListItem, ListItemSecondaryAction, Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PenIcon } from 'assets/icons/Pen.svg';
import { DelayLinkFab } from 'components/DelayLinkButtons';
import { messages } from 'utils/mocked-data';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 50,
  },
  buttonContainer: {
    paddingTop: 20,
    width: '100%',
    backgroundColor: 'white',
    margin: 0,
  },
  list: {
    backgroundColor: 'white',
    padding: 0,
  },
  messageTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  messageDate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'right',
  },
  listItem: {
    minHeight: 50,
  },
  icon: {
    '& path': {
      fill: '#8e8e93',
    },
  },
  createFab: {
    position: 'fixed',
    bottom: 60,
    right: 10,
    '& path': {
      fill: '#FFF',
    },
  },
}));

const Index = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const monthNames = t('month-names', { returnObjects: true });

  const [selected, setSelected] = useState('inbox');
  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.buttonContainer}>
        <Grid item xs={6}>
          <Button variant={selected === 'inbox' ? 'contained' : 'outlined'} onClick={() => setSelected('inbox')}>
            {t('inbox')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant={selected === 'sent' ? 'contained' : 'outlined'} onClick={() => setSelected('sent')}>
            {t('sent')}
          </Button>
        </Grid>
      </Grid>
      <List className={classes.list}>
        {messages.map((m) => (
          <ListItem divider button key={m.title} className={classes.listItem}>
            <Grid container justify="space-between">
              <Grid item xs={9} className={classes.messageTitle}>
                {m.title}
              </Grid>
              <Grid item xs={3} component="b" className={classes.messageDate}>
                {m.date.getDate() === new Date().getDate()
                  ? `${m.date.getHours()}:${m.date.getMinutes() < 10 ? `0${m.date.getMinutes()}` : m.date.getMinutes()}`
                  : `${m.date.getDate()} ${monthNames[m.date.getMonth()].substring(0, 3)}`}
              </Grid>
            </Grid>
            <ListItemSecondaryAction>
              <PenIcon className={classes.icon} />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <DelayLinkFab to="/messages/create" color="primary" className={classes.createFab} size="small">
        <PenIcon />
      </DelayLinkFab>
    </div>
  );
};

export default Index;
