import axios from 'axios';

import { apiEndpoints } from '../config';

const plansEndpoint = apiEndpoints.user.plans;

export async function fetchPlansAsync() {
  try {
    const res = await axios.get(`${plansEndpoint}`);
    return res.data;
  } catch {
    return false;
  }
}

export async function fetchPlanAsync(id) {
  const res = await axios.get(`${plansEndpoint}/${id}`);
  return res.data;
}

export async function createPlanAsync(data) {
  try {
    const res = await axios.post(`${plansEndpoint}`, data);
    return res.data;
  } catch {
    return false;
  }
}

export async function updatePlanAsync(id, data) {
  try {
    const res = await axios.put(`${plansEndpoint}/${id}`, data);
    return res.data;
  } catch {
    return false;
  }
}

export async function sellPlanSharesAsync(id, amountToSell, currentPassword) {
  const res = await axios.post(`${plansEndpoint}/${id}/sell`, {
    amountToSell,
    currentPassword,
  });
  return res.data;
}

export async function endPlanAsync(id, currentPassword) {
  const res = await axios.post(
    `${plansEndpoint}/${id}/end`,
    {},
    {
      headers: {
        'Content-Type': 'text/plain',
      },
      params: { currentPassword },
    }
    /* ,{
    ,
  }*/
  );
  return res.data;
}
