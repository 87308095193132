import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Redirect, useParams } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

import { updateBackPath } from 'store/reducers/UI';
import useDelayHistory from 'hooks/useDelayHistory';
import { useEndPlan } from 'store/reducers/plans';
import { formatPrice } from 'utils/helpers';
import LinearProgress from 'components/UI/CustomLinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 40px - 60px)',
    padding: '6vh 20px',
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: 5,
  },
  header: {
    margin: '0 20px',
  },
  title: {
    marginBottom: 30,
    lineHeight: '30px',
  },
  valueText: {
    color: theme.palette.accent.darkBlue,
    fontWeight: 400,
    marginBottom: 15,
    fontSize: 16,
  },
  textField: {
    marginTop: 5,
  },
  button: {
    fontWeight: 500,
  },
  noticeHeader: {
    marginTop: 20,
    marginBottom: 15,
    textTransform: 'uppercase',
  },
}));

const Close = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { planId } = useParams();
  const { plans, fetched } = useSelector((state) => state.plans);
  const [, endPlan] = useEndPlan();
  const delayHistory = useDelayHistory();

  const { register, handleSubmit, errors, setError, clearErrors, formState } = useForm({
    defaultValues: {
      password: '',
    },
  });
  const { isSubmitting } = formState;

  const plan = plans?.find((p) => p.id === planId);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateBackPath({ path: `/user/plans/${planId}` }));
  }, [planId, dispatch]);

  if (!fetched) return <LinearProgress />;

  if (fetched && !plan) {
    return <Redirect to="/user/plans" from={window.location.pathname} />;
  }

  const onSubmit = async (data) => {
    try {
      await endPlan(planId, data.password);
      delayHistory.push(`/user/plans/closed/${planId}`);
    } catch (error) {
      const status = error.response?.status;
      let type, message;
      if (status === 400) {
        type = error.response?.data?.type;
        message = error.response?.data?.detail;
      } else if (status === 403) {
        type = error.response?.data?.type;
        message = error.response?.data?.title;
      }
      if (type === 'invalid_password' && message) {
        setError('password', {
          type: 'manual',
          message,
        });
      } else if (type && message) {
        setError('serverError', {
          type: 'manual',
          message,
        });
      }
    }
  };

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      spacing={2}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12}>
        <div className={classes.header}>
          <Typography variant="h3" align="center" className={classes.title}>
            {t('user.plans.close.title')}
          </Typography>
        </div>
        <Typography color="primary">{t('planName')}</Typography>
        <Typography className={classes.valueText}>{plan.name}</Typography>
        <Typography color="primary">{t('currentValue')}</Typography>
        <Typography className={classes.valueText}>{formatPrice(plan.value, plan?.currency?.code)}</Typography>
        <Typography color="primary">{t('user.plans.close.passwordText')}</Typography>
        <TextField
          type="password"
          name="password"
          inputRef={register}
          label={t('password')}
          required
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
          disabled={isSubmitting}
          color="primary"
          className={classes.textField}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          className={classes.button}
          disabled={isSubmitting}
          variant="outlined"
          onClick={() => delayHistory.goBack()}
        >
          {t('cancel')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button type="submit" disabled={isSubmitting} onClick={() => clearErrors()}>
          {t('closePlan')}
        </Button>
      </Grid>
      {Boolean(errors.serverError) && (
        <Grid item xs={12}>
          <Typography color="error">{errors.serverError.message}</Typography>
        </Grid>
      )}
      <Grid item>
        <Typography variant="subtitle2" className={classes.noticeHeader}>
          {t('important')}
        </Typography>
        <Typography variant="body1">
          <Trans i18nKey="user.plans.close.notice" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Close;
