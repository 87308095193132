import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { REGISTER_TOP_BAR_HEIGHT } from 'utils/constants';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    flexGrow: 1,
    position: 'relative',
    backgroundColor: theme.palette.accent.pinkLady,
    height: `calc(100vh - ${REGISTER_TOP_BAR_HEIGHT}px)`,
    overflow: 'auto',
    padding: 20,
    paddingTop: '12vh',
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: 50,
  },
}));

const WelcomeBack = ({ handleNext }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.root} direction="column">
      <Grid item className={classes.content}>
        <div>
          <Typography variant="h2" className={classes.title}>
            {t('register-transfer.welcome-back.title-1')}
          </Typography>
          <Typography variant="h2" className={classes.title}>
            {t('register-transfer.welcome-back.title-2')}
          </Typography>
          <Button onClick={handleNext}>{t('register-transfer.welcome-back.button')}</Button>
        </div>
      </Grid>
    </Grid>
  );
};

WelcomeBack.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default WelcomeBack;
