import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DonutCard from 'components/Cards/DonutCard';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import StockAccordion from 'components/StockAccordion';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AssetDetails from 'components/AssetDetails';
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 20,
  },
  selectPortfolioButton: {
    margin: 20,
  },
  tabs: {
    marginTop: -15,
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%)',
  },
  closeAssetBtn: {
    transform: 'rotate(180deg)',
    '& svg path': {
      fill: 'black',
    },
  },
  assetContent: {
    marginTop: -23,
  },
}));

const Investments = ({ portfolio }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  // Can be either assetClassBreakdown, sectionalBreakdown or regionalBreakdown.
  const [selectedBreakdown, setSelectedBreakdown] = useState('assetClassBreakdown');
  const [showAsset, setShowAsset] = useState();

  if (showAsset) {
    return (
      <>
        <IconButton size="small" onClick={() => setShowAsset(undefined)} className={classes.closeAssetBtn}>
          <CrossIcon />
        </IconButton>
        <div className={classes.assetContent}>
          <AssetDetails asset={showAsset} />
        </div>
      </>
    );
  }

  const formatAssetData = (port, breakdown) => {
    if (!port || !port[breakdown] || !port?.assets) return;
    return Object.entries(port[breakdown]).map(([key, fraction]) => ({
      percentage: fraction * 100,
      label: breakdown === 'assetClassBreakdown' ? t(`asset-class-enum.${key}`) : key,
      assets: breakdown === 'assetClassBreakdown' ? port.assets.filter((a) => a.assetClass === key) : [],
    }));
  };

  if (!portfolio) return null;
  return (
    <>
      <DonutCard
        key={`chart-${selectedBreakdown}`}
        data={Object.entries(portfolio[selectedBreakdown] ?? {}).map(([key, value]) => ({
          label: key,
          y: value,
        }))}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        title={t(`portfolios.${portfolio.name}`)}
        labelTitle={
          typeof selectedIndex === 'number' &&
          `${Number.parseFloat(Object.values(portfolio[selectedBreakdown] ?? {})[selectedIndex] * 100).toFixed(2)}%`
        }
        labelSubtitle={
          typeof selectedIndex === 'number' && Object.keys(portfolio[selectedBreakdown] ?? {})[selectedIndex]
        }
      />
      <AppBar color="primary" position="relative" className={classes.tabs}>
        <Tabs
          variant="fullWidth"
          value={selectedBreakdown}
          onChange={(e, newValue) => {
            setSelectedIndex(0);
            setSelectedBreakdown(newValue);
          }}
        >
          <Tab label={t('asset-class')} value="assetClassBreakdown" />
          <Tab label={t('sector')} value="sectionalBreakdown" />
          <Tab label={t('region')} value="regionalBreakdown" />
        </Tabs>
      </AppBar>
      <StockAccordion
        stockData={formatAssetData(portfolio, selectedBreakdown)}
        setSelectedAccordion={setSelectedIndex}
        selectedAccordion={selectedIndex}
        handleStockClick={setShowAsset}
      />
    </>
  );
};

Investments.propTypes = {
  portfolio: PropTypes.object.isRequired,
};

export default Investments;
