import { LinearProgress, withStyles } from '@material-ui/core';

export default withStyles(() => ({
  root: {
    height: 8,
    borderRadius: 4,
    backgroundColor: '#eceff1',
    marginBottom: 5,
  },
  bar: (props) => ({
    backgroundColor: props.barcolor,
    height: 8,
    borderRadius: 4,
  }),
}))(LinearProgress);
