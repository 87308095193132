import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { updateBackPath } from 'store/reducers/UI';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import { isUserVerified } from 'utils/helpers';

const useStyles = makeStyles(() => ({
  expandIcon: {
    '& path': {
      fill: '#8e8e93',
      stroke: '#8e8e93',
    },
    '&.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },
  accordion: {
    boxShadow: 'none',
    margin: '0 !important',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
  },
  accordionSummaryRoot: {
    minHeight: '80px !important',
  },
  accordionSummaryContent: {
    color: '#8e8e93',
    margin: '12px 0 !important',
  },
}));

const Faq = () => {
  const classes = useStyles();
  const [expandedId, setExpandedId] = useState(null);
  const { t } = useTranslation(['faq']);
  const questions = t('questions', { returnObjects: true });
  const userData = useSelector((state) => state.user.data);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateBackPath({ path: isUserVerified(userData) ? '/user/info' : '/user/contact' }));
  }, [dispatch, userData]);

  return (
    <>
      {Object.values(questions)
        .flat()
        .map((item, index) => (
          <Accordion
            square
            key={item.question}
            className={classes.accordion}
            expanded={expandedId === index}
            onChange={() => setExpandedId((prev) => (prev === index ? null : index))}
          >
            <AccordionSummary
              classes={{
                root: classes.accordionSummaryRoot,
                content: classes.accordionSummaryContent,
                expandIcon: classes.expandIcon,
              }}
              expandIcon={<ArrowRightIcon />}
            >
              {item.question}
            </AccordionSummary>
            <AccordionDetails>{item.answer}</AccordionDetails>
          </Accordion>
        ))}
    </>
  );
};

export default Faq;
