import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { hideTransition, showTransition, addTransition } from 'store/reducers/UI';

export default (withTransition = true, delay = 250) => {
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const push = (to, onDelayStart = () => {}, onDelayEnd = () => {}) => {
    if (disabled) return;
    setDisabled(true);
    if (onDelayStart) onDelayStart();
    if (withTransition) dispatch(hideTransition());
    dispatch(addTransition(to?.state?.transition ?? 'no'));

    setTimeout(() => {
      history.push(to);
      if (withTransition) dispatch(showTransition());
      if (onDelayEnd) onDelayEnd();
      setDisabled(false);
    }, delay);
  };

  const goBack = async (onDelayStart = () => {}, onDelayEnd = () => {}) => {
    if (disabled) return;
    setDisabled(true);
    if (onDelayStart) await onDelayStart();
    if (withTransition) dispatch(hideTransition());

    setTimeout(async () => {
      history.goBack();
      if (withTransition) dispatch(showTransition());
      if (onDelayEnd) await onDelayEnd();
      setDisabled(false);
    }, delay);
  };

  const replace = async (to, onDelayStart = () => {}, onDelayEnd = () => {}) => {
    if (disabled) return;
    setDisabled(true);
    if (onDelayStart) await onDelayStart();
    if (withTransition) dispatch(hideTransition());

    setTimeout(async () => {
      history.replace(to);
      if (withTransition) dispatch(showTransition());
      if (onDelayEnd) await onDelayEnd();
      setDisabled(false);
    }, delay);
  };

  return { ...history, push, goBack, replace };
};
