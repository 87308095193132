export const SuitabilitySurveys = [
  {
    identifier: 'identifier',
    countries: [],
    questionnaires: [
      {
        version: 1,
        portfolios: [
          { name: 'cautious', minimumPoints: 0 },
          { name: 'tentative', minimumPoints: 5 },
          { name: 'confident', minimumPoints: 15 },
          { name: 'ambitious', minimumPoints: 30 },
          { name: 'adventurous', minimumPoints: 65 },
        ],
        questions: [
          {
            totalPoints: 12.5,
            pointsPerAnswer: [0.3, 0.3, 0.3, 0.05, 0.05],
          },
          {
            totalPoints: 12.5,
            pointsPerAnswer: [0.35, 0.35, 0.1, 0.2],
          },
          {
            totalPoints: 12.5,
            pointsPerAnswer: [0.025, 0.125, 0.225, 0.3, 0.325],
          },
          {
            totalPoints: 12.5,
            pointsPerAnswer: [0.025, 0.125, 0.225, 0.3, 0.325],
          },
          {
            totalPoints: 12.5,
            pointsPerAnswer: [0.325, 0.3, 0.225, 0.125, 0.025],
          },
          {
            totalPoints: 12.5,
            pointsPerAnswer: [0.45, 0.3, 0.2, 0.05, 0],
          },
          {
            totalPoints: 12.5,
            pointsPerAnswer: [0.025, 0.125, 0.2, 0.25, 0.4],
          },
          {
            totalPoints: 12.5,
            pointsPerAnswer: [0.025, 0.125, 0.225, 0.3, 0.325],
          },
        ],
      },
    ],
  },
];

export const calcTotalPoints = (questions) => {
  return questions.reduce((acc, curr) => acc + Math.max(...curr.pointsPerAnswer) * curr.totalPoints, 0);
};

export const calcPortfolio = (answers, questionnaire) => {
  const { questions } = questionnaire;
  const answerPoints = answers
    .map((value, index) => {
      const question = questions[index];
      return question.pointsPerAnswer[value] * question.totalPoints;
    })
    .reduce((acc, curr) => acc + curr, 0);

  for (let i = questionnaire.portfolios.length - 1; i >= 0; i--) {
    if (answerPoints >= questionnaire.portfolios[i].minimumPoints) {
      return questionnaire.portfolios[i].name;
    }
  }
  return 0;
};

export const getSurveyById = (identifier) => {
  return SuitabilitySurveys.find((s) => s.identifier === identifier);
};

export const getQuestionnaireByVersion = (version, survey) => {
  return survey ? survey.questionnaires.find((q) => q.version === version) : null;
};

export const getSurveyByCountry = (countryId) => {
  // Todo: Fix this once countries is specified on the surveys below
  return SuitabilitySurveys[0];
};

export const getLatestQuestionnaire = (identifier) => {
  if (!identifier) return null;
  const surveys = SuitabilitySurveys.find((s) => s.identifier === identifier);
  const questionnaires = [...surveys.questionnaires];
  questionnaires.sort((a, b) => (a.version < b.version ? 1 : -1));
  return questionnaires[0];
};
