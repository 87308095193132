import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, makeStyles, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NavPrompt from 'components/NavPrompt';

import Transition from 'components/Transition';
import useTransition from 'hooks/useTransition';
import { useStepValidation } from 'hooks/useStepValidation';
import { useUpdateAddress, useUpdatePersonalSettings } from 'store/reducers/user';
import { redirectUser } from 'utils/helpers';
import RegisterTopBar from 'components/Layout/RegisterTopBar';
import { REGISTER_TOP_BAR_HEIGHT } from 'utils/constants';

import Intro from './review/Intro';
import AddressForm from './review/AddressForm';
import ReviewDetails from './review/ReviewDetails';
import { useSetAppSafeArea } from 'hooks/useSetAppSafeArea';
import { useSetAppNotchColor } from 'hooks/useSetAppNotchColor';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.contrastText,
  },
  headerInfo: {
    margin: '20px 30px 25px',
  },
  subtitle: {
    marginTop: 20,
  },
  contentContainer: {
    paddingTop: REGISTER_TOP_BAR_HEIGHT,
    minHeight: '100vh',
  },
  content: {
    padding: '0 20px',
    flexGrow: 1,
  },
}));

const Review = () => {
  const classes = useStyles();
  useSetAppSafeArea(true);
  useSetAppNotchColor('#FFFFFF');
  const { toggleAnimation, showTransition, delay } = useTransition();
  const [
    { loading: updatingAddress, value: valueAddress, error: errorAddress },
    updateAddress,
    resetAddressState,
  ] = useUpdateAddress();
  const [{ loading: updatingSettings, error: errorSettings }, updateSettings] = useUpdatePersonalSettings();
  const userData = useSelector((state) => state.user.data);
  const { plans } = useSelector((state) => state.plans);
  const { address } = userData.client;
  const [done, setDone] = useState(false);
  const [data, setData] = useState({
    line1: address.line1,
    line2: address.line2,
    line3: address.line3,
    city: address.city,
    region: address.region,
    zipCode: address.zipCode,
    countryId: address.country?.id,
    portfolio: plans[0]?.portfolioName,
  });
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const step = history.location.state?.step ?? 0;

  const steps = [
    (handleNext) => <Intro handleNext={handleNext} />,
    (handleNext) => <AddressForm handleNext={handleNext} userData={data} updateUserData={setData} />,
    (handleNext) => (
      <ReviewDetails
        handleNext={handleNext}
        userData={userData}
        data={data}
        updateUserSettings={updateSettings}
        updating={done || updatingSettings || updatingAddress}
      />
    ),
  ];
  const { stepsValidated, validateStep, invalidateStep } = useStepValidation(steps.length);

  const handleNext = (callback = () => {}, customStep) => {
    if (step + 1 === steps.length) {
      resetAddressState();
      setDone(true);
    } else {
      toggleAnimation(() => {
        if (typeof callback === 'function') callback();
        validateStep(step);
        let nextStep = step + 1;
        if (customStep) {
          nextStep = customStep;
        }
        history.push(`/register/review`, { step: nextStep });
        window.scrollTo(0, 0);
      });
    }
  };

  const handleBack = () => {
    if (step === 0) {
      // history.goBack();
    } else {
      toggleAnimation(() => {
        invalidateStep(step);
        history.goBack();
      });
    }
  };

  useEffect(() => {
    async function update() {
      try {
        await updateAddress(data);
      } catch {
        setDone(false);
      }
    }
    if (done && !updatingAddress && !valueAddress && !errorAddress) {
      update();
    } else {
      setDone(false);
    }
  }, [done, updateAddress, updatingAddress, errorAddress, valueAddress, data]);

  useEffect(() => {
    if (valueAddress) {
      redirectUser(history.push, userData);
    } else if (errorAddress || errorSettings) {
      console.log('error', errorAddress, errorSettings);
    }
  }, [history, valueAddress, errorAddress, errorSettings, userData]);

  useEffect(() => {
    if (step > 0 && !stepsValidated[step]) {
      history.replace('/register/review');
    } else {
      redirectUser(history.push, userData);
    }
    // eslint-disable-next-line
  }, []);

  const [progressPercent, setProgressPercent] = useState(step / steps.length);
  useEffect(() => {
    if (typeof step === 'number') {
      setProgressPercent((step + 1) / steps.length);
    }
  }, [step, steps]);

  return (
    <div className={classes.root}>
      <NavPrompt
        when={(crntLocation, nextLocation) =>
          !nextLocation.pathname.startsWith('/identity-verification') &&
          (!nextLocation || !nextLocation.pathname.startsWith(crntLocation.pathname))
        }
      />
      <RegisterTopBar
        progressValue={{ min: 40, max: 50, percent: progressPercent }}
        handleBack={handleBack}
        disabled={done || updatingSettings || updatingAddress}
        hideBackBtn={step === 0}
      />
      <Transition show={showTransition} delay={delay}>
        <Grid container className={classes.contentContainer} direction="column">
          {step === 0 ? (
            steps[step](handleNext)
          ) : (
            <>
              <Grid item className={classes.headerInfo}>
                <Typography variant="h3" align="center">
                  {t(`register-review.step${step}.title`)}
                </Typography>
                {i18n.exists(`register-review.step${step}.subtitle`) && (
                  <Typography variant="body1" color="primary" align="center" className={classes.subtitle}>
                    {t(`register-review.step${step}.subtitle`)}
                  </Typography>
                )}
              </Grid>
              <Grid item className={classes.content}>
                {steps[step](handleNext)}
              </Grid>
            </>
          )}
        </Grid>
      </Transition>
    </div>
  );
};

export default Review;
