import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { useSelector } from 'react-redux';

import theme from './theme';
import useStyles from './styles';
import Transition from '../components/Transition';
import SnackbarProvider from './components/SnackbarProvider';

const DefaultLayout = ({ children }) => {
  const classes = useStyles(theme)();
  const { showTransition } = useSelector((state) => state.UI);
  console.log(theme);
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <CssBaseline />
        <Transition show={showTransition} delay={250}>
          <main className={classes.main}>{children}</main>
        </Transition>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DefaultLayout;
