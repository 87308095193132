import axios from 'axios';

import { apiEndpoints } from '../config';

const onfidoEnpoint = apiEndpoints.user.onfido;

export async function generateOnfidoSdkTokenAsync(useReferrerPath) {
  try {
    let referrerPath = null;
    if (useReferrerPath) {
      referrerPath = '/';
    }
    const res = await axios.post(
      `${onfidoEnpoint}/generate-token`,
      { referrerPath: referrerPath, useReferrerPath: useReferrerPath },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    return res.data;
  } catch {
    return false;
  }
}

export async function createOnfidoCheckAsync() {
  try {
    const res = await axios.post(`${onfidoEnpoint}/create-check`);
    return res.data;
  } catch {
    return false;
  }
}

export async function updateOnfidoDocumentStatusAsync(data) {
  try {
    const res = await axios.put(`${onfidoEnpoint}/onboarding-status`, data);
    return res.data;
  } catch {
    return false;
  }
}
