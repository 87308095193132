import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles, List, ListItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import CustomButton from 'components/UI/CustomButton';
import { useSelector } from 'react-redux';
import { formatPrice } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 40px - 60px)',
    paddingTop: '12vh',
    paddingBottom: 10,
    backgroundColor: theme.palette.primary.main,
  },
  header: {
    margin: '0 20px',
  },
  title: {
    marginBottom: 15,
    fontWeight: 300,
  },
  body: {
    fontWeight: 300,
    marginBottom: 30,
  },
  list: {
    padding: 0,
    background: 'white',
    '& li': {
      minHeight: 50,
      display: 'flex',
      justifyContent: 'space-between',
      '& > div:last-child': {
        textAlign: 'right',
        whiteSpace: 'pre',
      },
    },
  },
  button: {
    marginTop: 10,
    borderRadius: 0,
    height: 50,
    textTransform: 'uppercase',
  },
  notice: {
    fontWeight: 300,
    margin: '20px 20px',
  },
  icon: {
    height: 13,
    '& path': {
      fill: '#000',
      stroke: '#000',
    },
  },
}));

const Item = ({ label, value }) => {
  if (!value) return null;
  return (
    <ListItem divider>
      <div>{label}</div>
      <div>{value}</div>
    </ListItem>
  );
};
Item.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

const Finished = ({ handleNext, data }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { accountNumber } = useSelector((state) => state.user.data.client.bankAccount);
  const currency = useSelector((state) => state.misc.detailedCurrencies.find((c) => c.code === data.currency));
  return (
    <Grid container className={classes.root} direction="column" justify="space-between">
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.header}>
            <Typography variant="h3" align="center" color="secondary" className={classes.title}>
              {t('user.transfer.top-up.create.finished.title')}
            </Typography>
            <Typography variant="body1" align="center" color="secondary" className={classes.body}>
              {t('user.transfer.top-up.create.finished.body')}
            </Typography>
          </div>
          <List className={classes.list}>
            <Item label={t('recipient')} value={currency?.beneficiaryName} />
            <Item label={t('accountNumber')} value={currency?.accountNumber} />
            <Item label={t('iban')} value={currency?.iban} />
            <Item label={t('swiftCode')} value={currency?.swift} />
            <Item label={t('sortCode')} value={currency?.sortCode} />
            <Item label={t('address')} value={currency?.address} />
            <Item label={t('amount')} value={formatPrice(data.amount, data.currency)} />
            <Item label={t('reference')} value={data.transaction?.wireTransferMessageIdentifier} />
          </List>
          <Typography variant="body1" align="center" color="secondary" className={classes.notice}>
            {t('user.transfer.top-up.create.finished.notice', {
              accountNumber: `****${accountNumber.substring(accountNumber.length - 4)}`,
            })}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={6}>
          <CustomButton
            bgcolor="melanie"
            onClick={handleNext}
            className={classes.button}
            endIcon={<ArrowRightIcon className={classes.icon} />}
          >
            {t('finish')}
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

Finished.propTypes = {
  handleNext: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default Finished;
