import React, { useState, useEffect } from 'react';
import { BottomNavigation, BottomNavigationAction, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { hasUserCompletedInitialSetup, hasUserCompletedOnboarding } from 'utils/helpers';

import DelayLink from 'components/DelayLink';
import { ReactComponent as PlanMenuIcon } from 'assets/icons/PlanMenuIcon.svg';
import { ReactComponent as CardMenuIcon } from 'assets/icons/CardMenuIcon.svg';
import { ReactComponent as InfoMenuIcon } from 'assets/icons/InfoMenuIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[2],
    height: 50,
    position: 'fixed',
    bottom: 0,
    width: '100vw',
    zIndex: 1000,
  },
}));

const BottomNav = () => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const { fetched: userFetched, data: userData } = useSelector((state) => state.user);
  const { fetched: plansFetched, plans } = useSelector((state) => state.plans);
  const items = [
    {
      to: '/user/plans',
      value: '/user/plans',
      icon: <PlanMenuIcon />,
    },
    {
      to: '/user/transfer',
      value: '/user/transfer',
      icon: <CardMenuIcon />,
    },
    {
      to: '/user/info',
      value: '/user/info',
      icon: <InfoMenuIcon />,
    },
  ];

  const getValue = (pathname) => {
    if (pathname.startsWith('/user/plans')) return '/user/plans';
    if (pathname.startsWith('/user/info')) return '/user/info';
    if (pathname.startsWith('/user/transfer')) return '/user/transfer';
    return pathname;
  };

  const getTransition = (currentIndex) => {
    const indexOfItem = items.findIndex((i) => pathname.startsWith(i.to));
    if (indexOfItem < 0) return 'fade';
    if (currentIndex > indexOfItem) return 'slide-right';
    return 'slide-left';
  };

  const [value, setValue] = useState(getValue(pathname));

  useEffect(() => {
    setValue(getValue(pathname));
  }, [pathname, setValue]);

  if (!userFetched || !plansFetched) return null;
  if (!hasUserCompletedOnboarding(userData) || !hasUserCompletedInitialSetup(userData, plans)) return null;

  return (
    <BottomNavigation value={value} className={classes.root}>
      {items.map((item, index) => (
        <BottomNavigationAction
          key={item.value}
          component={DelayLink}
          delay={0}
          to={item.to}
          transition={getTransition(index)}
          value={item.value}
          icon={item.icon}
        />
      ))}
    </BottomNavigation>
  );
};

export default BottomNav;
