import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';

const NavPrompt = ({ when, message }) => {
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    /**
     * Needed to clear the history block when unmounting the component since NavigationPrompt component won't always
     * do this on its own when using history.push()
     */
    return () => history.block(true);
  }, [history]);
  return (
    <NavigationPrompt when={(crntLocation, nextLocation) => when(crntLocation, nextLocation)}>
      {({ isActive, onConfirm, onCancel }) => (
        <ConfirmDialog
          open={isActive}
          onCancel={onCancel}
          onConfirm={onConfirm}
          message={message || t('notice-messages.leavingPage')}
        />
      )}
    </NavigationPrompt>
  );
};

NavPrompt.propTypes = {
  when: PropTypes.func.isRequired,
  message: PropTypes.string,
};

NavPrompt.defaultProps = {
  message: null,
};

export default NavPrompt;
