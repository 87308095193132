import { useState } from 'react';

export function useStepValidation(length) {
  const [stepsValidated, setStepsValidated] = useState(new Array(length).fill(false));

  const validateStep = (index) => {
    const newStepsValidated = [...stepsValidated];
    newStepsValidated[index] = true;
    setStepsValidated(newStepsValidated);
  };

  const invalidateStep = (index) => {
    const newStepsValidated = [...stepsValidated];
    newStepsValidated[index] = false;
    setStepsValidated(newStepsValidated);
  };

  return {
    stepsValidated,
    validateStep,
    invalidateStep,
  };
}
