import React from 'react';
import classNames from 'classnames';
import { AppBar, Toolbar, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { resetBackPath, clearBackAction } from 'store/reducers/UI';
import { ReactComponent as BurgerMenuIcon } from 'assets/icons/BurgerMenu.svg';
import { ReactComponent as PointerBackIcon } from 'assets/icons/PointerBack.svg';
import { DelayIconButton } from 'components/DelayButtons';
import useToggle from 'hooks/useToggle';
import useDelayHistory from 'hooks/useDelayHistory';
import SideDrawer from 'components/Layout/SideDrawer';
import { USER_TOP_BAR_HEIGHT } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[1],
    height: USER_TOP_BAR_HEIGHT,
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: '0.7px',
    lineHeight: '17px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 12px',
  },
  button: {
    width: 44,
  },

  hiddenButton: {
    visibility: 'hidden',
    pointerEvents: 'none',
  },
}));
const TopBar = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { backPath, backAction, goBackOverride } = useSelector((state) => state.UI);
  const delayHistory = useDelayHistory(true, 0);
  const dispatch = useDispatch();
  const [sideDrawerOpen, toggleSideDrawerOpen] = useToggle();
  const { pathname, state } = useLocation();
  const userData = useSelector((state) => state.user.data);

  const title = () => {
    if (pathname.startsWith('/user/plans')) {
      if (pathname.includes('overview')) return `${t('planText')} ${t('overview')}`;
      if (pathname.includes('activity')) return `${t('planText')} ${t('activity')}`;
      if (pathname.includes('investments')) return `${t('planText')} ${t('investments')}`;
    }
    if (pathname.startsWith('/user/transfer')) {
      if (pathname.includes('withdraw')) return t('withdraw');
      if (pathname.includes('top-up')) return t('topUp');
      return t('transfer');
    }
    if (pathname.startsWith('/user/settings')) {
      if (pathname.includes('personal')) {
        return `${t('personal')} ${t('settings')}`;
      }
      if (pathname.includes('security')) {
        if (pathname.includes('change-password')) return t('changePassword');
        if (pathname.includes('legal-notices')) return t('legalNotices');
        return `${t('security')} ${t('settings')}`;
      }
      if (pathname.includes('app')) {
        if (pathname.includes('languages')) return `${t('language')} ${t('settings')}`;
        return `${t('app')} ${t('settings')}`;
      }
      return t('settings');
    }
    if (pathname.startsWith('/user/contact')) {
      return t('talkToUs');
    }
    if (pathname.startsWith('/user/messages')) {
      return t('messages');
    }
    if (pathname.startsWith('/user/info')) {
      if (pathname.includes('faq')) return 'FAQ';
      return t('talkToUs');
    }
    return `${t('hi')} ${userData.givenName}`;
  };

  const showBackButton = () => pathname.split('/').length > 3;

  const handleBack = async () => {
    if (backAction && backAction instanceof Function) {
      await backAction();
      dispatch(clearBackAction());
    }
    if (goBackOverride) {
      delayHistory.goBack();
      return;
    }
    const prevPath = state?.from;
    if (backPath !== '' && backPath !== prevPath) {
      delayHistory.replace({ pathname: backPath, state: { transition: 'slide-left' } }, () =>
        dispatch(resetBackPath())
      );
    } else {
      delayHistory.goBack(() => dispatch(resetBackPath()));
    }
  };

  return (
    <AppBar position="fixed" color="secondary" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <DelayIconButton
          className={classNames(classes.button, { [classes.hiddenButton]: !showBackButton() })}
          onClick={handleBack}
        >
          <PointerBackIcon />
        </DelayIconButton>
        <SideDrawer open={sideDrawerOpen} toggleOpen={toggleSideDrawerOpen} />
        <span className={classes.title}>{title()}</span>
        <DelayIconButton onClick={toggleSideDrawerOpen} className={classes.button}>
          <BurgerMenuIcon />
        </DelayIconButton>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
