import { useState } from 'react';

export default function useTransition(initialState = true, delay = 250) {
  const [showTransition, setShowTransition] = useState(initialState);

  const toggleAnimation = (callback = () => {}) => {
    setTimeout(() => {
      setShowTransition(false);
      setTimeout(() => {
        callback();
        setShowTransition(true);
      }, delay);
    }, delay / 2);
  };

  const handleHideAnimation = (callback = () => {}) => {
    setShowTransition(false);
    setTimeout(() => {
      callback();
    }, delay);
  };
  const handleShowAnimation = (callback = () => {}) => {
    setShowTransition(true);
    setTimeout(() => {
      callback();
    }, delay);
  };

  return {
    showTransition,
    toggleAnimation,
    handleHideAnimation,
    handleShowAnimation,
    delay,
  };
}
