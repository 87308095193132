import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';

import { DelayButton } from './DelayButtons';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: '15px 10px',
  },
  button: {
    border: '2px solid transparent',
    height: 24,
    borderRadius: 12,
    minHeight: 'unset',
    minWidth: 'unset',
    fontSize: 12,
    padding: '0 6px',
    color: 'white',
    letterSpacing: '0.25px',
    textTransform: 'uppercase',
  },
  selectedButton: {
    borderColor: 'white',
  },
}));

const ButtonGroup = ({ buttons, selectedButton, handleOnClick }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root} justify="space-evenly">
      {buttons.map((button, index) => (
        <Grid item xs={4} container justify="center" key={button.name}>
          <DelayButton
            fullWidth={false}
            onClick={() => handleOnClick(button, index)}
            variant="text"
            className={classNames(classes.button, { [classes.selectedButton]: selectedButton === index })}
          >
            {button.name}
          </DelayButton>
        </Grid>
      ))}
    </Grid>
  );
};

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedButton: PropTypes.number.isRequired,
  handleOnClick: PropTypes.func.isRequired,
};

export default ButtonGroup;
