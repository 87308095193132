import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Typography, Button, Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Onfido from '../../components/Onfido';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    margin: 0,
    padding: 30,
  },
  title: {
    marginBottom: 20,
  },
  body: {
    marginBottom: 20,
  },
}));

const ProofOfAddress = ({ countryOfResidence, handleNext, handleBack, step }) => {
  const classes = useStyles();
  const { view } = useParams();
  const { t } = useTranslation();

  const handleStartPoaVerification = () => {
    handleNext(null, step, '/onfido-poa');
  };

  if (view === 'onfido-poa') {
    return (
      <Onfido
        handleBack={handleBack}
        handleNext={handleNext}
        steps={{ document: false, face: false, poa: true }}
        country={countryOfResidence}
      />
    );
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3" className={classes.title}>
          {t('identity-verification.poa.title')}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.body}>
        <Typography>{t('identity-verification.poa.body')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={handleStartPoaVerification}>{t('uploadNow')}</Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="outlined" onClick={handleNext}>
          {t('doThisLater')}
        </Button>
      </Grid>
    </Grid>
  );
};

ProofOfAddress.propTypes = {
  countryOfResidence: PropTypes.string.isRequired,
  handleNext: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
};

export default ProofOfAddress;
