import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Transition from 'components/Transition';
import useTransition from 'hooks/useTransition';
import { useStepValidation } from 'hooks/useStepValidation';
import {
  calcPortfolio,
  getLatestQuestionnaire,
  getQuestionnaireByVersion,
  getSurveyByCountry,
  getSurveyById,
} from 'utils/SuitabilityQuestions';
import RegisterTopBar from 'components/Layout/RegisterTopBar';
import { REGISTER_TOP_BAR_HEIGHT } from 'utils/constants';
import { useCreatePlan, useCreatePlanTransaction } from 'store/reducers/plans';
import { isUserVerified, redirectUser } from 'utils/helpers';
import useDelayHistory from 'hooks/useDelayHistory';
import NavPrompt from 'components/NavPrompt';

import PlanDataForm from './create/PlanDataForm';
import SelectAccountType from './create/SelectAccountType';
import Intro from './create/Intro';
import Questionnaire from './create/Questionnaire';
import ChoosePortfolio from './create/ChoosePortfolio';
import Portfolio from './create/Portfolio';
import SetInvestment from './create/SetInvestment';
import ProjectedValue from './create/ProjectedValue';
import PortfolioDetails from './create/PortfolioDetails';
import StockDetails from './create/StockDetails';
import { useFetchPortfolios } from 'store/reducers/misc';
import LinearProgress from 'components/UI/CustomLinearProgress';
import { useSetAppSafeArea } from 'hooks/useSetAppSafeArea';
import { useSetAppNotchColor } from 'hooks/useSetAppNotchColor';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.contrastText,
    width: '100vw',
  },
  headerInfo: {
    margin: '20px 0 30px',
  },
  content: {
    paddingTop: REGISTER_TOP_BAR_HEIGHT,
    flex: 1,
  },
}));

const Create = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  useSetAppSafeArea(true);
  useSetAppNotchColor('#FFFFFF');
  const history = useHistory();
  const delayHistory = useDelayHistory();
  const [disabled, setDisabled] = useState(false);
  const [done, setDone] = useState(false);
  const [transactionCreated, setTransactionCreated] = useState(false);
  const [{ loading: creatingPlan, value: valueCreatePlan, error: errorCreatePlan }, createPlan] = useCreatePlan();
  const [{ loading: fetchingPortfolios }, fetchPortfolios] = useFetchPortfolios();
  const [{ loading: creatingTransaction }, createTransaction] = useCreatePlanTransaction();
  const userData = useSelector((state) => state.user.data);
  const portfolios = useSelector((state) => state.misc.portfolios);
  const currencies = useSelector((state) => state.misc.currencies);
  const { client } = userData;
  const { answers, identifier, version } = client.suitabilityQuestionAnswers;

  let survey,
    questionnaire,
    surveyAnswers,
    recommendedPortfolio = null,
    hasQuestionnaireBeenAnswered = false;
  if (identifier && version && answers) {
    survey = getSurveyById(identifier);
    questionnaire = getQuestionnaireByVersion(version, survey);
    surveyAnswers = answers.map((a) => a.toString());
    recommendedPortfolio = calcPortfolio(answers, questionnaire);
    hasQuestionnaireBeenAnswered = true;
  }
  if (!survey || !questionnaire || !surveyAnswers) {
    survey = getSurveyByCountry(client.address.country.id);
    questionnaire = getLatestQuestionnaire(survey?.identifier, survey);
    surveyAnswers = new Array(questionnaire.questions.length).fill(null);
  }
  // Needed for caching purposes so that the steps array below isn't changed after the questionnaire has been answered for the first time.
  const [skipQuestionnaire] = useState(hasQuestionnaireBeenAnswered);

  const step = history.location.state?.step ?? 0;
  const buttonsDisabled = disabled || done || creatingPlan || creatingTransaction;
  const verifiedUser = isUserVerified(userData);
  const [title, setTitle] = useState(verifiedUser ? '' : `${t('hi')} ${userData.givenName}`);

  const [data, setData] = useState({
    surveyAnswers,
    recommendedPortfolio,
    selectedPortfolio: recommendedPortfolio,
    selectedCurrencyCode: currencies.code,
    initialInvestment: 500,
    monthlyTopUp: 100,
    accountType: 'regular',
    name: '',
    targetValue: '',
  });
  const { toggleAnimation, showTransition, delay } = useTransition();

  const steps = [
    !verifiedUser
      ? false
      : (handleNext) => <PlanDataForm handleNext={handleNext} planData={data} updatePlanData={setData} />,
    (handleNext) => (
      <SelectAccountType
        handleNext={handleNext}
        data={data}
        updateData={setData}
        skipQuestionnaire={skipQuestionnaire}
        verifiedUser={verifiedUser}
        recommendedPortfolio={recommendedPortfolio}
        hasQuestionnaireBeenAnswered={hasQuestionnaireBeenAnswered}
      />
    ),
    skipQuestionnaire ? false : (handleNext) => <Intro handleNext={handleNext} />,
    skipQuestionnaire
      ? false
      : (handleNext) => (
          <Questionnaire
            handleNext={handleNext}
            data={data}
            updateData={setData}
            surveyId={survey.identifier}
            questionnaire={questionnaire}
            setDisabled={setDisabled}
          />
        ),
    (handleNext) => <ChoosePortfolio handleNext={handleNext} data={data} updateData={setData} updateTitle={setTitle} />,
    (handleNext) => <Portfolio handleNext={handleNext} data={data} updateTitle={setTitle} />,
    (handleNext) => <SetInvestment handleNext={handleNext} data={data} updateData={setData} />,
    (handleNext) => (
      <ProjectedValue handleNext={handleNext} data={data} updateData={setData} buttonsDisabled={buttonsDisabled} />
    ),
  ].filter(Boolean);
  const { stepsValidated, validateStep, invalidateStep } = useStepValidation(steps.length);

  const handleNext = (callback = () => {}, customStep, state = {}) => {
    if (step + 1 === steps.length) {
      setDone(true);
    } else {
      toggleAnimation(() => {
        if (callback) callback();
        if (typeof step === 'number') validateStep(step);
        let nextStep = step + 1;
        if (customStep) {
          nextStep = customStep;
        }
        history.push(`/plans/create`, { ...state, step: nextStep });
        window.scrollTo(0, 0);
      });
    }
  };

  const handleBack = () => {
    if (step === 0 && verifiedUser) {
      history.goBack();
    } else {
      toggleAnimation(() => {
        if (typeof step === 'number') invalidateStep(step);
        history.goBack();
      });
    }
  };

  const renderStep = () => {
    if (step > steps.length) return null;
    switch (step) {
      case 'portfolio-details':
        return (
          <PortfolioDetails
            data={data}
            updateTitle={setTitle}
            handleNext={handleNext}
            selectPortfolio={() => handleNext(null, steps.length - 2)}
          />
        );
      case 'stock-details':
        return <StockDetails data={data} />;
      default:
        return steps[step](handleNext);
    }
  };

  useEffect(() => {
    if (typeof step === 'string' || (step > 0 && !stepsValidated[step])) {
      history.push('/plans/create');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    redirectUser(history.push, userData);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!portfolios && !fetchingPortfolios) {
      fetchPortfolios();
    }
  }, [portfolios, fetchingPortfolios, fetchPortfolios]);

  useEffect(() => {
    const createPlanAndTransaction = async (portfolioId) => {
      try {
        const newPlan = await createPlan({
          name: data.name,
          targetValue: typeof data.targetValue === 'number' ? data.targetValue : 0,
          initialSpecInvestment: data.initialInvestment,
          InitialSpecInvestmentCurrencyCode: data.selectedCurrencyCode,
          portfolioId,
        });
        if (newPlan && verifiedUser) {
          await createTransaction(newPlan.id, data.initialInvestment, data.selectedCurrencyCode);
          setTransactionCreated(true);
        }
      } catch {
        setDone(false);
      }
    };
    if (done && !creatingPlan && !valueCreatePlan && !errorCreatePlan) {
      const portfolioId = portfolios.find((p) => p.name?.toLowerCase() === data.selectedPortfolio?.toLowerCase())?.id;
      if (!portfolioId) {
        setDone(false);
        return;
      }
      createPlanAndTransaction(portfolioId);
    } else {
      setDone(false);
    }
  }, [
    done,
    creatingPlan,
    valueCreatePlan,
    errorCreatePlan,
    createPlan,
    data,
    portfolios,
    verifiedUser,
    createTransaction,
  ]);

  useEffect(() => {
    if (valueCreatePlan && !(verifiedUser && !transactionCreated)) {
      if (verifiedUser) {
        delayHistory.push('/user/plans');
      } else {
        redirectUser(delayHistory.push, userData);
      }
    } else if (errorCreatePlan) {
      console.log('error', errorCreatePlan);
    }
  }, [delayHistory, valueCreatePlan, errorCreatePlan, userData, verifiedUser, transactionCreated]);

  const [progressPercent, setProgressPercent] = useState(step / steps.length);
  useEffect(() => {
    if (typeof step === 'number') {
      setProgressPercent((step + 1) / steps.length);
    }
  }, [step, steps]);

  if (!portfolios) return <LinearProgress />;
  if (portfolios?.length === 0) return t('error-messages.no-portfolios');

  return (
    <Grid container className={classes.root} direction="column">
      <NavPrompt
        when={(crntLocation, nextLocation) =>
          !(verifiedUser && transactionCreated) &&
          step !== 0 &&
          !nextLocation.pathname.startsWith('/register/review') &&
          (!nextLocation || !nextLocation.pathname.startsWith(crntLocation.pathname))
        }
      />
      <RegisterTopBar
        handleBack={handleBack}
        disabled={buttonsDisabled}
        title={title}
        hideBackBtn={step === 0 && !verifiedUser}
        progressValue={{ min: 20, max: 40, percent: progressPercent }}
        hideProgressBar={verifiedUser}
      />
      <Grid item className={classes.content}>
        <Transition show={showTransition} delay={delay}>
          {renderStep()}
        </Transition>
      </Grid>
    </Grid>
  );
};

export default Create;
