import React from 'react';
import PropTypes from 'prop-types';
import { Button, Fab, IconButton } from '@material-ui/core';
import DelayLink from './DelayLink';

const propTypes = {
  // Milliseconds to wait before registering the click.
  delay: PropTypes.number,

  // Called after the link is clicked and before the delay timer starts.
  onDelayStart: PropTypes.func,

  // Called after the delay timer ends.
  onDelayEnd: PropTypes.func,

  // Link to path
  to: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object]).isRequired,

  transition: PropTypes.string,

  linkClassName: PropTypes.string,

  children: PropTypes.node.isRequired,
};

const defaultProps = {
  delay: 200,
  transition: 'no',
  linkClassName: '',
  onDelayStart: () => {},
  onDelayEnd: () => {},
};

export const DelayLinkButton = ({
  to,
  delay,
  transition,
  linkClassName,
  onDelayStart,
  onDelayEnd,
  children,
  ...rest
}) => {
  return (
    <DelayLink
      to={to}
      delay={delay}
      onDelayEnd={onDelayEnd}
      onDelayStart={onDelayStart}
      transition={transition}
      linkClassName={linkClassName}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Button {...rest}>{children}</Button>
    </DelayLink>
  );
};

DelayLinkButton.propTypes = propTypes;
DelayLinkButton.defaultProps = defaultProps;

export const DelayLinkIconButton = ({
  to,
  delay,
  transition,
  linkClassName,
  onDelayStart,
  onDelayEnd,
  children,
  ...rest
}) => {
  return (
    <DelayLink
      to={to}
      delay={delay}
      onDelayEnd={onDelayEnd}
      onDelayStart={onDelayStart}
      transition={transition}
      linkClassName={linkClassName}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <IconButton {...rest}>{children}</IconButton>
    </DelayLink>
  );
};

DelayLinkIconButton.propTypes = propTypes;
DelayLinkIconButton.defaultProps = defaultProps;

export const DelayLinkFab = ({ to, delay, transition, linkClassName, onDelayStart, onDelayEnd, children, ...rest }) => {
  return (
    <DelayLink
      to={to}
      delay={delay}
      onDelayEnd={onDelayEnd}
      onDelayStart={onDelayStart}
      transition={transition}
      linkClassName={linkClassName}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Fab {...rest}>{children}</Fab>
    </DelayLink>
  );
};

DelayLinkFab.propTypes = propTypes;
DelayLinkFab.defaultProps = defaultProps;
