export default {
  root: {
    color: 'black',
  },
  colorSecondary: {
    color: 'white',
    '& input': {
      color: 'white',
    },
  },
  underline: {
    '&:before': {
      //borderColor: 'white !important',
    },
  },
};
