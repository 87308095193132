import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { InputAdornment, TextField } from '@material-ui/core';

import LoaderWrapper from 'components/LoaderWrapper';
import { useUpdatePlan } from 'store/reducers/plans';
import Grow from '@material-ui/core/Grow';

const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Grow ref={ref} {...props} timeout={{ enter: 500, exit: 250 }} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 'unset',
    maxWidth: 500,
    width: '100%',
    margin: 10,
    borderRadius: 4,
  },
  title: {
    ...theme.typography.h5,
    fontWeight: 500,
    textAlign: 'center',
  },
  cancelButton: {
    borderRadius: 0,
    backgroundColor: '#DDD',
    '&:hover': {
      backgroundColor: '#AAA',
    },
  },
  confirmButtonWrapper: {
    margin: '0px !important',
  },
  confirmButton: {
    borderRadius: 0,
  },
  confirmButtonDisabled: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  actions: {
    padding: 0,
  },
}));

const PlanGoalDialog = ({ open, handleClose, handleUpdateCurrentPlan, plan }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [{ loading }, updatePlan] = useUpdatePlan();

  const schema = useMemo(
    () =>
      yup.object().shape({
        targetValue: yup
          .number()
          .typeError(t('form-validations.required'))
          .moreThan(-1, t('form-validations.greaterThanZero'))
          .required(t('form-validations.required')),
      }),
    [t]
  );
  const { register, handleSubmit, errors, formState } = useForm({
    defaultValues: {
      targetValue: plan.targetValue,
    },
    resolver: yupResolver(schema),
  });
  const { isSubmitting } = formState;

  const onSubmit = async (data) => {
    const updatedPlan = await updatePlan(plan.id, data);
    if (updatedPlan) {
      if (handleUpdateCurrentPlan) handleUpdateCurrentPlan(updatedPlan);
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.root,
      }}
      disableEscapeKeyDown={isSubmitting || loading}
      disableBackdropClick={isSubmitting || loading}
      PaperProps={{
        component: 'form',
      }}
      onSubmit={handleSubmit(onSubmit)}
      TransitionComponent={Transition}
    >
      <DialogTitle disableTypography>
        <Typography className={classes.title}>
          {plan.targetValue > 0 ? t('editTargetAmount') : t('setTargetAmount')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <Grid container spacing={2} justify="center">
            <Grid item xs={11}>
              <TextField
                name="targetValue"
                label={`${t('targetAmount')} *`}
                inputRef={register}
                type="number"
                error={Boolean(errors.targetValue)}
                helperText={errors.targetValue?.message}
                disabled={isSubmitting}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" disablePointerEvents disableTypography>
                      $
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          color="secondary"
          onClick={handleClose}
          className={classes.cancelButton}
          disabled={isSubmitting || loading}
        >
          {t('cancel')}
        </Button>
        <LoaderWrapper loading={isSubmitting || loading} color="secondary" className={classes.confirmButtonWrapper}>
          <Button
            className={classes.confirmButton}
            type="submit"
            disabled={isSubmitting || loading}
            classes={{
              disabled: classes.confirmButtonDisabled,
            }}
          >
            {t('confirm')}
          </Button>
        </LoaderWrapper>
      </DialogActions>
    </Dialog>
  );
};

PlanGoalDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleUpdateCurrentPlan: PropTypes.func,
  plan: PropTypes.object.isRequired,
};

export default PlanGoalDialog;
