import axios from 'axios';

import { apiEndpoints } from './config';

const endpoint = apiEndpoints.currencies;

export async function fetchCurrenciesAsync() {
  try {
    const res = await axios.get(`${endpoint}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
