import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Typography, makeStyles, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    padding: '30px 12px 20px',
    width: '100%',
    margin: 0,
  },
}));

const BankDetails = ({ data, updateData, handleNext }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const schema = useMemo(
    () =>
      yup.object().shape({
        accountHolderName: yup
          .string()
          .required(t('form-validations.required'))
          .matches(/^[a-zA-Z_ ]*$/, t('form-validations.onlyLetters')),
        bankName: yup.string().required(t('form-validations.required')),
        accountNumber: yup.string().required(t('form-validations.required')),
        swiftCode: yup.string(),
      }),
    [t]
  );

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      accountHolderName: data.accountHolderName,
      bankName: data.bankName,
      accountNumber: data.accountNumber,
      swiftCode: data.swiftCode,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData) => {
    updateData({
      ...data,
      ...formData,
    });
    handleNext();
  };
  return (
    <Grid
      container
      component="form"
      direction="column"
      justify="space-between"
      onSubmit={handleSubmit(onSubmit)}
      className={classes.root}
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant="h3">{t('register-transfer.bank-details.title')}</Typography>
        <Typography>{t('register-transfer.bank-details.body')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="accountHolderName"
          label={t('accountHolderName')}
          required
          inputRef={register}
          type="text"
          error={Boolean(errors.accountHolderName)}
          helperText={errors.accountHolderName?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="bankName"
          label={t('bankName')}
          required
          inputRef={register}
          type="text"
          error={Boolean(errors.bankName)}
          helperText={errors.bankName?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="accountNumber"
          label={t('ibanOrAccountNumber')}
          required
          inputRef={register}
          type="text"
          error={Boolean(errors.accountNumber)}
          helperText={errors.accountNumber?.message}
        />
      </Grid>
      <Grid item xs={12}>
        {t('register-transfer.bank-details.accountNumberNotice')}
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="swiftCode"
          label={t('swiftCode')}
          inputRef={register}
          type="text"
          error={Boolean(errors.swiftCode)}
          helperText={errors.swiftCode?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <Button type="submit">{t('next')}</Button>
      </Grid>
    </Grid>
  );
};

BankDetails.propTypes = {
  handleNext: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default BankDetails;
