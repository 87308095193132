import React from 'react';
import { List, ListItem, ListItemSecondaryAction, ListItemIcon, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import { ReactComponent as WithdrawIcon } from 'assets/icons/WithdrawIcon.svg';
import { ReactComponent as TopupIcon } from 'assets/icons/TopupIcon.svg';
import TextCard from '../../components/Cards/TextCard';
import DelayLink from '../../components/DelayLink';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
    backgroundColor: theme.palette.common.white,
  },
  listItem: {
    height: 80,
  },
  icon: {
    '& path': {
      fill: '#8e8e93',
      stroke: '#8e8e93',
    },
  },
}));

const Transfer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <TextCard text={t('user.transfer.index.title')} />
      <List className={classes.list}>
        <ListItem
          button
          divider
          className={classes.listItem}
          component={DelayLink}
          to="/user/transfer/top-up"
          transition="slide-right"
        >
          <ListItemIcon>
            <TopupIcon />
          </ListItemIcon>
          {t('user.transfer.index.top-up')}
          <ListItemSecondaryAction>
            <ArrowRightIcon className={classes.icon} />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          button
          divider
          className={classes.listItem}
          component={DelayLink}
          to="/user/transfer/withdraw"
          transition="slide-right"
        >
          <ListItemIcon>
            <WithdrawIcon />
          </ListItemIcon>
          {t('user.transfer.index.withdraw')}
          <ListItemSecondaryAction>
            <ArrowRightIcon className={classes.icon} />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
};

export default Transfer;
