import React, { useState, useEffect, useCallback } from 'react';
import { VictoryPie } from 'victory-pie';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

const onloadAnimationTime = 500;
const delayBeforeLoading = 250;

const DonutChart = ({ data, selectedIndex, setSelectedIndex }) => {
  const {
    palette: { accent },
  } = useTheme();
  const colorScheme = [accent.melanie, accent.gainsboro, accent.regentBlue, accent.pinkLady, accent.gainsboro];
  const [externalMutations, setExternalMutations] = useState();
  const [angle, setAngle] = useState(0);
  const [chartStyle, setChartStyle] = useState({
    labels: {
      display: 'none',
    },
  });
  const [hasChartLoaded, setHasChartLoaded] = useState(false);

  const updateSelectedPie = useCallback(
    (id) => {
      setExternalMutations([
        {
          target: ['data'],
          eventKey: 'all',
          mutation: ({ index }) => (index === id ? { innerRadius: 67 } : { innerRadius: 80 }),
          callback: () => setExternalMutations(undefined),
        },
      ]);
    },
    [setExternalMutations]
  );

  useEffect(() => {
    updateSelectedPie(selectedIndex);
    setTimeout(() => {
      setAngle(360);
      setTimeout(() => {
        setChartStyle((prevStyle) => ({
          ...prevStyle,
          data: {
            transition: 'd 0.25s cubic-bezier(0.4, 0, 1, 1)',
          },
        }));
        setHasChartLoaded(true);
      }, onloadAnimationTime * 1.5);
    }, delayBeforeLoading);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedIndex === undefined || !hasChartLoaded) return;
    updateSelectedPie(selectedIndex);
  }, [selectedIndex, updateSelectedPie, hasChartLoaded]);

  return (
    <svg height={200} width={200}>
      <VictoryPie
        colorScale={colorScheme}
        standalone={false}
        height={200}
        width={200}
        externalEventMutations={externalMutations}
        innerRadius={80}
        radius={100}
        endAngle={angle}
        animate={{
          onLoad: {
            duration: onloadAnimationTime,
          },
        }}
        style={chartStyle}
        events={[
          {
            target: 'data',
            eventHandlers: {
              onClick: () => {
                return [
                  {
                    target: 'data',
                    mutation: ({ index }) => {
                      setSelectedIndex((prev) => (index === prev ? null : index));
                    },
                  },
                ];
              },
            },
          },
        ]}
        data={data}
      />
    </svg>
  );
};

DonutChart.propTypes = {
  data: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func.isRequired,
};

DonutChart.defaultProps = {
  selectedIndex: undefined,
};

export default DonutChart;
