import { getI18n } from 'react-i18next';
import { APP_COOKIES } from 'utils/constants';

export function formatPrice(value, currency, lng = getI18n().language || 'en') {
  const formatter = new Intl.NumberFormat(lng, {
    style: 'currency',
    currency: currency ?? 'USD',
    minimumFractionDigits: 0,
  });
  return formatter.format(typeof value === 'number' ? value : typeof value === 'string' ? parseInt(value, 10) : 0);
}
export function getCurrencySymbol(currency, lng = getI18n().language || 'en') {
  return new Intl.NumberFormat(lng, {
    style: 'currency',
    currency: currency ?? 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(0)
    .replace(/\d/g, '')
    .trim();
}

export function isiOSDevice() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function formatISODateString(dateStr) {
  if (!dateStr || dateStr === '') return '';
  return new Date(dateStr).toLocaleString(undefined, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
}

export const hasUserCompletedOnboarding = (userData) =>
  Boolean(
    userData.client.address.line1 !== null && userData.roles.includes('VerifiedClient') && userData.client.bankAccount
  );

export const hasUserCompletedInitialSetup = (userData, plans) =>
  (userData.roles.includes('VerifiedClient') && plans.length === 0) ||
  (plans.length > 0 && !plans.some((p) => p.name === ''));

export function getRedirectUserPath(userData, options = { currentPath: window.location.pathname, plans: [] }) {
  const currentPath = options.currentPath ?? window.location.pathname;
  const plans = options.plans ?? [];
  if (userData.client.plansCount === false) return false;

  if (currentPath.startsWith('/confirm-email') || currentPath.startsWith('/password-reset')) {
    return false;
  }

  // Only show this in production to make testing in development be a bit quicker.
  if (!userData.emailConfirmed && !userData.roles.includes('VerifiedClient') && process.env.NODE_ENV === 'production') {
    return currentPath !== '/register/confirm-email' ? '/register/confirm-email' : false;
  }

  if (userData.client.plansCount === 0 && !userData.roles.includes('VerifiedClient')) {
    return currentPath !== '/plans/create' ? '/plans/create' : false;
  }
  if (userData.client.address.line1 === null) {
    return currentPath !== '/register/review' ? '/register/review' : false;
  }
  const { documentReportUploaded, facialSimilarityPhotoUploaded } = userData.client.onfidoVerification ?? {};
  if (!(documentReportUploaded && facialSimilarityPhotoUploaded)) {
    return currentPath !== '/identity-verification' ? '/identity-verification' : false;
  }
  if (!userData.roles.includes('VerifiedClient')) {
    return currentPath !== '/register/final' ? '/register/final' : false;
  }
  if (!userData.client.bankAccount) {
    return currentPath !== '/register/transfer' ? '/register/transfer' : false;
  }

  if (!hasUserCompletedInitialSetup(userData, plans)) {
    return currentPath !== '/user/initial-setup' ? '/user/initial-setup' : false;
  }

  if (['/plans/create'].includes(currentPath) || currentPath.startsWith('/user')) {
    return false;
  }

  // Redirect to dashboard
  return currentPath !== '/user/plans' ? '/user/plans' : false;
}

export function redirectUser(pushFunc, userData, options = { currentPath: window.location.pathname, plans: [] }) {
  const redirectUserPath = getRedirectUserPath(userData, options);
  console.log('redirectUser', userData, options.currentPath, redirectUserPath, options);
  if (redirectUserPath) {
    pushFunc(redirectUserPath);
    return true;
  }
  return false;
}

export function isUserVerified(userData) {
  return userData.roles.includes('VerifiedClient');
}

export function shadeColor(color, percent) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100, 10);
  G = parseInt((G * (100 + percent)) / 100, 10);
  B = parseInt((B * (100 + percent)) / 100, 10);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
}

// Returns true if the current session is in an app web view. Requires the channel to not be changed in the app wrapper
export function isAppWebView() {
  return (
    typeof window.flutter_inappwebview === 'object' &&
    'callHandler' in window.flutter_inappwebview &&
    window.flutter_inappwebview.callHandler instanceof Function
  );
}

// Closes the app wrapper
export function closeAppWrapper() {
  if (isAppWebView()) {
    window.flutter_inappwebview.callHandler('close_app');
  }
}

export async function requestAppPermissions(permissions) {
  if (isAppWebView()) {
    return await window.flutter_inappwebview.callHandler('request_permissions', permissions);
  }
  return false;
}

export function logAnalyticsSignup() {
  if (isAppWebView()) {
    return window.flutter_inappwebview.callHandler('log_google_analytics_event', 'signup', null);
  }
  return true;
}

export function logAnalyticsOnfidoCompleted() {
  if (isAppWebView()) {
    return window.flutter_inappwebview.callHandler('log_google_analytics_event', 'onfido_completed', null);
  }
  return true;
}

export async function checkAppPermissions(permissions) {
  if (isAppWebView()) {
    return await window.flutter_inappwebview.callHandler('check_permissions', permissions);
  }
  return true; // No need to verify permissions if it's not in the app
}
export function cookieExists(name) {
  return document.cookie.indexOf(name) >= 0;
}

export function addCookie(name, value, expires = 0) {
  document.cookie = `${name}=${value}; expires=${expires}; path=/`;
}

export function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export function removeCookie(name) {
  document.cookie = `${name}=X; Max-Age=0`;
}

export function setAppWrapperCookies(input = {}) {
  // Updates value of cookies in the browser/webview.
  Object.entries(input).forEach(([name, value]) => {
    addCookie(name, value);
  });
  if (isAppWebView()) {
    const cookies = {
      ...{
        [APP_COOKIES.EMAIL]: getCookie(APP_COOKIES.EMAIL) ?? '',
        [APP_COOKIES.HIDE_SANDBOX_DISCLAIMER]: getCookie(APP_COOKIES.HIDE_SANDBOX_DISCLAIMER) ?? false,
      },
      ...input,
    };
    window.flutter_inappwebview.callHandler(
      'set_cookies',
      Object.entries(cookies)
        .map(([key, value]) => `${key}=${value}`)
        .join('; ')
    );
  }
}
