import axios from 'axios';

import { apiEndpoints } from '../config';

const endpoint = apiEndpoints.user.currencies;

export async function fetchDetailedCurrenciesAsync() {
  try {
    const res = await axios.get(`${endpoint}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
