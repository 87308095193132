import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import NavPrompt from 'components/NavPrompt';

import { redirectUser } from 'utils/helpers';
import Transition from 'components/Transition';
import useTransition from 'hooks/useTransition';
import { useStepValidation } from 'hooks/useStepValidation';
import RegisterTopBar from 'components/Layout/RegisterTopBar';

import DocumentsCheck from './final/DocumentsCheck';
import Finished from './final/Finished';
import { useSetAppSafeArea } from 'hooks/useSetAppSafeArea';
import { useSetAppNotchColor } from 'hooks/useSetAppNotchColor';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.contrastText,
    width: '100vw',
  },
  headerInfo: {
    margin: '20px 0 30px',
  },
  content: {
    paddingTop: 64,
    height: '100vh',
  },
}));

const Final = () => {
  const classes = useStyles();
  const { view } = useParams();
  const history = useHistory();
  useSetAppSafeArea(true);
  useSetAppNotchColor('#FFFFFF');
  const { toggleAnimation, showTransition, delay } = useTransition();
  const [title, setTitle] = useState('');
  const step = history.location.state?.appStep ?? 0;
  const onfidoStep = history.location.state?.step;
  const userData = useSelector((state) => state.user.data);
  const { onfidoVerification } = userData.client;
  const countryOfResidence = userData.client.address.country.isO3;

  const documents = [
    {
      category: 'identity',
      type: 'face',
      uploaded: Boolean(onfidoVerification?.facialSimilarityPhotoUploaded),
    },
    {
      category: 'identity',
      type: 'document',
      uploaded: Boolean(onfidoVerification?.documentReportUploaded),
    },
    {
      category: 'address',
      type: 'poa',
      uploaded: Boolean(onfidoVerification?.proofOfAddressUploaded),
    },
  ];

  const steps = [
    (handleNext) => (
      <DocumentsCheck
        handleNext={handleNext}
        updateTitle={setTitle}
        step={step}
        country={countryOfResidence}
        documents={documents}
      />
    ),
    () => <Finished />,
  ];
  const onfidoStepIndex = 0;
  const { stepsValidated, validateStep, invalidateStep } = useStepValidation(steps.length);

  const handleNext = (callback = () => {}, customStep, view) => {
    if (!customStep && step === steps.length) {
      console.log('finalize account');
    } else {
      toggleAnimation(() => {
        if (typeof callback === 'function') callback();
        validateStep(step);
        const nextStep = typeof customStep === 'number' ? customStep : step + 1;
        history.push(`/register/final${view || ''}`, {
          appStep: nextStep,
        });
        window.scrollTo(0, 0);
      });
    }
  };

  const handleBack = () => {
    if (view === 'onfido') {
      history.goBack();
    } else if (step === 0 && !onfidoStep) {
      // history.goBack();
    } else {
      toggleAnimation(() => {
        invalidateStep(step);
        history.goBack();
      });
    }
  };

  const renderStep = () => {
    if (view === 'onfido')
      return (
        <DocumentsCheck
          handleNext={handleNext}
          updateTitle={setTitle}
          step={step}
          country={countryOfResidence}
          documents={documents}
        />
      );
    return steps[step](handleNext);
  };

  const [progressPercent, setProgressPercent] = useState(step / steps.length);
  useEffect(() => {
    if (typeof step === 'number') {
      setProgressPercent((step + 1) / steps.length);
    }
  }, [step, steps]);

  useEffect(() => {
    if (
      step === 0 &&
      Boolean(
        onfidoVerification?.facialSimilarityPhotoUploaded &&
          onfidoVerification?.documentReportUploaded &&
          onfidoVerification?.proofOfAddressUploaded
      )
    ) {
      validateStep(0);
      history.push('/register/final', {
        appStep: 1,
      });
    }
  }, [validateStep, step, history, onfidoVerification]);

  useEffect(() => {
    if ((view === 'onfido' && !stepsValidated[onfidoStepIndex]) || (step > 0 && !stepsValidated[step])) {
      history.push('/register/final');
    } else {
      redirectUser(history.push, userData);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container className={classes.root} direction="column">
      <NavPrompt
        when={(crntLocation, nextLocation) =>
          view === 'onfido' &&
          !nextLocation.pathname.startsWith('/register/transfer') &&
          (!nextLocation || !nextLocation.pathname.startsWith('/register/final'))
        }
      />
      <RegisterTopBar
        title={title}
        handleBack={handleBack}
        hideBackBtn={view !== 'onfido' && (step === 0 || step + 1 === steps.length)}
        progressValue={{ min: 75, max: 85, percent: progressPercent }}
      />
      <Grid item className={classes.content}>
        <Transition show={showTransition} delay={delay}>
          {renderStep()}
        </Transition>
      </Grid>
    </Grid>
  );
};

export default Final;
