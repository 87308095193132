import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import useAsyncFn from 'hooks/useAsyncFn';
import {
  createPlanAsync,
  endPlanAsync,
  fetchPlansAsync,
  sellPlanSharesAsync,
  updatePlanAsync,
} from 'transfer/user/plansApi';
import { updatePlansCount } from './user';
import { createTransactionAsync } from 'transfer/user/transactionApi';

const initialState = {
  fetched: false,
  plans: [],
};

const planSlice = createSlice({
  name: 'plans',
  initialState: { ...initialState },
  reducers: {
    addPlans(state, { payload }) {
      state.fetched = true;
      state.plans = [...payload, ...state.plans];
    },
    addPlan(state, { payload }) {
      state.plans = [payload, ...state.plans];
    },
    updatePlan(state, { payload }) {
      const planIndex = state.plans.findIndex((plan) => plan.id === payload.id);
      if (planIndex > -1) {
        state.plans[planIndex] = payload;
      }
    },
    clearPlans() {
      return {
        ...initialState,
      };
    },
  },
});

export const { addPlans, addPlan, updatePlan, clearPlans } = planSlice.actions;

export default planSlice.reducer;

export function useFetchPlans() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const plans = await fetchPlansAsync();
    if (plans) {
      dispatch(addPlans(plans));
      dispatch(updatePlansCount(plans.length));
    }
    return plans;
  }, [dispatch]);
}

export function useCreatePlan() {
  const dispatch = useDispatch();
  const { plans } = useSelector((state) => state.plans);
  return useAsyncFn(
    async (data) => {
      const plan = await createPlanAsync(data);
      if (plan) {
        dispatch(addPlan(plan));
        dispatch(updatePlansCount(plans.length + 1));
      }
      return plan;
    },
    [dispatch, plans]
  );
}

export function useUpdatePlan() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (id, data) => {
      const plan = await updatePlanAsync(id, data);
      if (plan) {
        dispatch(updatePlan(plan));
      }
      return plan;
    },
    [dispatch]
  );
}

export function useCreatePlanTransaction() {
  return useAsyncFn(async (planId, amount, currencyCode) => {
    return await createTransactionAsync({
      planId,
      amount,
      currencyCode,
    });
  });
}

export function useSellPlanShares() {
  return useAsyncFn(async (planId, amount, password) => {
    return await sellPlanSharesAsync(planId, amount, password);
  });
}

export function useEndPlan() {
  return useAsyncFn(async (planId, password) => {
    return await endPlanAsync(planId, password);
  });
}
