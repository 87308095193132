import React, { useEffect, useMemo, useState } from 'react';
import { Fab, Grid, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import ProgressFab from 'components/ProgressFab';
import { ReactComponent as PointerForwardIcon } from 'assets/icons/PointerForward.svg';
import { USER_TOP_BAR_HEIGHT } from 'utils/constants';
import { useUpdatePlan } from 'store/reducers/plans';
import { hasUserCompletedInitialSetup, redirectUser } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    flexGrow: 1,
    padding: '12vh 20px 50px',
    backgroundColor: theme.palette.accent.pinkLady,
    minHeight: `calc(100vh - ${USER_TOP_BAR_HEIGHT}px - 80px)`,
    marginBottom: -60,
    '& > div:first-child': {
      width: '100%',
    },
  },
  footer: {
    padding: 20,
    boxShadow: theme.shadows[4],
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    top: 60,
    // position: 'sticky',
    // marginBottom: -60,
    // bottom: 0,
  },
}));

const InitialSetup = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [{ loading }, updatePlan] = useUpdatePlan();

  const { fetched: plansFetched, plans } = useSelector((state) => state.plans);
  const { fetched: userFetched, data: userData } = useSelector((state) => state.user);

  useEffect(() => {
    if (userFetched && plansFetched && hasUserCompletedInitialSetup(userData, plans)) {
      history.push('/user/plans');
    }
  }, [userData, history, plans, plansFetched, userFetched]);
  const [submitting, setSubmitting] = useState(false);
  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string(),
        targetValue: yup
          .number()
          .typeError(t('form-validations.required'))
          .moreThan(-1, t('form-validations.greaterThanZero')),
      }),
    [t]
  );
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      name: '',
      targetValue: 0,
    },
    resolver: yupResolver(schema),
  });

  if (!plansFetched) return null;

  const plan = plans.length > 0 && plans.find((p) => p.name === '' && p.targetValue === 0);

  if (!plan) {
    redirectUser(history.push, userData);
  }

  const onSubmit = async (data) => {
    setSubmitting(true);
    if (
      await updatePlan(plan.id, {
        name: data.name === '' ? t('default-values.first-plan-name') : data.name,
        targetValue: data.targetValue,
      })
    ) {
      redirectUser(history.push, userData);
    }
    setSubmitting(false);
  };

  return (
    <Grid container component="form" direction="column" onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid item className={classes.content} container>
        <div>
          <Grid container spacing={2} justify="center">
            <Grid item xs={11}>
              <Typography variant="h3" className={classes.title} gutterBottom>
                {t('user.initial-setup.title')}
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Typography variant="body1">{t('user.initial-setup.body')}</Typography>
            </Grid>
            <Grid item xs={11}>
              <TextField
                name="name"
                label={t('name')}
                inputRef={register}
                type="text"
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            </Grid>
            <Grid item xs={11}>
              <TextField
                name="targetValue"
                label={t('targetGoal')}
                inputRef={register}
                type="number"
                error={Boolean(errors.targetValue)}
                helperText={errors.targetValue?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" disablePointerEvents disableTypography>
                      $
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item container alignItems="flex-end" direction="column" className={classes.footer}>
        <ProgressFab loading={submitting || loading}>
          <Fab color="primary" type="submit" size="small" disabled={submitting || loading}>
            <PointerForwardIcon />
          </Fab>
        </ProgressFab>
      </Grid>
    </Grid>
  );
};

export default InitialSetup;
