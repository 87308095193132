import React, { useEffect, useState } from 'react';
import { Grid, Button, CircularProgress, Typography, makeStyles, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';

import { ReactComponent as CocoaLogo } from 'assets/icons/CocoaLogo.svg';
import { ReactComponent as PointerBackIcon } from 'assets/icons/PointerBack.svg';
import useDelayHistory from 'hooks/useDelayHistory';
import useAsyncFn from 'hooks/useAsyncFn';
import { confirmEmailAsync, resendEmailConfirmationAsync } from 'transfer/accountApi';
import LoaderWrapper from 'components/LoaderWrapper';
import { useSetAppSafeArea } from 'hooks/useSetAppSafeArea';
import { redirectUser } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    width: '100%',
    background: 'url("/assets/images/home-image.png") no-repeat, linear-gradient(-1deg, #FFFFFF, #298070)',
    backgroundSize: 'cover',
    backgroundBlendMode: 'multiply',
    minHeight: '100vh',
  },
  container: {
    display: 'flex',
    minHeight: '100vh',
    padding: 20,
  },
  logo: {
    height: 100,
    marginBottom: 50,
  },
  underline: {
    '&:before, &:after': {
      borderColor: 'white !important',
    },
  },
  requirements: {
    padding: '10px 18px',
    borderRadius: 3,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
  requirementsTitle: {
    marginBottom: 15,
  },
  helperText: {
    padding: '10px 18px',
    borderRadius: 3,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
  button: {
    marginTop: 15,
  },
  homeIconBtn: {
    position: 'absolute',
    top: 20,
    left: 20,
    '& svg path': {
      fill: 'white',
    },
    [theme.breakpoints.down('md')]: {
      top: 24,
      left: 8,
    },
  },
}));

const ConfirmEmail = () => {
  const classes = useStyles();
  const delayHistory = useDelayHistory();
  useSetAppSafeArea(false);
  const { userId } = useParams();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [emailAlreadyConfirmed, setEmailAlreadyConfirmed] = useState(false);
  const isWebViewApp = useSelector((state) => state.misc.isWebViewApp);
  const { t } = useTranslation();
  const authenticated = useSelector((state) => state.user.authenticated);
  const userData = useSelector((state) => state.user.data);
  const [{ loading: confirmingEmail }, confirmEmail] = useAsyncFn(confirmEmailAsync);
  const [{ loading: resendingEmail, value: responseResendingEmail }, resendEmailConfirmation] = useAsyncFn(
    resendEmailConfirmationAsync
  );
  const [resendDisabledTimeLeft, setResendDisabledTimeLeft] = useState(0);

  const handleResendingEmailConfirmation = async () => {
    setError(false);
    try {
      await resendEmailConfirmation(userId);
      setResendDisabledTimeLeft(30);
    } catch (error) {
      // Email has already been confirmed so we show a different message.
      if (error.response?.data?.type === 'invalid_operation') {
        setEmailAlreadyConfirmed(true);
      }
    }
  };

  useEffect(() => {
    if (resendDisabledTimeLeft > 0) {
      setTimeout(() => {
        setResendDisabledTimeLeft((prev) => prev - 1);
      }, 1000);
    }
  }, [resendDisabledTimeLeft]);

  useEffect(() => {
    const [, token] = document.URL.split('?token=');
    if (!token || token.length === 0) {
      delayHistory.push('/');
    } else {
      confirmEmail(userId, encodeURIComponent(decodeURIComponent(token)))
        .then((emailConfirmed) => {
          if (emailConfirmed) {
            setError(false);
            setSuccess(true);
          } else {
            setError(true);
          }
        })
        .catch(() => {
          setError(true);
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container className={classes.root} justify="center" alignItems="center">
      <Container
        component={Grid}
        maxWidth="sm"
        className={classes.container}
        container
        direction="column"
        justify="space-between"
        alignItems="center"
      >
        <IconButton component={Link} to="/" className={classes.homeIconBtn}>
          <PointerBackIcon />
        </IconButton>
        <Grid item>
          <CocoaLogo className={classes.logo} />
        </Grid>
        <Grid item container style={{ flexGrow: 1 }} alignItems="center" justify="center">
          {confirmingEmail ? (
            <Grid item>
              <CircularProgress color="secondary" />
            </Grid>
          ) : (
            <Grid container spacing={2} justify="center">
              {error || resendingEmail ? (
                <>
                  <Typography color="secondary" align="center" gutterBottom>
                    {t('error-messages.confirmEmail')}
                  </Typography>
                  {resendDisabledTimeLeft > 0 && (
                    <Typography color="secondary" align="center">
                      {t('register-confirm-email.resendTimeoutText', {
                        secondsLeft: resendDisabledTimeLeft,
                      })}
                    </Typography>
                  )}
                  <LoaderWrapper loading={resendingEmail} color="secondary">
                    <Button
                      className={classes.button}
                      disabled={resendingEmail || resendDisabledTimeLeft > 0}
                      onClick={handleResendingEmailConfirmation}
                    >
                      {t('resendEmailConfirm')}
                    </Button>
                  </LoaderWrapper>
                </>
              ) : responseResendingEmail ? (
                <Typography color="secondary" align="center">
                  {t('notice-messages.confirmEmail')}
                </Typography>
              ) : (
                (success || emailAlreadyConfirmed) && (
                  <>
                    <Typography color="secondary" align="center">
                      {isWebViewApp
                        ? emailAlreadyConfirmed
                          ? t('success-messages.emailAlreadyConfirmedApp')
                          : t('success-messages.confirmEmailApp')
                        : emailAlreadyConfirmed
                        ? t('success-messages.emailAlreadyConfirmed')
                        : t('success-messages.confirmEmail')}
                    </Typography>
                    {isWebViewApp &&
                      (authenticated ? (
                        <Button
                          className={classes.button}
                          onClick={() => redirectUser(delayHistory.push, userData, { currentPath: '/' })}
                        >
                          {t('get-back-to-the-app')}
                        </Button>
                      ) : (
                        <Button className={classes.button} href="/">
                          {t('login-now')}
                        </Button>
                      ))}
                  </>
                )
              )}
            </Grid>
          )}
        </Grid>
      </Container>
    </Grid>
  );
};

export default ConfirmEmail;
