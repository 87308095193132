import { makeStyles } from '@material-ui/core/styles';
import transitionStyles from './transitionStyles';

export default (theme) =>
  makeStyles(() => ({
    main: {
      backgroundColor: 'white',
      minHeight: '100vh',
      fontSize: 14,
    },
    mainUser: {
      backgroundColor: '#F3F4F6',
      color: theme.palette.secondary.contrastText,
      fontSize: 14,
      '& .root-transition-element': {
        paddingBottom: 60,
        minHeight: 'calc(100vh - 40px)',
      },
    },
    toolbar: {
      ...theme.mixins.toolbar,
    },
    // Global styles below
    '@global': {
      ...transitionStyles,
      body: {
        backgroundColor: '#FFFFFF',
        position: 'relative',
        height: '100vh',
        overflowX: 'hidden',
      },
      a: {
        textDecoration: 'none',
        textDecorationColor: 'none',
        color: 'inherit',
        '&: -webkit-any-link': {
          color: 'black',
        },
      },

      // Onfido style overrides below
      '.onfido-sdk-ui-NavigationBar-back': {
        display: 'none',
      },
      '.onfido-sdk-ui-Button-button-primary ': {
        backgroundColor: theme.palette.primary.main,
        '& :active': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
      '.onfido-sdk-ui-Error-container-warning': {
        backgroundColor: theme.palette.primary.main,
      },
      '.onfido-sdk-ui-Error-warningTriangle': {
        borderColor: theme.palette.primary.main,
      },
      '.onfido-sdk-ui-Button-button-primary:not(:disabled):active, .onfido-sdk-ui-Button-button-primary:not(:disabled).onfido-sdk-ui-Button-hoverDesktop:active, .onfido-sdk-ui-Button-button-primary.onfido-sdk-ui-Button-hoverDesktop:not(:disabled):hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '.onfido-sdk-ui-Theme-link': {
        borderBottomColor: theme.palette.primary.main,
      },
      '.onfido-sdk-ui-Theme-link:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '.onfido-sdk-ui-DocumentSelector-option:active, .onfido-sdk-ui-DocumentSelector-option.onfido-sdk-ui-DocumentSelector-optionHoverDesktop:hover': {
        boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
      },
      '.onfido-sdk-ui-Button-button-secondary': {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '.onfido-sdk-ui-Button-button-secondary:active, .onfido-sdk-ui-Button-button-secondary.onfido-sdk-ui-Button-hoverDesktop:active': {
        backgroundColor: 'rgba(41, 128, 112, 0.35)',
      },
      '@media (max-width: 479px)': {
        '.onfido-sdk-ui-Uploader-identityIcon': {
          backgroundImage:
            'url("data:image/svg+xml,%3Csvg width%3D%2256%22 height%3D%2246%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath d%3D%22M16 24c0-6.617 5.383-12 12-12s12 5.383 12 12-5.383 12-12 12-12-5.383-12-12zM36.036 2H19.964l-4.456 4.842H4.87C3.28 6.842 2 8.116 2 9.684v31.474C2 42.726 3.281 44 4.87 44h46.26C52.72 44 54 42.726 54 41.158V9.684c0-1.568-1.281-2.842-2.87-2.842H40.492L36.036 2z%22 stroke%3D%22%23298070%22 stroke-width%3D%224%22 fill%3D%22none%22 fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E")',
        },
      },
      '.onfido-sdk-ui-crossDevice-CrossDeviceSubmit-icon': {
        backgroundImage:
          'url("data:image/svg+xml,%3C%3Fxml version%3D%221.0%22 encoding%3D%22UTF-8%22 standalone%3D%22no%22%3F%3E%3Csvg width%3D%2236px%22 height%3D%2236px%22 viewBox%3D%220 0 36 36%22 version%3D%221.1%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E    %3C!-- Generator%3A Sketch 46.2 (44496) - http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch --%3E    %3Ctitle%3Ecomplete-tick-small%3C%2Ftitle%3E    %3Cdesc%3ECreated with Sketch.%3C%2Fdesc%3E    %3Cdefs%3E%3C%2Fdefs%3E    %3Cg id%3D%22Symbols%22 stroke%3D%22none%22 stroke-width%3D%221%22 fill%3D%22none%22 fill-rule%3D%22evenodd%22%3E        %3Cg id%3D%22icons%2Fcomplete-tick%2Fmedium%22 transform%3D%22translate(-6.000000%2C -6.000000)%22%3E            %3Cg id%3D%22complete-tick-medium%22%3E                %3Cg id%3D%22complete-tick-small%22 transform%3D%22translate(6.000000%2C 6.000000)%22%3E                    %3Ccircle id%3D%22Oval%22 fill%3D%22%23298070%22 cx%3D%2218%22 cy%3D%2218%22 r%3D%2218%22%3E%3C%2Fcircle%3E                    %3Cpath d%3D%22M24.250633%2C10.5256681 L15.4492545%2C19.8654105 L13.249367%2C17.5304749 C12.2771252%2C16.5025957 10.7008135%2C16.5025957 9.72918132%2C17.5304749 C8.75693956%2C18.5646991 8.75693956%2C20.2397616 9.72918132%2C21.2676408 L13.6894664%2C25.4743319 C14.6617082%2C26.508556 16.2374103%2C26.508556 17.2096521%2C25.4743319 L27.7708187%2C14.2628341 C28.7430604%2C13.2286099 28.7430604%2C11.5535474 27.7708187%2C10.5256681 C26.7985769%2C9.49144395 25.2228748%2C9.49144395 24.250633%2C10.5256681 Z%22 id%3D%22checkmark%22 fill%3D%22%23FFFFFF%22%3E%3C%2Fpath%3E                %3C%2Fg%3E            %3C%2Fg%3E        %3C%2Fg%3E    %3C%2Fg%3E%3C%2Fsvg%3E");',
      },
    },
  }));
