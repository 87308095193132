import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, makeStyles, Typography, Fab, Slider } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from 'assets/icons/PlusIcon.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/MinusIcon.svg';
import { REGISTER_TOP_BAR_HEIGHT } from 'utils/constants';
import LoaderWrapper from 'components/LoaderWrapper';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    backgroundColor: theme.palette.accent.seaMist,
    padding: 20,
    paddingTop: '8vh',
    minHeight: `calc(100vh - ${REGISTER_TOP_BAR_HEIGHT}px - 80px)`,
    overflowY: 'auto',
  },
  title: {
    fontWeight: 400,
    fontSize: 30,
    marginBottom: 30,
  },
  subtitle: {
    marginTop: 5,
    marginBottom: 15,
  },
  lastItem: {
    marginTop: 25,
  },
  boldText: {
    fontWeight: 'bold',
  },
  fab: {
    boxShadow: 'unset',
    '&:active': {
      boxShadow: 'unset',
    },
  },
  projectValueNumber: {
    fontSize: 45,
    lineHeight: '55px',
    marginBottom: 35,
  },
  sliderThumb: {
    width: 18,
    height: 18,
    marginTop: -8,
    marginLeft: -8,
  },
  footer: {
    padding: '16px 20px',
    height: 80,
    backgroundColor: '#f8f9f9',
    boxShadow: theme.shadows[4],
    zIndex: 1,
  },
}));

const min = 1,
  max = 75,
  step = 5;

const ProjectedValue = ({ handleNext, data, buttonsDisabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const selectedPortfolio = data.accountType === 'sukuk' ? 'sukuk' : data.selectedPortfolio;
  const portfolio = useSelector((state) =>
    state.misc.portfolios?.find((p) => p.name.toLowerCase() === selectedPortfolio.toLowerCase())
  );
  const [years, setYears] = useState(10);

  const handleIncreaseYears = () => {
    setYears((prev) => (prev + step > max ? max : prev + step));
  };
  const handleDecreaseYears = () => {
    setYears((prev) => (prev - step < min ? min : prev - step));
  };

  const calcProjectedValue = (yearlyRate) => {
    const amountsOfPayments = years * 12;
    const monthlyRate = (1 + yearlyRate) ** (1 / 12) - 1;
    // Uniform Series Compound Amount Factor with initial value
    if (yearlyRate > 0) {
      return Math.round(
        (data.monthlyTopUp * ((1 + monthlyRate) ** amountsOfPayments - 1)) / monthlyRate +
          data.initialInvestment * (1 + yearlyRate) ** years
      );
    } else {
      return Math.round(data.initialInvestment + data.monthlyTopUp * amountsOfPayments);
    }
  };

  return (
    <Grid container direction="row" className={classes.root} component="form">
      <Grid item xs={12} container justify="center" alignItems="flex-start" className={classes.content}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12} container justify="center">
            <Typography variant="h3" align="center" className={classes.title}>
              <Trans
                i18nKey="plan.projected-value.title"
                values={{
                  initial: {
                    price: data.initialInvestment,
                    currency: data.selectedCurrencyCode,
                  },
                  monthly: {
                    price: data.monthlyTopUp,
                    currency: data.selectedCurrencyCode,
                  },
                  years,
                }}
              />
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
                <Fab
                  className={classes.fab}
                  size="small"
                  color="primary"
                  onClick={handleDecreaseYears}
                  disabled={buttonsDisabled}
                >
                  <MinusIcon />
                </Fab>
              </Grid>
              <Grid item xs container alignItems="center">
                <Slider
                  value={years}
                  min={min}
                  max={max}
                  onChange={(e, value) => setYears(value)}
                  disabled={buttonsDisabled}
                  classes={{
                    thumb: classes.sliderThumb,
                  }}
                />
              </Grid>
              <Grid item>
                <Fab
                  className={classes.fab}
                  size="small"
                  color="primary"
                  onClick={handleIncreaseYears}
                  disabled={buttonsDisabled}
                >
                  <PlusIcon />
                </Fab>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" align="center" className={classes.subtitle}>
                {t('projectedValue')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" align="center" className={classes.projectValueNumber}>
                {t('helpers.formatPrice', {
                  value: {
                    price: calcProjectedValue(portfolio.averageYoyHistoricalPerformance ?? 0.0),
                    currency: data.selectedCurrencyCode,
                  },
                })}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle2" align="center" className={classes.subtitle}>
                {t('plan.projected-value.projectCashValue')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" align="center" className={classes.projectValueNumber}>
                {t('helpers.formatPrice', {
                  value: {
                    price: calcProjectedValue(0.0),
                    currency: data.selectedCurrencyCode,
                  },
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        justify="space-between"
        direction="row"
        className={classes.footer}
      >
        <LoaderWrapper loading={buttonsDisabled}>
          <Button color="primary" onClick={handleNext} disabled={buttonsDisabled}>
            {t('plan.createPlan')}
          </Button>
        </LoaderWrapper>
      </Grid>
    </Grid>
  );
};

ProjectedValue.propTypes = {
  handleNext: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  buttonsDisabled: PropTypes.bool.isRequired,
};

export default ProjectedValue;
