import React, { useEffect } from 'react';
import { List, ListItem, ListItemSecondaryAction, Radio, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { updateBackPath } from 'store/reducers/UI';
import useDelayHistory from 'hooks/useDelayHistory';

const useStyles = makeStyles(() => ({
  list: {
    backgroundColor: 'white',
    padding: 0,
  },
  listItem: {
    height: 50,
  },
}));

const AppSettingsLanguages = () => {
  const classes = useStyles();
  const location = useLocation();
  const delayHistory = useDelayHistory();
  const { t, i18n } = useTranslation();
  const languages = i18n.options.supportedLngs.filter((lang) => lang !== 'cimode');
  const handleLanguageChange = async (lang) => {
    const from = location?.state?.from;
    if (from === '/user/settings/app')
      delayHistory.goBack(null, async () => {
        await i18n.changeLanguage(lang);
      });
    else
      delayHistory.replace({ pathname: '/user/settings/app', state: { transition: 'slide-left' } }, null, async () => {
        await i18n.changeLanguage(lang);
      });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateBackPath({ path: '/user/settings/app' }));
  }, [dispatch]);

  return (
    <>
      <List className={classes.list}>
        {languages.map((lang) => (
          <ListItem button divider key={lang} className={classes.listItem} onClick={() => handleLanguageChange(lang)}>
            {t(`languages.${lang}`)}
            <ListItemSecondaryAction>
              <Radio checked={lang === i18n.language} color="primary" onClick={() => handleLanguageChange(lang)} />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default AppSettingsLanguages;
