import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, Fab } from '@material-ui/core';

const propTypes = {
  // Milliseconds to wait before registering the click.
  delay: PropTypes.number,

  // Called after the link is clicked and before the delay timer starts.
  onDelayStart: PropTypes.func,

  // Called after the delay timer ends.
  onDelayEnd: PropTypes.func,

  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

const defaultProps = {
  delay: 250,
  onDelayStart: () => {},
  onDelayEnd: () => {},
  onClick: () => {},
};

export const DelayButton = ({ delay, onDelayStart, onDelayEnd, onClick, children, ...rest }) => {
  const [disabled, setDisabled] = useState(false);
  const handleClick = (e) => {
    if (disabled) return;
    setDisabled(true);
    onDelayStart(e);
    if (e.defaultPrevented) {
      return;
    }
    e.preventDefault();

    setTimeout(() => {
      onClick();
      onDelayEnd(e);
      setDisabled(false);
    }, delay);
  };
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button onClick={handleClick} {...rest}>
      {children}
    </Button>
  );
};

DelayButton.propTypes = propTypes;
DelayButton.defaultProps = defaultProps;

export const DelayIconButton = ({ delay, onDelayStart, onDelayEnd, onClick, children, ...rest }) => {
  const [disabled, setDisabled] = useState(false);
  const handleClick = (e) => {
    if (disabled) return;
    setDisabled(true);
    onDelayStart(e);
    if (e.defaultPrevented) {
      return;
    }
    e.preventDefault();

    setTimeout(() => {
      onClick();
      onDelayEnd(e);
      setDisabled(false);
    }, delay);
  };
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <IconButton onClick={handleClick} {...rest}>
      {children}
    </IconButton>
  );
};

DelayIconButton.propTypes = propTypes;
DelayIconButton.defaultProps = defaultProps;

export const DelayFab = ({ delay, onDelayStart, onDelayEnd, onClick, children, ...rest }) => {
  const [disabled, setDisabled] = useState(false);
  const handleClick = (e) => {
    if (disabled) return;
    setDisabled(true);
    onDelayStart(e);
    if (e.defaultPrevented) {
      return;
    }
    e.preventDefault();

    setTimeout(() => {
      onClick();
      onDelayEnd(e);
      setDisabled(false);
    }, delay);
  };
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Fab onClick={handleClick} {...rest}>
      {children}
    </Fab>
  );
};

DelayFab.propTypes = propTypes;
DelayFab.defaultProps = defaultProps;

export const CustomDelayButton = ({ delay, onDelayStart, onDelayEnd, onClick, children }) => {
  const [disabled, setDisabled] = useState(false);
  const handleClick = (e) => {
    if (disabled) return;
    setDisabled(true);
    onDelayStart(e);
    if (e.defaultPrevented) {
      return;
    }
    e.preventDefault();

    setTimeout(() => {
      onClick();
      onDelayEnd(e);
      setDisabled(false);
    }, delay);
  };
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    children({ onClick: handleClick })
  );
};

CustomDelayButton.propTypes = propTypes;
CustomDelayButton.defaultProps = defaultProps;
