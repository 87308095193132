import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import { Trans, useTranslation } from 'react-i18next';
import { updateBackPath } from 'store/reducers/UI';
import { useDispatch } from 'react-redux';
import useDelayHistory from 'hooks/useDelayHistory';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 40px - 60px)',
    padding: '6vh 20px',
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  title: {
    marginBottom: 30,
    lineHeight: '30px',
  },
  button: {
    marginTop: 20,
    fontWeight: 500,
  },
}));

const Closed = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { planId } = useParams();
  const delayHistory = useDelayHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateBackPath({ path: `/user/plans/${planId}` }));
  }, [planId, dispatch]);

  return (
    <Grid container className={classes.root} direction="column">
      <Grid item xs={12}>
        <Typography variant="h3" align="center" color="primary" className={classes.title}>
          {t('user.plans.closed.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          <Trans i18nKey="user.plans.closed.body" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button className={classes.button} variant="outlined" onClick={() => delayHistory.push('/user/plans')}>
          {t('backToDashboard')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Closed;
