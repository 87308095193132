import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';

import { updateBackPath } from 'store/reducers/UI';
import useDelayHistory from 'hooks/useDelayHistory';
import { changePasswordAsync } from 'transfer/accountApi';
import { ReactComponent as GreenCheckboxIcon } from 'assets/icons/GreenCheckbox.svg';
import { ReactComponent as RedCheckboxIcon } from 'assets/icons/RedCheckbox.svg';
import LoaderWrapper from 'components/LoaderWrapper';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    padding: '20px 10px 30px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  forgotBtn: {
    minHeight: 'unset',
    fontSize: 14,
  },
  submitBtn: {
    marginTop: 10,
  },
  requirements: {
    marginTop: 10,
    padding: '10px 18px',
    borderRadius: 3,
    backgroundColor: '#EFEFEF',
  },
  requirementsTitle: {
    marginBottom: 15,
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const delayHistory = useDelayHistory();
  const [submitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const schema = useMemo(
    () =>
      yup.object().shape({
        currentPassword: yup.string().required(t('form-validations.required')),
        newPassword: yup
          .string()
          .matches('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})', t('form-validations.password'))
          .required(t('form-validations.required')),
        confirmNewPassword: yup
          .string()
          .oneOf([yup.ref('newPassword'), null], t('form-validations.confirmPassword'))
          .required(t('form-validations.required')),
      }),
    [t]
  );
  const { register, handleSubmit, errors, watch } = useForm({
    mode: 'onBlur',
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setSubmitting(true);
    setErrorMsg(null);
    if (submitting) return;

    const res = await changePasswordAsync(data.currentPassword, data.newPassword);
    setSubmitting(false);

    if (!res) {
      setErrorMsg(t('error-messages.updatePassword'));
    } else {
      delayHistory.push('/user/settings/security');
    }
  };
  const password = watch('newPassword');
  const hasLength = Boolean(password.match('^(?=.{6,})'));
  const hasUppercase = Boolean(password.match('^(?=.*[A-Z])'));
  const hasLowercase = Boolean(password.match('^(?=.*[a-z])'));
  const hasNumber = Boolean(password.match('^(?=.*[0-9])'));

  const renderRequirement = (requirementFulfilled, text) => (
    <Typography variant="body1">
      {requirementFulfilled ? <GreenCheckboxIcon /> : <RedCheckboxIcon />}
      {` ${text}`}
    </Typography>
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateBackPath({ path: '/user/settings/security' }));
  }, [dispatch]);

  return (
    <Grid container className={classes.root} spacing={1} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <TextField
          name="currentPassword"
          label={t('currentPassword')}
          type="password"
          inputRef={register}
          error={Boolean(errors.currentPassword)}
          helperText={errors.currentPassword?.message}
          InputProps={{
            classes: {
              underline: classes.underline,
            },
            endAdornment: (
              <Button variant="text" color="primary" fullWidth={false} className={classes.forgotBtn}>
                {t('forgot?')}
              </Button>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="newPassword"
          label={t('newPassword')}
          type="password"
          inputRef={register}
          error={Boolean(errors.newPassword)}
          helperText={errors.newPassword?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="confirmNewPassword"
          label={t('repeatNewPassword')}
          type="password"
          inputRef={register}
          error={Boolean(errors.confirmNewPassword)}
          helperText={errors.confirmNewPassword?.message}
        />
      </Grid>

      <Grid item xs={12}>
        <div className={classes.requirements}>
          <Typography variant="subtitle2" color="primary" className={classes.requirementsTitle}>
            {t('password-requirements.title')}
          </Typography>
          <Typography variant="subtitle2" className={classes.requirementsTitle}>
            {t('password-requirements.subtitle')}
          </Typography>
          {renderRequirement(hasLength, t('password-requirements.length'))}
          {renderRequirement(hasUppercase, t('password-requirements.uppercase'))}
          {renderRequirement(hasLowercase, t('password-requirements.lowercase'))}
          {renderRequirement(hasNumber, t('password-requirements.number'))}
        </div>
      </Grid>
      {errorMsg && (
        <Grid item xs={12}>
          <Typography color="error">{errorMsg}</Typography>
        </Grid>
      )}
      <Grid item xs={12} className={classes.submitBtn}>
        <LoaderWrapper loading={submitting}>
          <Button type="submit" disabled={submitting}>
            {t('updatePassword')}
          </Button>
        </LoaderWrapper>
      </Grid>
    </Grid>
  );
};

export default ChangePassword;
