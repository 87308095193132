import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Typography, Fab } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as PointerForwardIcon } from 'assets/icons/PointerForward.svg';
import { REGISTER_TOP_BAR_HEIGHT } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    flexGrow: 1,
    position: 'relative',
    backgroundColor: theme.palette.accent.pinkLady,
    height: `calc(100vh - ${REGISTER_TOP_BAR_HEIGHT}px - 80px)`,
    overflow: 'auto',
    padding: 20,
    paddingTop: '12vh',
  },
  title: {
    marginBottom: 50,
  },
  footer: {
    padding: 20,
    boxShadow: theme.shadows[4],
    height: 80,
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  },
}));

const Intro = ({ handleNext }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.root} direction="column">
      <Grid item className={classes.content}>
        <div>
          <Typography variant="h2" className={classes.title}>
            {t('identity-verification.intro.title')}
          </Typography>
          <Typography variant="body1" component="div">
            <Trans i18nKey="identity-verification.intro.body" />
          </Typography>
        </div>
      </Grid>
      <Grid item container alignItems="flex-end" direction="column" className={classes.footer}>
        <Fab color="primary" onClick={handleNext} size="small">
          <PointerForwardIcon />
        </Fab>
      </Grid>
    </Grid>
  );
};

Intro.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default Intro;
