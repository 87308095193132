import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { REGISTER_TOP_BAR_HEIGHT } from 'utils/constants';
import { fundingMethods } from 'utils/config';
import { useCreatePlanTransaction } from 'store/reducers/plans';
import { useSelector } from 'react-redux';
import LoaderWrapper from 'components/LoaderWrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    flexGrow: 1,
    position: 'relative',
    backgroundColor: theme.palette.accent.pinkLady,
    height: `calc(100vh - ${REGISTER_TOP_BAR_HEIGHT}px)`,
    overflow: 'auto',
    padding: 20,
    paddingTop: '8vh',
  },
  title: {
    marginBottom: 25,
  },
  disabledButton: {
    backgroundColor: '#EFEFEF !important',
    color: '#777 !important',
  },
  comingSoon: {
    fontSize: 16,
    marginTop: 20,
  },
}));

const SelectFundingMethod = ({ handleNext, data, updateData }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, createPlanTransaction] = useCreatePlanTransaction();
  const { plans } = useSelector((state) => state.plans);

  const handleSelectMethod = async (method) => {
    const planId = plans ? (plans.length > 0 ? plans[0].id : null) : null;
    if (planId === null || isSubmitting) return;
    setIsSubmitting(true);
    switch (method) {
      case 'wireTransfer':
        const transaction = await createPlanTransaction(planId, data.initialInvestment, data.currencyCode);
        updateData({ ...data, paymentMethod: method, transaction });
        handleNext();
    }
    setIsSubmitting(false);
  };

  return (
    <Grid container className={classes.root} direction="column">
      <Grid item className={classes.content}>
        <div>
          <Typography variant="h4" className={classes.title} align="center">
            {t('register-transfer.select-funding-method.title')}
          </Typography>
        </div>
        <Grid item container spacing={2}>
          {fundingMethods.map((method) => (
            <Grid key={method.id} item xs={12}>
              <LoaderWrapper loading={isSubmitting}>
                <Button disabled={isSubmitting} onClick={() => handleSelectMethod(method.id)}>
                  {t(method.transKey)}
                </Button>
              </LoaderWrapper>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Typography variant="h5" align="center" className={classes.comingSoon}>
              {t('register-transfer.select-funding-method.coming-soon')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button disabled className={classes.disabledButton}>
              {t('payment-methods.linkBankAccount')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

SelectFundingMethod.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default SelectFundingMethod;
