import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid, makeStyles, Button } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { DatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { checkEmailAvailability } from '../../../transfer/accountApi';
import LoaderWrapper from '../../../components/LoaderWrapper';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const PersonalInfo = ({ handleNext, userData, updateUserData, setDisabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const schema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().required(t('form-validations.required')),
        lastName: yup.string().required(t('form-validations.required')),
        email: yup.string().email(t('form-validations.email')).required(t('form-validations.required')),
        dateOfBirth: yup
          .date()
          .max(
            new Date(new Date().setFullYear(new Date().getFullYear() - 18)), // TODO: There must be a better way to do this
            t('form-validations.ageRestriction18')
          )
          .required(t('form-validations.required')),
      }),
    [t]
  );

  const {
    register,
    handleSubmit,
    control,
    errors,
    setError,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      dateOfBirth: userData.dateOfBirth,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setSubmitting(true);
    setDisabled(true);
    if (isSubmitting || submitting) return;
    updateUserData({
      ...userData,
      ...data,
    });

    const isAvailable = await checkEmailAvailability(data.email);
    if (!isAvailable) {
      setError('email', {
        type: 'manual',
        message: t('form-validations.email-not-available'),
      });
      setSubmitting(false);
      setDisabled(false);
    } else {
      handleNext(() => {
        setSubmitting(false);
        setDisabled(false);
      });
    }
  };

  return (
    <Grid container spacing={2} className={classes.root} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <TextField
          name="firstName"
          label={t('firstName')}
          inputRef={register}
          type="text"
          error={Boolean(errors.firstName)}
          helperText={errors.firstName?.message}
          disabled={submitting}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="lastName"
          label={t('lastName')}
          inputRef={register}
          error={Boolean(errors.lastName)}
          helperText={errors.lastName?.message}
          disabled={submitting}
          type="text"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="email"
          label={t('email')}
          inputRef={register}
          type="email"
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          disabled={submitting}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          as={
            <DatePicker
              disableFuture
              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
              openTo="year"
              format="dd/MM/yyyy"
              label={t('dateOfBirth')}
              views={['year', 'month', 'date']}
              error={Boolean(errors.dateOfBirth)}
              helperText={errors.dateOfBirth?.message}
              disabled={submitting}
            />
          }
          control={control}
          name="dateOfBirth"
        />
      </Grid>
      <Grid item xs={12}>
        <LoaderWrapper loading={submitting}>
          <Button type="submit" disabled={submitting}>
            {t('continue')}
          </Button>
        </LoaderWrapper>
      </Grid>
    </Grid>
  );
};

PersonalInfo.propTypes = {
  handleNext: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  updateUserData: PropTypes.func.isRequired,
  setDisabled: PropTypes.func.isRequired,
};

export default PersonalInfo;
