import { Button, withStyles } from '@material-ui/core';
import { shadeColor } from 'utils/helpers';

/**
 * Props:
 * bgcolor - Can be any accent keys in palette or any hex color.
 */

export default withStyles((theme) => ({
  root: (props) => {
    const color = theme.palette.accent[props.bgcolor] ?? props.bgcolor;
    return {
      backgroundColor: color,
      color: 'black',
      '&:hover': {
        backgroundColor: shadeColor(color, -25),
      },
    };
  },
}))(Button);
