import { useState } from 'react';

export default function (initialValues = []) {
  const [values, setValues] = useState(initialValues);

  const toggleValue = (newValue) => {
    let newValues = [...values];
    const indexOfValue = values.indexOf(newValue);
    if (indexOfValue >= 0) {
      newValues.splice(indexOfValue, 1);
    } else {
      newValues = [...newValues, newValue];
    }
    setValues(newValues);
  };

  const setMultipleValues = (newValues) => {
    newValues.filter((v) => !values.includes(v));
    setValues([...values, ...newValues]);
  };

  const setNewValues = (newValues) => {
    setValues(newValues);
  };

  return { values, toggleValue, setMultipleValues, setNewValues };
}
