export const plans = [
  {
    id: 1,
    name: 'House Deposit',
    type: 'Regular',
    portfolio: 'cautious',
    investmentStyle: 'Moderately conservative',
    cocoaMgtFee: '0.5%',
    growthPercent: 6.5,
    value: 277.52,
    totalGrowth: 31.52,
    targetValue: 3000,
    invalid: true,
  },
  {
    id: 2,
    name: 'Kids Future',
    type: 'Regular',
    investmentStyle: 'Moderately conservative',
    portfolio: 'tentative',
    cocoaMgtFee: '0.5%',
    growthPercent: -1.2,
    value: 1200,
    totalGrowth: 0,
    targetValue: 0,
    invalid: true,
  },
  {
    id: 3,
    name: 'Rolex Seamaster Seamaster',
    type: 'Regular',
    investmentStyle: 'Moderately conservative',
    portfolio: 'confident',
    cocoaMgtFee: '0.5%',
    growthPercent: 6.0,
    value: 13000,
    totalGrowth: 3600,
    targetValue: 20000,
    invalid: true,
  },
  {
    id: 4,
    name: 'Retirement',
    type: 'Regular',
    investmentStyle: 'Moderately conservative',
    portfolio: 'ambitious',
    cocoaMgtFee: '0.5%',
    growthPercent: 4.3,
    value: 231700,
    totalGrowth: 53000,
    targetValue: 500000,
    invalid: true,
  },
  {
    id: 5,
    name: 'Wedding',
    type: 'Regular',
    investmentStyle: 'Moderately conservative',
    portfolio: 'adventurous',
    cocoaMgtFee: '0.5%',
    growthPercent: 3.6,
    value: 56100,
    totalGrowth: 8700,
    targetValue: 100000,
    invalid: true,
  },
];

export const stockData = [
  {
    y: 48.75,
    label: 'Global stocks',
    subData: [
      {
        percent: 16.25,
        title: 'Templeton Shariah Global Equity',
      },
      {
        percent: 16.25,
        title: 'HSBC Islamic Global Equity Index Fund',
      },
      {
        percent: 16.25,
        title: 'iShares MSCI World Islamic UCITS ETF',
      },
    ],
  },
  {
    y: 16.25,
    label: 'Emerging Market Stocks',
    subData: [
      {
        percent: 6.25,
        title: 'Templeton Shariah Global Equity',
      },
      {
        percent: 5,
        title: 'HSBC Islamic Global Equity Index Fund',
      },
      {
        percent: 5,
        title: 'iShares MSCI World Islamic UCITS ETF',
      },
    ],
  },
  {
    y: 12,
    label: 'Sukuk',
    subData: [
      {
        percent: 4,
        title: 'Templeton Shariah Global Equity',
      },
      {
        percent: 4,
        title: 'HSBC Islamic Global Equity Index Fund',
      },
      {
        percent: 4,
        title: 'iShares MSCI World Islamic UCITS ETF',
      },
    ],
  },
  {
    y: 12,
    label: 'Gold',
    subData: [
      {
        percent: 4,
        title: 'Templeton Shariah Global Equity',
      },
      {
        percent: 4,
        title: 'HSBC Islamic Global Equity Index Fund',
      },
      {
        percent: 4,
        title: 'iShares MSCI World Islamic UCITS ETF',
      },
    ],
  },
  {
    y: 9,
    label: 'Cash',
    subData: [
      {
        percent: 3,
        title: 'Templeton Shariah Global Equity',
      },
      {
        percent: 3,
        title: 'HSBC Islamic Global Equity Index Fund',
      },
      {
        percent: 3,
        title: 'iShares MSCI World Islamic UCITS ETF',
      },
    ],
  },
];

export const messages = [
  {
    title: 'Your quarterly statement',
    date: new Date(new Date().setHours(new Date().getHours() - 5)),
  },
  {
    title: 'Your costs & charges statement',
    date: new Date(new Date().setDate(new Date().getDate() - 3)),
  },
  {
    title: 'Your COCOA query',
    date: new Date(new Date().setDate(new Date().getDate() - 5)),
  },
  {
    title: 'We received your money',
    date: new Date(new Date().setDate(new Date().getDate() - 10)),
  },
  {
    title: 'Your COCOA statement',
    date: new Date(new Date().setDate(new Date().getDate() - 15)),
  },
];

export const portfolioPerformanceMockedData = [
  { date: '2010-03-03T08:00:00.000Z', close: 209.33 },
  { date: '2010-03-04T08:00:00.000Z', close: 210.71 },
  { date: '2010-03-05T08:00:00.000Z', close: 218.95 },
  { date: '2010-03-08T08:00:00.000Z', close: 219.08 },
  { date: '2010-03-09T08:00:00.000Z', close: 223.02 },
  { date: '2010-03-10T08:00:00.000Z', close: 224.84 },
  { date: '2010-03-11T08:00:00.000Z', close: 225.5 },
  { date: '2010-03-12T08:00:00.000Z', close: 226.6 },
  { date: '2010-03-15T07:00:00.000Z', close: 223.84 },
  { date: '2010-03-16T07:00:00.000Z', close: 224.45 },
  { date: '2010-03-17T07:00:00.000Z', close: 224.12 },
  { date: '2010-03-18T07:00:00.000Z', close: 224.65 },
  { date: '2010-03-19T07:00:00.000Z', close: 222.25 },
  { date: '2010-03-22T07:00:00.000Z', close: 224.75 },
  { date: '2010-03-23T07:00:00.000Z', close: 228.36 },
  { date: '2010-03-24T07:00:00.000Z', close: 229.37 },
  { date: '2010-03-25T07:00:00.000Z', close: 226.65 },
  { date: '2010-03-26T07:00:00.000Z', close: 230.9 },
  { date: '2010-03-29T07:00:00.000Z', close: 232.39 },
  { date: '2010-03-30T07:00:00.000Z', close: 235.84 },
  { date: '2010-03-31T07:00:00.000Z', close: 235 },
  { date: '2010-04-01T07:00:00.000Z', close: 235.97 },
  { date: '2010-04-02T07:00:00.000Z', close: 235.97 },
  { date: '2010-04-05T07:00:00.000Z', close: 238.49 },
  { date: '2010-04-06T07:00:00.000Z', close: 239.54 },
  { date: '2010-04-07T07:00:00.000Z', close: 240.6 },
  { date: '2010-04-08T07:00:00.000Z', close: 239.95 },
  { date: '2010-04-09T07:00:00.000Z', close: 241.79 },
  { date: '2010-04-12T07:00:00.000Z', close: 242.29 },
  { date: '2010-04-13T07:00:00.000Z', close: 242.43 },
  { date: '2010-04-14T07:00:00.000Z', close: 245.69 },
  { date: '2010-04-15T07:00:00.000Z', close: 248.92 },
  { date: '2010-04-16T07:00:00.000Z', close: 247.4 },
  { date: '2010-04-19T07:00:00.000Z', close: 247.07 },
  { date: '2010-04-20T07:00:00.000Z', close: 244.59 },
  { date: '2010-04-21T07:00:00.000Z', close: 259.22 },
  { date: '2010-04-22T07:00:00.000Z', close: 266.47 },
  { date: '2010-04-23T07:00:00.000Z', close: 270.83 },
  { date: '2010-04-26T07:00:00.000Z', close: 269.5 },
  { date: '2010-04-27T07:00:00.000Z', close: 262.04 },
  { date: '2010-04-28T07:00:00.000Z', close: 261.6 },
  { date: '2010-04-29T07:00:00.000Z', close: 268.64 },
  { date: '2010-04-30T07:00:00.000Z', close: 261.09 },
  { date: '2010-05-03T07:00:00.000Z', close: 266.35 },
  { date: '2010-05-04T07:00:00.000Z', close: 258.68 },
  { date: '2010-05-05T07:00:00.000Z', close: 255.98 },
  { date: '2010-05-06T07:00:00.000Z', close: 246.25 },
  { date: '2010-05-07T07:00:00.000Z', close: 235.86 },
  { date: '2010-05-10T07:00:00.000Z', close: 253.99 },
  { date: '2010-05-11T07:00:00.000Z', close: 256.52 },
  { date: '2010-05-12T07:00:00.000Z', close: 262.09 },
  { date: '2010-05-13T07:00:00.000Z', close: 258.36 },
  { date: '2010-05-14T07:00:00.000Z', close: 253.82 },
  { date: '2010-05-17T07:00:00.000Z', close: 254.22 },
  { date: '2010-05-18T07:00:00.000Z', close: 252.36 },
  { date: '2010-05-19T07:00:00.000Z', close: 248.34 },
  { date: '2010-05-20T07:00:00.000Z', close: 237.76 },
  { date: '2010-05-21T07:00:00.000Z', close: 242.32 },
  { date: '2010-05-24T07:00:00.000Z', close: 246.76 },
  { date: '2010-05-25T07:00:00.000Z', close: 245.22 },
  { date: '2010-05-26T07:00:00.000Z', close: 244.11 },
  { date: '2010-05-27T07:00:00.000Z', close: 253.35 },
  { date: '2010-05-28T07:00:00.000Z', close: 256.88 },
  { date: '2010-05-31T07:00:00.000Z', close: 256.88 },
  { date: '2010-06-01T07:00:00.000Z', close: 260.83 },
  { date: '2010-06-02T07:00:00.000Z', close: 263.95 },
  { date: '2010-06-03T07:00:00.000Z', close: 263.12 },
  { date: '2010-06-04T07:00:00.000Z', close: 255.96 },
  { date: '2010-06-07T07:00:00.000Z', close: 250.94 },
  { date: '2010-06-08T07:00:00.000Z', close: 249.33 },
  { date: '2010-06-09T07:00:00.000Z', close: 243.2 },
  { date: '2010-06-10T07:00:00.000Z', close: 250.51 },
  { date: '2010-06-11T07:00:00.000Z', close: 253.51 },
  { date: '2010-06-14T07:00:00.000Z', close: 254.28 },
  { date: '2010-06-15T07:00:00.000Z', close: 259.69 },
  { date: '2010-06-16T07:00:00.000Z', close: 267.25 },
  { date: '2010-06-17T07:00:00.000Z', close: 271.87 },
  { date: '2010-06-18T07:00:00.000Z', close: 274.07 },
  { date: '2010-06-21T07:00:00.000Z', close: 270.17 },
  { date: '2010-06-22T07:00:00.000Z', close: 273.85 },
  { date: '2010-06-23T07:00:00.000Z', close: 270.97 },
  { date: '2010-06-24T07:00:00.000Z', close: 269 },
  { date: '2010-06-25T07:00:00.000Z', close: 266.7 },
  { date: '2010-06-28T07:00:00.000Z', close: 268.3 },
  { date: '2010-06-29T07:00:00.000Z', close: 256.17 },
  { date: '2010-06-30T07:00:00.000Z', close: 251.53 },
  { date: '2010-07-01T07:00:00.000Z', close: 248.48 },
  { date: '2010-07-02T07:00:00.000Z', close: 246.94 },
  { date: '2010-07-05T07:00:00.000Z', close: 246.94 },
  { date: '2010-07-06T07:00:00.000Z', close: 248.63 },
  { date: '2010-07-07T07:00:00.000Z', close: 258.66 },
  { date: '2010-07-08T07:00:00.000Z', close: 258.09 },
  { date: '2010-07-09T07:00:00.000Z', close: 259.62 },
  { date: '2010-07-12T07:00:00.000Z', close: 257.28 },
  { date: '2010-07-13T07:00:00.000Z', close: 251.8 },
  { date: '2010-07-14T07:00:00.000Z', close: 252.73 },
  { date: '2010-07-15T07:00:00.000Z', close: 251.45 },
  { date: '2010-07-16T07:00:00.000Z', close: 249.9 },
  { date: '2010-07-19T07:00:00.000Z', close: 245.58 },
  { date: '2010-07-20T07:00:00.000Z', close: 251.89 },
  { date: '2010-07-21T07:00:00.000Z', close: 254.24 },
  { date: '2010-07-22T07:00:00.000Z', close: 259.02 },
  { date: '2010-07-23T07:00:00.000Z', close: 259.94 },
  { date: '2010-07-26T07:00:00.000Z', close: 259.28 },
  { date: '2010-07-27T07:00:00.000Z', close: 264.08 },
  { date: '2010-07-28T07:00:00.000Z', close: 260.96 },
  { date: '2010-07-29T07:00:00.000Z', close: 258.11 },
  { date: '2010-07-30T07:00:00.000Z', close: 257.25 },
  { date: '2010-08-02T07:00:00.000Z', close: 261.85 },
  { date: '2010-08-03T07:00:00.000Z', close: 261.93 },
  { date: '2010-08-04T07:00:00.000Z', close: 262.98 },
  { date: '2010-08-05T07:00:00.000Z', close: 261.7 },
  { date: '2010-08-06T07:00:00.000Z', close: 260.09 },
  { date: '2010-08-09T07:00:00.000Z', close: 261.75 },
  { date: '2010-08-10T07:00:00.000Z', close: 259.41 },
  { date: '2010-08-11T07:00:00.000Z', close: 250.19 },
  { date: '2010-08-12T07:00:00.000Z', close: 251.79 },
  { date: '2010-08-13T07:00:00.000Z', close: 249.1 },
  { date: '2010-08-16T07:00:00.000Z', close: 247.64 },
  { date: '2010-08-17T07:00:00.000Z', close: 251.97 },
  { date: '2010-08-18T07:00:00.000Z', close: 253.07 },
  { date: '2010-08-19T07:00:00.000Z', close: 249.88 },
  { date: '2010-08-20T07:00:00.000Z', close: 249.64 },
  { date: '2010-08-23T07:00:00.000Z', close: 245.8 },
  { date: '2010-08-24T07:00:00.000Z', close: 239.93 },
  { date: '2010-08-25T07:00:00.000Z', close: 242.89 },
  { date: '2010-08-26T07:00:00.000Z', close: 240.28 },
  { date: '2010-08-27T07:00:00.000Z', close: 241.62 },
  { date: '2010-08-30T07:00:00.000Z', close: 242.5 },
  { date: '2010-08-31T07:00:00.000Z', close: 243.1 },
  { date: '2010-09-01T07:00:00.000Z', close: 250.33 },
  { date: '2010-09-02T07:00:00.000Z', close: 252.17 },
  { date: '2010-09-03T07:00:00.000Z', close: 258.77 },
  { date: '2010-09-06T07:00:00.000Z', close: 258.77 },
  { date: '2010-09-07T07:00:00.000Z', close: 257.81 },
  { date: '2010-09-08T07:00:00.000Z', close: 262.92 },
  { date: '2010-09-09T07:00:00.000Z', close: 263.07 },
  { date: '2010-09-10T07:00:00.000Z', close: 263.41 },
  { date: '2010-09-13T07:00:00.000Z', close: 267.04 },
  { date: '2010-09-14T07:00:00.000Z', close: 268.06 },
  { date: '2010-09-15T07:00:00.000Z', close: 270.22 },
  { date: '2010-09-16T07:00:00.000Z', close: 276.57 },
  { date: '2010-09-17T07:00:00.000Z', close: 275.37 },
  { date: '2010-09-20T07:00:00.000Z', close: 283.23 },
  { date: '2010-09-21T07:00:00.000Z', close: 283.77 },
  { date: '2010-09-22T07:00:00.000Z', close: 287.75 },
  { date: '2010-09-23T07:00:00.000Z', close: 288.92 },
  { date: '2010-09-24T07:00:00.000Z', close: 292.32 },
  { date: '2010-09-27T07:00:00.000Z', close: 291.16 },
  { date: '2010-09-28T07:00:00.000Z', close: 286.86 },
  { date: '2010-09-29T07:00:00.000Z', close: 287.37 },
  { date: '2010-09-30T07:00:00.000Z', close: 283.75 },
  { date: '2010-10-01T07:00:00.000Z', close: 282.52 },
  { date: '2010-10-04T07:00:00.000Z', close: 278.64 },
  { date: '2010-10-05T07:00:00.000Z', close: 288.94 },
  { date: '2010-10-06T07:00:00.000Z', close: 289.19 },
  { date: '2010-10-07T07:00:00.000Z', close: 289.22 },
  { date: '2010-10-08T07:00:00.000Z', close: 294.07 },
  { date: '2010-10-11T07:00:00.000Z', close: 295.36 },
  { date: '2010-10-12T07:00:00.000Z', close: 298.54 },
  { date: '2010-10-13T07:00:00.000Z', close: 300.14 },
  { date: '2010-10-14T07:00:00.000Z', close: 302.31 },
  { date: '2010-10-15T07:00:00.000Z', close: 314.74 },
  { date: '2010-10-18T07:00:00.000Z', close: 318 },
  { date: '2010-10-19T07:00:00.000Z', close: 309.49 },
  { date: '2010-10-20T07:00:00.000Z', close: 310.53 },
  { date: '2010-10-21T07:00:00.000Z', close: 309.52 },
  { date: '2010-10-22T07:00:00.000Z', close: 307.47 },
  { date: '2010-10-25T07:00:00.000Z', close: 308.84 },
  { date: '2010-10-26T07:00:00.000Z', close: 308.05 },
  { date: '2010-10-27T07:00:00.000Z', close: 307.83 },
  { date: '2010-10-28T07:00:00.000Z', close: 305.24 },
  { date: '2010-10-29T07:00:00.000Z', close: 300.98 },
  { date: '2010-11-01T07:00:00.000Z', close: 304.18 },
  { date: '2010-11-02T07:00:00.000Z', close: 309.36 },
  { date: '2010-11-03T07:00:00.000Z', close: 312.8 },
  { date: '2010-11-04T07:00:00.000Z', close: 318.27 },
  { date: '2010-11-05T07:00:00.000Z', close: 317.13 },
  { date: '2010-11-08T08:00:00.000Z', close: 318.62 },
  { date: '2010-11-09T08:00:00.000Z', close: 316.08 },
  { date: '2010-11-10T08:00:00.000Z', close: 318.03 },
  { date: '2010-11-11T08:00:00.000Z', close: 316.66 },
  { date: '2010-11-12T08:00:00.000Z', close: 308.03 },
  { date: '2010-11-15T08:00:00.000Z', close: 307.04 },
  { date: '2010-11-16T08:00:00.000Z', close: 301.59 },
  { date: '2010-11-17T08:00:00.000Z', close: 300.5 },
  { date: '2010-11-18T08:00:00.000Z', close: 308.43 },
  { date: '2010-11-19T08:00:00.000Z', close: 306.73 },
  { date: '2010-11-22T08:00:00.000Z', close: 313.36 },
  { date: '2010-11-23T08:00:00.000Z', close: 308.73 },
  { date: '2010-11-24T08:00:00.000Z', close: 314.8 },
  { date: '2010-11-26T08:00:00.000Z', close: 315 },
  { date: '2010-11-29T08:00:00.000Z', close: 316.87 },
  { date: '2010-11-30T08:00:00.000Z', close: 311.15 },
  { date: '2010-12-01T08:00:00.000Z', close: 316.4 },
  { date: '2010-12-02T08:00:00.000Z', close: 318.15 },
  { date: '2010-12-03T08:00:00.000Z', close: 317.44 },
  { date: '2010-12-06T08:00:00.000Z', close: 320.15 },
  { date: '2010-12-07T08:00:00.000Z', close: 318.21 },
  { date: '2010-12-08T08:00:00.000Z', close: 321.01 },
  { date: '2010-12-09T08:00:00.000Z', close: 319.76 },
  { date: '2010-12-10T08:00:00.000Z', close: 320.56 },
  { date: '2010-12-13T08:00:00.000Z', close: 321.67 },
  { date: '2010-12-14T08:00:00.000Z', close: 320.29 },
  { date: '2010-12-15T08:00:00.000Z', close: 320.36 },
  { date: '2010-12-16T08:00:00.000Z', close: 321.25 },
  { date: '2010-12-17T08:00:00.000Z', close: 320.61 },
  { date: '2010-12-20T08:00:00.000Z', close: 322.21 },
  { date: '2010-12-21T08:00:00.000Z', close: 324.2 },
  { date: '2010-12-22T08:00:00.000Z', close: 325.16 },
  { date: '2010-12-23T08:00:00.000Z', close: 323.6 },
  { date: '2010-12-27T08:00:00.000Z', close: 324.68 },
  { date: '2010-12-28T08:00:00.000Z', close: 325.47 },
  { date: '2010-12-29T08:00:00.000Z', close: 325.29 },
  { date: '2010-12-30T08:00:00.000Z', close: 323.66 },
  { date: '2010-12-31T08:00:00.000Z', close: 322.56 },
  { date: '2011-01-03T08:00:00.000Z', close: 329.57 },
  { date: '2011-01-04T08:00:00.000Z', close: 331.29 },
  { date: '2011-01-05T08:00:00.000Z', close: 334 },
  { date: '2011-01-06T08:00:00.000Z', close: 333.73 },
  { date: '2011-01-07T08:00:00.000Z', close: 336.12 },
  { date: '2011-01-10T08:00:00.000Z', close: 342.46 },
  { date: '2011-01-11T08:00:00.000Z', close: 341.64 },
  { date: '2011-01-12T08:00:00.000Z', close: 344.42 },
  { date: '2011-01-13T08:00:00.000Z', close: 345.68 },
  { date: '2011-01-14T08:00:00.000Z', close: 348.48 },
  { date: '2011-01-18T08:00:00.000Z', close: 340.65 },
  { date: '2011-01-19T08:00:00.000Z', close: 338.84 },
  { date: '2011-01-20T08:00:00.000Z', close: 332.68 },
  { date: '2011-01-21T08:00:00.000Z', close: 326.72 },
  { date: '2011-01-24T08:00:00.000Z', close: 337.45 },
  { date: '2011-01-25T08:00:00.000Z', close: 341.4 },
  { date: '2011-01-26T08:00:00.000Z', close: 343.85 },
  { date: '2011-01-27T08:00:00.000Z', close: 343.21 },
  { date: '2011-01-28T08:00:00.000Z', close: 336.1 },
  { date: '2011-01-31T08:00:00.000Z', close: 339.32 },
  { date: '2011-02-01T08:00:00.000Z', close: 345.03 },
  { date: '2011-02-02T08:00:00.000Z', close: 344.32 },
  { date: '2011-02-03T08:00:00.000Z', close: 343.44 },
  { date: '2011-02-04T08:00:00.000Z', close: 346.5 },
  { date: '2011-02-07T08:00:00.000Z', close: 351.88 },
  { date: '2011-02-08T08:00:00.000Z', close: 355.2 },
  { date: '2011-02-09T08:00:00.000Z', close: 358.16 },
  { date: '2011-02-10T08:00:00.000Z', close: 354.54 },
  { date: '2011-02-11T08:00:00.000Z', close: 356.85 },
  { date: '2011-02-14T08:00:00.000Z', close: 359.18 },
  { date: '2011-02-15T08:00:00.000Z', close: 359.9 },
  { date: '2011-02-16T08:00:00.000Z', close: 363.13 },
  { date: '2011-02-17T08:00:00.000Z', close: 358.3 },
  { date: '2011-02-18T08:00:00.000Z', close: 350.56 },
  { date: '2011-02-22T08:00:00.000Z', close: 338.61 },
  { date: '2011-02-23T08:00:00.000Z', close: 342.62 },
  { date: '2011-02-24T08:00:00.000Z', close: 342.88 },
  { date: '2011-02-25T08:00:00.000Z', close: 348.16 },
  { date: '2011-02-28T08:00:00.000Z', close: 353.21 },
  { date: '2011-03-01T08:00:00.000Z', close: 349.31 },
  { date: '2011-03-02T08:00:00.000Z', close: 352.12 },
  { date: '2011-03-03T08:00:00.000Z', close: 359.56 },
  { date: '2011-03-04T08:00:00.000Z', close: 360 },
  { date: '2011-03-07T08:00:00.000Z', close: 355.36 },
  { date: '2011-03-08T08:00:00.000Z', close: 355.76 },
  { date: '2011-03-09T08:00:00.000Z', close: 352.47 },
  { date: '2011-03-10T08:00:00.000Z', close: 346.67 },
  { date: '2011-03-11T08:00:00.000Z', close: 351.99 },
  { date: '2011-03-14T07:00:00.000Z', close: 353.56 },
  { date: '2011-03-15T07:00:00.000Z', close: 345.43 },
  { date: '2011-03-16T07:00:00.000Z', close: 330.01 },
  { date: '2011-03-17T07:00:00.000Z', close: 334.64 },
  { date: '2011-03-18T07:00:00.000Z', close: 330.67 },
  { date: '2011-03-21T07:00:00.000Z', close: 339.3 },
  { date: '2011-03-22T07:00:00.000Z', close: 341.2 },
  { date: '2011-03-23T07:00:00.000Z', close: 339.19 },
  { date: '2011-03-24T07:00:00.000Z', close: 344.97 },
  { date: '2011-03-25T07:00:00.000Z', close: 351.54 },
  { date: '2011-03-28T07:00:00.000Z', close: 350.44 },
  { date: '2011-03-29T07:00:00.000Z', close: 350.96 },
  { date: '2011-03-30T07:00:00.000Z', close: 348.63 },
  { date: '2011-03-31T07:00:00.000Z', close: 348.51 },
  { date: '2011-04-01T07:00:00.000Z', close: 344.56 },
  { date: '2011-04-04T07:00:00.000Z', close: 341.19 },
  { date: '2011-04-05T07:00:00.000Z', close: 338.89 },
  { date: '2011-04-06T07:00:00.000Z', close: 338.04 },
  { date: '2011-04-07T07:00:00.000Z', close: 338.08 },
  { date: '2011-04-08T07:00:00.000Z', close: 335.06 },
  { date: '2011-04-11T07:00:00.000Z', close: 330.8 },
  { date: '2011-04-12T07:00:00.000Z', close: 332.4 },
  { date: '2011-04-13T07:00:00.000Z', close: 336.13 },
  { date: '2011-04-14T07:00:00.000Z', close: 332.42 },
  { date: '2011-04-15T07:00:00.000Z', close: 327.46 },
  { date: '2011-04-18T07:00:00.000Z', close: 331.85 },
  { date: '2011-04-19T07:00:00.000Z', close: 337.86 },
  { date: '2011-04-20T07:00:00.000Z', close: 342.41 },
  { date: '2011-04-21T07:00:00.000Z', close: 350.7 },
  { date: '2011-04-25T07:00:00.000Z', close: 353.01 },
  { date: '2011-04-26T07:00:00.000Z', close: 350.42 },
  { date: '2011-04-27T07:00:00.000Z', close: 350.15 },
  { date: '2011-04-28T07:00:00.000Z', close: 346.75 },
  { date: '2011-04-29T07:00:00.000Z', close: 350.13 },
  { date: '2011-05-02T07:00:00.000Z', close: 346.28 },
  { date: '2011-05-03T07:00:00.000Z', close: 348.2 },
  { date: '2011-05-04T07:00:00.000Z', close: 349.57 },
  { date: '2011-05-05T07:00:00.000Z', close: 346.75 },
  { date: '2011-05-06T07:00:00.000Z', close: 346.66 },
  { date: '2011-05-09T07:00:00.000Z', close: 347.6 },
  { date: '2011-05-10T07:00:00.000Z', close: 349.45 },
  { date: '2011-05-11T07:00:00.000Z', close: 347.23 },
  { date: '2011-05-12T07:00:00.000Z', close: 346.57 },
  { date: '2011-05-13T07:00:00.000Z', close: 340.5 },
  { date: '2011-05-16T07:00:00.000Z', close: 333.3 },
  { date: '2011-05-17T07:00:00.000Z', close: 336.14 },
  { date: '2011-05-18T07:00:00.000Z', close: 339.87 },
  { date: '2011-05-19T07:00:00.000Z', close: 340.53 },
  { date: '2011-05-20T07:00:00.000Z', close: 335.22 },
  { date: '2011-05-23T07:00:00.000Z', close: 334.4 },
  { date: '2011-05-24T07:00:00.000Z', close: 332.19 },
  { date: '2011-05-25T07:00:00.000Z', close: 336.78 },
  { date: '2011-05-26T07:00:00.000Z', close: 335 },
  { date: '2011-05-27T07:00:00.000Z', close: 337.41 },
  { date: '2011-05-31T07:00:00.000Z', close: 347.83 },
  { date: '2011-06-01T07:00:00.000Z', close: 345.51 },
  { date: '2011-06-02T07:00:00.000Z', close: 346.1 },
  { date: '2011-06-03T07:00:00.000Z', close: 343.44 },
  { date: '2011-06-06T07:00:00.000Z', close: 338.04 },
  { date: '2011-06-07T07:00:00.000Z', close: 332.04 },
  { date: '2011-06-08T07:00:00.000Z', close: 332.24 },
  { date: '2011-06-09T07:00:00.000Z', close: 331.49 },
  { date: '2011-06-10T07:00:00.000Z', close: 325.9 },
  { date: '2011-06-13T07:00:00.000Z', close: 326.6 },
  { date: '2011-06-14T07:00:00.000Z', close: 332.44 },
  { date: '2011-06-15T07:00:00.000Z', close: 326.75 },
  { date: '2011-06-16T07:00:00.000Z', close: 325.16 },
  { date: '2011-06-17T07:00:00.000Z', close: 320.26 },
  { date: '2011-06-20T07:00:00.000Z', close: 315.32 },
  { date: '2011-06-21T07:00:00.000Z', close: 325.3 },
  { date: '2011-06-22T07:00:00.000Z', close: 322.61 },
  { date: '2011-06-23T07:00:00.000Z', close: 331.23 },
  { date: '2011-06-24T07:00:00.000Z', close: 326.35 },
  { date: '2011-06-27T07:00:00.000Z', close: 332.04 },
  { date: '2011-06-28T07:00:00.000Z', close: 335.26 },
  { date: '2011-06-29T07:00:00.000Z', close: 334.04 },
  { date: '2011-06-30T07:00:00.000Z', close: 335.67 },
  { date: '2011-07-01T07:00:00.000Z', close: 343.26 },
  { date: '2011-07-05T07:00:00.000Z', close: 349.43 },
  { date: '2011-07-06T07:00:00.000Z', close: 351.76 },
  { date: '2011-07-07T07:00:00.000Z', close: 357.2 },
  { date: '2011-07-08T07:00:00.000Z', close: 359.71 },
  { date: '2011-07-11T07:00:00.000Z', close: 354 },
  { date: '2011-07-12T07:00:00.000Z', close: 353.75 },
  { date: '2011-07-13T07:00:00.000Z', close: 358.02 },
  { date: '2011-07-14T07:00:00.000Z', close: 357.77 },
  { date: '2011-07-15T07:00:00.000Z', close: 364.92 },
  { date: '2011-07-18T07:00:00.000Z', close: 373.8 },
  { date: '2011-07-19T07:00:00.000Z', close: 376.85 },
  { date: '2011-07-20T07:00:00.000Z', close: 386.9 },
  { date: '2011-07-21T07:00:00.000Z', close: 387.29 },
  { date: '2011-07-22T07:00:00.000Z', close: 393.3 },
  { date: '2011-07-25T07:00:00.000Z', close: 398.5 },
  { date: '2011-07-26T07:00:00.000Z', close: 403.41 },
  { date: '2011-07-27T07:00:00.000Z', close: 392.59 },
  { date: '2011-07-28T07:00:00.000Z', close: 391.82 },
  { date: '2011-07-29T07:00:00.000Z', close: 390.48 },
  { date: '2011-08-01T07:00:00.000Z', close: 396.75 },
  { date: '2011-08-02T07:00:00.000Z', close: 388.91 },
  { date: '2011-08-03T07:00:00.000Z', close: 392.57 },
  { date: '2011-08-04T07:00:00.000Z', close: 377.37 },
  { date: '2011-08-05T07:00:00.000Z', close: 373.62 },
  { date: '2011-08-08T07:00:00.000Z', close: 353.21 },
  { date: '2011-08-09T07:00:00.000Z', close: 374.01 },
  { date: '2011-08-10T07:00:00.000Z', close: 363.69 },
  { date: '2011-08-11T07:00:00.000Z', close: 373.7 },
  { date: '2011-08-12T07:00:00.000Z', close: 376.99 },
  { date: '2011-08-15T07:00:00.000Z', close: 383.41 },
  { date: '2011-08-16T07:00:00.000Z', close: 380.48 },
  { date: '2011-08-17T07:00:00.000Z', close: 380.44 },
  { date: '2011-08-18T07:00:00.000Z', close: 366.05 },
  { date: '2011-08-19T07:00:00.000Z', close: 356.03 },
  { date: '2011-08-22T07:00:00.000Z', close: 356.44 },
  { date: '2011-08-23T07:00:00.000Z', close: 373.6 },
  { date: '2011-08-24T07:00:00.000Z', close: 376.18 },
  { date: '2011-08-25T07:00:00.000Z', close: 373.72 },
  { date: '2011-08-26T07:00:00.000Z', close: 383.58 },
  { date: '2011-08-29T07:00:00.000Z', close: 389.97 },
  { date: '2011-08-30T07:00:00.000Z', close: 389.99 },
  { date: '2011-08-31T07:00:00.000Z', close: 384.83 },
  { date: '2011-09-01T07:00:00.000Z', close: 381.03 },
  { date: '2011-09-02T07:00:00.000Z', close: 374.05 },
  { date: '2011-09-06T07:00:00.000Z', close: 379.74 },
  { date: '2011-09-07T07:00:00.000Z', close: 383.93 },
  { date: '2011-09-08T07:00:00.000Z', close: 384.14 },
  { date: '2011-09-09T07:00:00.000Z', close: 377.48 },
  { date: '2011-09-12T07:00:00.000Z', close: 379.94 },
  { date: '2011-09-13T07:00:00.000Z', close: 384.62 },
  { date: '2011-09-14T07:00:00.000Z', close: 389.3 },
  { date: '2011-09-15T07:00:00.000Z', close: 392.96 },
  { date: '2011-09-16T07:00:00.000Z', close: 400.5 },
  { date: '2011-09-19T07:00:00.000Z', close: 411.63 },
  { date: '2011-09-20T07:00:00.000Z', close: 413.45 },
  { date: '2011-09-21T07:00:00.000Z', close: 412.14 },
  { date: '2011-09-22T07:00:00.000Z', close: 401.82 },
  { date: '2011-09-23T07:00:00.000Z', close: 404.3 },
  { date: '2011-09-26T07:00:00.000Z', close: 403.17 },
  { date: '2011-09-27T07:00:00.000Z', close: 399.26 },
  { date: '2011-09-28T07:00:00.000Z', close: 397.01 },
  { date: '2011-09-29T07:00:00.000Z', close: 390.57 },
  { date: '2011-09-30T07:00:00.000Z', close: 381.32 },
  { date: '2011-10-03T07:00:00.000Z', close: 374.6 },
  { date: '2011-10-04T07:00:00.000Z', close: 372.5 },
  { date: '2011-10-05T07:00:00.000Z', close: 378.25 },
  { date: '2011-10-06T07:00:00.000Z', close: 377.37 },
  { date: '2011-10-07T07:00:00.000Z', close: 369.8 },
  { date: '2011-10-10T07:00:00.000Z', close: 388.81 },
  { date: '2011-10-11T07:00:00.000Z', close: 400.29 },
  { date: '2011-10-12T07:00:00.000Z', close: 402.19 },
  { date: '2011-10-13T07:00:00.000Z', close: 408.43 },
  { date: '2011-10-14T07:00:00.000Z', close: 422 },
  { date: '2011-10-17T07:00:00.000Z', close: 419.99 },
  { date: '2011-10-18T07:00:00.000Z', close: 422.24 },
  { date: '2011-10-19T07:00:00.000Z', close: 398.62 },
  { date: '2011-10-20T07:00:00.000Z', close: 395.31 },
  { date: '2011-10-21T07:00:00.000Z', close: 392.87 },
  { date: '2011-10-24T07:00:00.000Z', close: 405.77 },
  { date: '2011-10-25T07:00:00.000Z', close: 397.77 },
  { date: '2011-10-26T07:00:00.000Z', close: 400.6 },
  { date: '2011-10-27T07:00:00.000Z', close: 404.69 },
  { date: '2011-10-28T07:00:00.000Z', close: 404.95 },
  { date: '2011-10-31T07:00:00.000Z', close: 404.78 },
  { date: '2011-11-01T07:00:00.000Z', close: 396.51 },
  { date: '2011-11-02T07:00:00.000Z', close: 397.41 },
  { date: '2011-11-03T07:00:00.000Z', close: 403.07 },
  { date: '2011-11-04T07:00:00.000Z', close: 400.24 },
  { date: '2011-11-07T08:00:00.000Z', close: 399.73 },
  { date: '2011-11-08T08:00:00.000Z', close: 406.23 },
  { date: '2011-11-09T08:00:00.000Z', close: 395.28 },
  { date: '2011-11-10T08:00:00.000Z', close: 385.22 },
  { date: '2011-11-11T08:00:00.000Z', close: 384.62 },
  { date: '2011-11-14T08:00:00.000Z', close: 379.26 },
  { date: '2011-11-15T08:00:00.000Z', close: 388.83 },
  { date: '2011-11-16T08:00:00.000Z', close: 384.77 },
  { date: '2011-11-17T08:00:00.000Z', close: 377.41 },
  { date: '2011-11-18T08:00:00.000Z', close: 374.94 },
  { date: '2011-11-21T08:00:00.000Z', close: 369.01 },
  { date: '2011-11-22T08:00:00.000Z', close: 376.51 },
  { date: '2011-11-23T08:00:00.000Z', close: 366.99 },
  { date: '2011-11-25T08:00:00.000Z', close: 363.57 },
  { date: '2011-11-28T08:00:00.000Z', close: 376.12 },
  { date: '2011-11-29T08:00:00.000Z', close: 373.2 },
  { date: '2011-11-30T08:00:00.000Z', close: 382.2 },
  { date: '2011-12-01T08:00:00.000Z', close: 387.93 },
  { date: '2011-12-02T08:00:00.000Z', close: 389.7 },
  { date: '2011-12-05T08:00:00.000Z', close: 393.01 },
  { date: '2011-12-06T08:00:00.000Z', close: 390.95 },
  { date: '2011-12-07T08:00:00.000Z', close: 389.09 },
  { date: '2011-12-08T08:00:00.000Z', close: 390.66 },
  { date: '2011-12-09T08:00:00.000Z', close: 393.62 },
  { date: '2011-12-12T08:00:00.000Z', close: 391.84 },
  { date: '2011-12-13T08:00:00.000Z', close: 388.81 },
  { date: '2011-12-14T08:00:00.000Z', close: 380.19 },
  { date: '2011-12-15T08:00:00.000Z', close: 378.94 },
  { date: '2011-12-16T08:00:00.000Z', close: 381.02 },
  { date: '2011-12-19T08:00:00.000Z', close: 382.21 },
  { date: '2011-12-20T08:00:00.000Z', close: 395.95 },
  { date: '2011-12-21T08:00:00.000Z', close: 396.44 },
  { date: '2011-12-22T08:00:00.000Z', close: 398.55 },
  { date: '2011-12-23T08:00:00.000Z', close: 403.43 },
  { date: '2011-12-27T08:00:00.000Z', close: 406.53 },
  { date: '2011-12-28T08:00:00.000Z', close: 402.64 },
  { date: '2011-12-29T08:00:00.000Z', close: 405.12 },
  { date: '2011-12-30T08:00:00.000Z', close: 405 },
  { date: '2012-01-03T08:00:00.000Z', close: 411.23 },
  { date: '2012-01-04T08:00:00.000Z', close: 413.44 },
  { date: '2012-01-05T08:00:00.000Z', close: 418.03 },
  { date: '2012-01-06T08:00:00.000Z', close: 422.4 },
  { date: '2012-01-09T08:00:00.000Z', close: 421.73 },
  { date: '2012-01-10T08:00:00.000Z', close: 423.24 },
  { date: '2012-01-11T08:00:00.000Z', close: 422.55 },
  { date: '2012-01-12T08:00:00.000Z', close: 421.39 },
  { date: '2012-01-13T08:00:00.000Z', close: 419.81 },
  { date: '2012-01-17T08:00:00.000Z', close: 424.7 },
  { date: '2012-01-18T08:00:00.000Z', close: 429.11 },
  { date: '2012-01-19T08:00:00.000Z', close: 427.75 },
  { date: '2012-01-20T08:00:00.000Z', close: 420.3 },
  { date: '2012-01-23T08:00:00.000Z', close: 427.41 },
  { date: '2012-01-24T08:00:00.000Z', close: 420.41 },
  { date: '2012-01-25T08:00:00.000Z', close: 446.66 },
  { date: '2012-01-26T08:00:00.000Z', close: 444.63 },
  { date: '2012-01-27T08:00:00.000Z', close: 447.28 },
  { date: '2012-01-30T08:00:00.000Z', close: 453.01 },
  { date: '2012-01-31T08:00:00.000Z', close: 456.48 },
  { date: '2012-02-01T08:00:00.000Z', close: 456.19 },
  { date: '2012-02-02T08:00:00.000Z', close: 455.12 },
  { date: '2012-02-03T08:00:00.000Z', close: 459.68 },
  { date: '2012-02-06T08:00:00.000Z', close: 463.97 },
  { date: '2012-02-07T08:00:00.000Z', close: 468.83 },
  { date: '2012-02-08T08:00:00.000Z', close: 476.68 },
  { date: '2012-02-09T08:00:00.000Z', close: 493.17 },
  { date: '2012-02-10T08:00:00.000Z', close: 493.42 },
  { date: '2012-02-13T08:00:00.000Z', close: 502.6 },
  { date: '2012-02-14T08:00:00.000Z', close: 509.46 },
  { date: '2012-02-15T08:00:00.000Z', close: 497.67 },
  { date: '2012-02-16T08:00:00.000Z', close: 502.21 },
  { date: '2012-02-17T08:00:00.000Z', close: 502.12 },
  { date: '2012-02-21T08:00:00.000Z', close: 514.85 },
];
