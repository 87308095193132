import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { initOnfido } from 'utils/onfido';
import { isAppWebView, logAnalyticsOnfidoCompleted } from 'utils/helpers';
import { generateOnfidoSdkTokenAsync, updateOnfidoDocumentStatusAsync } from 'transfer/user/onfidoApi';
import { useCreateOnfidoCheck } from 'store/reducers/user';
import { requestAppPermissions } from 'utils/helpers';
import { addNotification } from 'store/reducers/UI';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    height: 'calc(100vh - 64px)',
    width: '100vw',
    '& > div': {
      position: 'relative',
    },
  },
}));

const Onfido = ({ handleNext, handleBack, steps, country }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [token, setToken] = useState();
  const [, createCheck] = useCreateOnfidoCheck();
  const [permissionState, setPermissionState] = useState('loading'); // Can be loading, granted, not-granted
  const isWebViewApp = useSelector((state) => state.misc.isWebViewApp);
  const buildNumber = useSelector((state) => state.misc.buildNumber);
  const onfidoVerification = useSelector((state) => state.user.data.client.onfidoVerification);
  const { documentReportUploaded, facialSimilarityPhotoUploaded, proofOfAddressUploaded } = onfidoVerification ?? {};

  const stepTemplates = {
    document: {
      type: 'document',
    },
    face: {
      type: 'face',
      options: {
        requestedVariant: 'standard',
      },
    },
    poa: {
      type: 'poa',
      options: {
        country,
      },
    },
  };

  const onfidoSteps = Object.entries(steps)
    .map(([step, use]) => (use ? stepTemplates[step] : false))
    .filter(Boolean);

  const reportNames = onfidoSteps
    .map((step) => {
      switch (step.type) {
        case 'document':
          return 'document';
        case 'face':
          return 'facial_similarity_photo';
        case 'poa':
          return 'proof_of_address';
        default:
          return false;
      }
    })
    .filter(Boolean);

  const documents = {
    document: documentReportUploaded,
    face: facialSimilarityPhotoUploaded,
    proofOfAddress: proofOfAddressUploaded,
  };
  reportNames.forEach((reportName) => {
    if (reportName === 'document') {
      documents.document = true;
    } else if (reportName === 'facial_similarity_photo') {
      documents.face = true;
    } else if (reportName === 'proof_of_address') {
      documents.proofOfAddress = true;
    }
  });

  useEffect(() => {
    if (!token && permissionState === 'granted') {
      const useReferralPath = !isWebViewApp;
      generateOnfidoSdkTokenAsync(useReferralPath).then((token) => {
        setToken(token);
      });
    }
    // eslint-disable-next-line
  }, [permissionState]);

  useEffect(() => {
    if (isWebViewApp || isAppWebView()) {
      requestAppPermissions().then((permissionsGranted) => {
        setPermissionState(permissionsGranted ? 'granted' : 'not-granted');
        if (permissionsGranted === false && handleBack) {
          dispatch(
            addNotification({
              message: t('error-messages.permissions-not-granted'),
              variant: 'error',
            })
          );
          handleBack();
        }
      });
    } else {
      setPermissionState('granted');
    }
    // eslint-disable-next-line
  }, []);

  const onComplete = async () => {
    const allDocumentsUploaded = await updateOnfidoDocumentStatusAsync(documents);
    if (allDocumentsUploaded) {
      await createCheck();
      logAnalyticsOnfidoCompleted();
    }
    handleNext();
  };

  useEffect(() => {
    let onfidoOut;
    const initOnfidoFlutter = async () => {
      const continueFlow = await window.flutter_inappwebview.callHandler('begin_onfido', token);
      if (continueFlow) {
        // Always true, true, true to circumvent navigation issue after onfido flutter sdk
        const allDocumentsUploaded = await updateOnfidoDocumentStatusAsync({
          document: true,
          face: true,
          proofOfAddress: true,
        });
        if (allDocumentsUploaded) {
          await createCheck();
          logAnalyticsOnfidoCompleted();
        }
        handleNext();
      } else {
        handleBack();
      }
    };
    if (token) {
      if (isWebViewApp && Number(buildNumber) > 12) {
        // Launch web sdk for poa with flutter sdk onComplete callback
        onfidoOut = initOnfido(
          token,
          initOnfidoFlutter,
          onfidoSteps.filter((step) => step.type === 'poa'),
          reportNames,
          true
        );
      } else {
        onfidoOut = initOnfido(token, onComplete, onfidoSteps, reportNames, false);
      }
    }

    return onfidoOut ? () => onfidoOut.tearDown() : () => {};
    // eslint-disable-next-line
  }, [token]);

  return <div id="onfido-mount" className={classes.root} />;
};

Onfido.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.shape({
    document: PropTypes.bool,
    face: PropTypes.bool,
    poa: PropTypes.bool,
  }),
  /**
   * Required if poa step is enabled above (otherwise it will default to GBR).
   * Must be a 3-letter ISO code.
   */
  country: PropTypes.string,
};

Onfido.defaultProps = {
  steps: {
    document: true,
    face: true,
    poa: false,
  },
  country: null,
};

export default Onfido;
