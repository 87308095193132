import React, { useEffect } from 'react';
import { List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Attachment as AttachmentIcon } from '@material-ui/icons';

import { updateBackPath } from 'store/reducers/UI';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  list: {
    backgroundColor: 'white',
    padding: 0,
  },
  icon: {
    '& path': {
      fill: '#8e8e93',
    },
  },
}));
const LegalNotices = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateBackPath({ path: '/user/settings/security' }));
  }, [dispatch]);

  return (
    <>
      <List className={classes.list}>
        <ListItem divider button component="a" target="_blank" href="/account-opening-agreement.pdf">
          <ListItemText primary={t('account-opening-agreement')} />
          <ListItemSecondaryAction>
            <AttachmentIcon className={classes.icon} />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem divider button component="a" target="_blank" href="/platform-agreement.pdf">
          <ListItemText primary={t('platform-agreement')} />
          <ListItemSecondaryAction>
            <AttachmentIcon className={classes.icon} />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem divider button component="a" target="_blank" href="/privacy-policy.pdf">
          <ListItemText primary={t('privacy-policy')} />
          <ListItemSecondaryAction>
            <AttachmentIcon className={classes.icon} />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem divider button component="a" target="_blank" href="/cookie-policy.pdf">
          <ListItemText primary={t('cookie-policy')} />
          <ListItemSecondaryAction>
            <AttachmentIcon className={classes.icon} />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
};

export default LegalNotices;
