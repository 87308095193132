import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Button, TextField, Typography, makeStyles, Container } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useResetPassword } from 'store/reducers/user';
import LoaderWrapper from 'components/LoaderWrapper';
import { redirectUser } from 'utils/helpers';
import { ReactComponent as CocoaLogo } from 'assets/icons/CocoaLogo.svg';
import { ReactComponent as GreenCheckboxIcon } from '../assets/icons/GreenCheckbox.svg';
import { ReactComponent as RedCheckboxIcon } from '../assets/icons/RedCheckbox.svg';
import { ReactComponent as PointerBackIcon } from 'assets/icons/PointerBack.svg';
import useDelayHistory from '../hooks/useDelayHistory';
import { useSetAppSafeArea } from 'hooks/useSetAppSafeArea';
import { forgotPasswordAsync } from 'transfer/accountApi';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    width: '100%',
    background: 'url("/assets/images/home-image.png") no-repeat, linear-gradient(-1deg, #FFFFFF, #298070)',
    backgroundSize: 'cover',
    backgroundBlendMode: 'multiply',
    minHeight: '100vh',
  },
  container: {
    display: 'flex',
    minHeight: '100vh',
    padding: 20,
  },
  logo: {
    height: 100,
    marginBottom: 50,
  },
  title: {
    color: 'white',
  },
  underline: {
    '&:before, &:after': {
      borderColor: 'white !important',
    },
  },
  requirements: {
    padding: '10px 18px',
    borderRadius: 3,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
  requirementsTitle: {
    marginBottom: 15,
  },
  helperText: {
    padding: '10px 18px',
    borderRadius: 3,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
  homeIconBtn: {
    position: 'absolute',
    top: 20,
    left: 20,
    '& svg path': {
      fill: 'white',
    },
    [theme.breakpoints.down('md')]: {
      top: 24,
      left: 8,
    },
  },
}));

const PasswordReset = () => {
  const classes = useStyles();
  const delayHistory = useDelayHistory();
  useSetAppSafeArea(false);
  const { t } = useTranslation();
  const [helperText, setHelperText] = useState(null);
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [passwordWasUpdated, setPasswordWasUpdated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [{ loading: loadingResetPassword }, resetPassword] = useResetPassword();
  const isWebViewApp = useSelector((state) => state.misc.isWebViewApp);
  const schema = useMemo(
    () =>
      yup.object().shape({
        password: yup
          .string()
          .matches('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})', t('form-validations.password'))
          .required(t('form-validations.required')),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref('password'), null], t('form-validations.confirmPassword'))
          .required(t('form-validations.required')),
      }),
    [t]
  );
  const { register, errors, handleSubmit, watch, clearErrors, setError } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
  });
  const loading = loadingResetPassword || submitting;

  const password = watch('password');
  const hasLength = Boolean(password.match('^(?=.{6,})'));
  const hasUppercase = Boolean(password.match('^(?=.*[A-Z])'));
  const hasLowercase = Boolean(password.match('^(?=.*[a-z])'));
  const hasNumber = Boolean(password.match('^(?=.*[0-9])'));

  const onSubmit = async (data) => {
    setSubmitting(true);
    setIsInvalidToken(false);
    if (submitting) return;
    setHelperText(null);

    const [firstPart, token] = document.URL.split('&token=');
    const [, email] = firstPart.split('?email=');
    if (!token || token.length === 0 || !email || email.length === 0) {
      return;
    }
    try {
      const userData = await resetPassword(email, data.password, encodeURI(token));
      if (isWebViewApp) {
        redirectUser(delayHistory.push, userData, { currentPath: '/' });
      } else {
        setHelperText(t('success-messages.resetPassword'));
        setPasswordWasUpdated(true);
      }
    } catch (err) {
      setHelperText(t('error-messages.resetPassword'));
      if (err.response?.data?.type === 'InvalidToken') {
        setIsInvalidToken(true);
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const [firstPart, token] = document.URL.split('&token=');
    const [, email] = firstPart.split('?email=');
    if (!token || token.length === 0 || !email || email.length === 0) {
      delayHistory.push('/');
    }
  }, [delayHistory]);

  const renderRequirement = (requirementFulfilled, text) => (
    <Typography variant="body1">
      {requirementFulfilled ? <GreenCheckboxIcon /> : <RedCheckboxIcon />}
      {` ${text}`}
    </Typography>
  );

  const handleForgotPassword = async () => {
    clearErrors();
    setHelperText(null);
    setSubmitting(true);
    try {
      const emailSent = await forgotPasswordAsync(new URLSearchParams(window.location.search).get('email'));
      if (emailSent) {
        setIsInvalidToken(false);
        setHelperText(t('success-messages.forgotPassword'));
      } else {
        setHelperText(t('error-messages.forgotPassword'));
      }
    } catch (error) {
      setError('email', {
        type: 'manual',
        message: error.message,
      });
    }
    setSubmitting(false);
  };

  return (
    <Grid container className={classes.root} justify="center" alignItems="center">
      <Container
        component={Grid}
        maxWidth="sm"
        className={classes.container}
        container
        direction="column"
        justify="space-between"
        alignItems="center"
      >
        <IconButton component={Link} to="/" className={classes.homeIconBtn}>
          <PointerBackIcon />
        </IconButton>
        <Grid item>
          <CocoaLogo className={classes.logo} />
        </Grid>
        <Grid item container style={{ flexGrow: 1 }} alignItems="center" justify="center">
          <Grid container spacing={2} component="form" onSubmit={handleSubmit(onSubmit)} autocomplete="off">
            {!passwordWasUpdated ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="h3" align="center" className={classes.title}>
                    {t('password-reset.title')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    name="password"
                    inputRef={register}
                    label={t('password')}
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                    disabled={loading}
                    color="secondary"
                    autocomplete="new-password"
                    InputProps={{ classes: { underline: classes.underline } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    name="confirmPassword"
                    inputRef={register}
                    label={t('confirmPassword')}
                    error={Boolean(errors.confirmPassword)}
                    helperText={errors.confirmPassword?.message}
                    disabled={loading}
                    color="secondary"
                    autocomplete="new-password"
                    InputProps={{ classes: { underline: classes.underline } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.requirements}>
                    <Typography variant="subtitle2" color="primary" className={classes.requirementsTitle}>
                      {t('register.step-3.requirements-title')}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.requirementsTitle}>
                      {t('register.step-3.requirements-subtitle')}
                    </Typography>
                    {renderRequirement(hasLength, t('register.step-3.requirements.length'))}
                    {renderRequirement(hasUppercase, t('register.step-3.requirements.uppercase'))}
                    {renderRequirement(hasLowercase, t('register.step-3.requirements.lowercase'))}
                    {renderRequirement(hasNumber, t('register.step-3.requirements.number'))}
                  </div>
                </Grid>
                {helperText && (
                  <Grid item xs={12}>
                    <Typography className={classes.helperText}>{helperText}</Typography>
                  </Grid>
                )}
                {isInvalidToken && (
                  <Grid item xs={12}>
                    <Button fullWidth color="default" disabled={submitting} onClick={handleForgotPassword}>
                      {t('send-new-reset-link')}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <LoaderWrapper loading={loading && !isInvalidToken} color="secondary">
                    <Button color="primary" fullWidth type="submit" disabled={loading}>
                      {t('updatePassword')}
                    </Button>
                  </LoaderWrapper>
                </Grid>
                {isWebViewApp && (
                  <Grid item xs={12}>
                    <Typography color="secondary" align="center">
                      {t('notice-messages.resetPasswordApp')}
                    </Typography>
                  </Grid>
                )}
              </>
            ) : (
              helperText && (
                <Grid item xs={12}>
                  <Typography color="secondary" align="center">
                    {helperText}
                  </Typography>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default PasswordReset;
