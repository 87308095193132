import React, { useState } from 'react';
import { Dialog, makeStyles, Slide, Typography } from '@material-ui/core';
import { DelayIconButton } from 'components/DelayButtons';
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg';
import { Trans, useTranslation } from 'react-i18next';
import { cookieExists, setAppWrapperCookies } from 'utils/helpers';
import { APP_COOKIES } from 'utils/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...props} timeout={{ enter: 500, exit: 250 }} />;
});

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: '100%',
    margin: 0,
    padding: '40px 20px 20px',
    borderRadius: 25,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  dialogContainer: {
    alignItems: 'flex-end',
  },
  disclaimerText: {
    lineHeight: '20px',
    color: theme.palette.grey['600'],
  },
  closeDialogBtn: {
    position: 'absolute',
    right: 12,
    top: 12,
  },
  title: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginBottom: 20,
  },
}));

const SandboxDisclaimerDialog = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showDisclaimerDialog, setShowDisclaimerDialog] = useState(!cookieExists(APP_COOKIES.HIDE_SANDBOX_DISCLAIMER));

  function handleClose() {
    setShowDisclaimerDialog(false);
    setAppWrapperCookies({
      [APP_COOKIES.HIDE_SANDBOX_DISCLAIMER]: true,
    });
  }

  return (
    <Dialog
      open={showDisclaimerDialog}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth
      classes={{
        paper: classes.dialogPaper,
        container: classes.dialogContainer,
      }}
    >
      <DelayIconButton onClick={handleClose} className={classes.closeDialogBtn}>
        <CrossIcon />
      </DelayIconButton>
      <Typography variant="subtitle1" align="center" gutterBottom className={classes.title}>
        {t('sandbox-disclaimer.title')}
      </Typography>
      <Typography align="center" className={classes.disclaimerText} variant="body2">
        <Trans i18nKey="sandbox-disclaimer.body" />
      </Typography>
    </Dialog>
  );
};

export default SandboxDisclaimerDialog;
