import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Switch, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useUpdateNotifications } from 'store/reducers/user';
import { updateBackPath } from 'store/reducers/UI';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import DelayLink from 'components/DelayLink';

const useStyles = makeStyles(() => ({
  list: {
    backgroundColor: 'white',
    padding: 0,
  },
  icon: {
    '& path': {
      fill: '#8e8e93',
      stroke: '#8e8e93',
    },
  },
}));

const AppSettings = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { client } = useSelector((state) => state.user.data);
  const [{ loading }, updateNotifications] = useUpdateNotifications();

  const [data, setData] = useState({
    enableSmsNotifications: client.enableSmsNotifications,
  });

  const handleUpdateNotifications = async (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.checked,
    });
    await updateNotifications({
      enableSmsNotifications: client.enableSmsNotifications,
      [e.target.name]: e.target.checked,
    });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateBackPath({ path: '/user/settings' }));
  }, [dispatch]);

  return (
    <>
      <List className={classes.list}>
        {/*<ListItem divider button component={DelayLink} to="/user/settings/app/languages" transition="slide-right">
          <ListItemText primary={t('language')} secondary={t(`languages.${i18n.language}`)} />
          <ListItemSecondaryAction>
            <ArrowRightIcon className={classes.icon} />
          </ListItemSecondaryAction>
        </ListItem>*/}
        <ListItem divider>
          <ListItemText primary={t('notifications')} secondary={t('notificationsEnabled')} />
          <ListItemSecondaryAction>
            <Switch
              name="enableSmsNotifications"
              color="default"
              disabled={loading}
              checked={data.enableSmsNotifications}
              onChange={handleUpdateNotifications}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
};

export default AppSettings;
