import axios from 'axios';

import { apiEndpoints } from '../config';

const porfoliosEndpoint = apiEndpoints.user.porfolios;

export async function fetchPortfoliosAsync() {
  const res = await axios.get(`${porfoliosEndpoint}`);
  return res.data;
}

export async function fetchPortfolioAsync(id) {
  const res = await axios.get(`${porfoliosEndpoint}/${id}`);
  return res.data;
}
