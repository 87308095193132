import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import CustomButton from 'components/UI/CustomButton';
import { formatPrice } from 'utils/helpers';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 40px - 60px)',
    paddingTop: '12vh',
    paddingBottom: 10,
    backgroundColor: theme.palette.primary.main,
  },
  header: {
    margin: '0 20px',
  },
  title: {
    marginBottom: 15,
    fontWeight: 300,
  },
  body: {
    fontWeight: 300,
    marginBottom: 30,
  },
  button: {
    marginTop: 10,
    borderRadius: 0,
    height: 50,
    textTransform: 'uppercase',
  },
  icon: {
    height: 13,
    '& path': {
      fill: '#000',
      stroke: '#000',
    },
  },
  input: {
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.divider}`,
    padding: '0 10px',
  },
}));

const Finished = ({ handleNext, plan, data, updateData, error }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      password: '',
    },
  });

  const onSubmit = async (newData) => {
    updateData({
      ...data,
      ...newData,
    });
    handleNext();
  };

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      justify="space-between"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.header}>
            <Typography variant="h3" align="center" color="secondary" className={classes.title}>
              {t('user.transfer.withdraw.create.finished.title')}
            </Typography>
            <Typography variant="body1" align="center" color="secondary" className={classes.body}>
              {t('user.transfer.withdraw.create.finished.body', {
                value: formatPrice(data.amount, 'USD'),
                planName: plan.name,
              })}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.input}>
        <TextField
          label={t('password')}
          inputRef={register}
          required
          type="password"
          name="password"
          error={Boolean(errors.password)}
          InputProps={{
            disableUnderline: true,
          }}
        />
        {error && <Typography color="error">{error}</Typography>}
      </Grid>
      <Grid item container>
        <Grid item xs={6}>
          <CustomButton
            bgcolor="melanie"
            type="submit"
            className={classes.button}
            endIcon={<ArrowRightIcon className={classes.icon} />}
          >
            {t('finish')}
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

Finished.propTypes = {
  handleNext: PropTypes.func.isRequired,
  plan: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
};

export default Finished;
