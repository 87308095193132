import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Grid, TextField, Typography, Collapse, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { DatePicker } from '@material-ui/pickers';

import LoaderWrapper from 'components/LoaderWrapper';
import { formatPrice } from 'utils/helpers';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -10,
  },
  section: {
    paddingBottom: 5,
    marginBottom: 10,
    borderBottom: '2px solid #b8b8b8',
    '& > div:first-child': {
      marginBottom: 10,
    },
  },
  editBtnWrapper: {
    width: 'unset',
  },
  editBtn: {
    minWidth: 'unset',
    minHeight: 'unset',
    padding: 0,
    ...theme.typography.h6,
    fontWeight: 500,
  },
  bold: {
    fontWeight: 500,
  },
  noBorder: {
    border: 'none',
  },
  collapseContainer: {
    width: '100%',
  },
  item: {
    marginBottom: 5,
  },
  disclaimer: {
    marginBottom: 5,
    fontSize: 12,
    fontStyle: 'italic',
  },
  verifyBtn: {
    marginBottom: 20,
    marginTop: -10,
  },
}));

const collapseDuration = 350;

const ReviewDetails = ({ handleNext, userData, data, updateUserSettings, updating }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { investmentLimits } = useSelector((state) => state.misc);
  const [showEditDetails, setShowEditDetails] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [isToggling, setIsToggling] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const schema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().required(t('form-validations.required')),
        lastName: yup.string().required(t('form-validations.required')),
        email: yup.string().email(t('form-validations.email')).required(t('form-validations.required')),
        dateOfBirth: yup
          .date()
          .max(
            new Date(new Date().setFullYear(new Date().getFullYear() - 18)), // TODO: There must be a better way to do this
            t('form-validations.ageRestriction18')
          )
          .required(t('form-validations.required')),
      }),
    [t]
  );

  const { register, handleSubmit, control, errors } = useForm({
    defaultValues: {
      firstName: userData.givenName,
      lastName: userData.familyName,
      email: userData.email,
      dateOfBirth: new Date(userData.dateOfBirth),
    },
    resolver: yupResolver(schema),
  });

  const toggleEdit = () => {
    if (isToggling) return;
    setIsToggling(true);
    if (showDetails) {
      setShowDetails((prev) => !prev);
      setTimeout(() => {
        setShowEditDetails((prev) => !prev);
        setIsToggling(false);
      }, collapseDuration);
    } else {
      setShowEditDetails((prev) => !prev);
      setTimeout(() => {
        setShowDetails((prev) => !prev);
        setIsToggling(false);
      }, collapseDuration);
    }
  };

  const onSubmit = async (formData) => {
    setSubmitting(true);
    if (submitting) return;
    await updateUserSettings({
      familyName: formData.lastName,
      givenName: formData.firstName,
      dateOfBirth: formData.dateOfBirth,
    });
    toggleEdit();
    setSubmitting(false);
  };

  const handleEditDetails = () => {
    if (showEditDetails) {
      handleSubmit(onSubmit)();
    } else {
      toggleEdit();
    }
  };

  // eslint-disable-next-line
  const Item = ({ label, value }) => (
    <Grid item container justify="space-between" className={classes.item}>
      <Grid item xs={6} component={Typography}>
        {label}
      </Grid>
      <Grid item xs={6} component={Typography} className={classes.bold} align="right" noWrap>
        {value}
      </Grid>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <Grid container className={classes.section}>
        <Grid item container justify="space-between">
          <Typography variant="h6" className={classes.bold}>
            {t('personalDetails')}
          </Typography>
          <LoaderWrapper loading={submitting} className={classes.editBtnWrapper}>
            <Button
              variant="text"
              fullWidth={false}
              className={classes.editBtn}
              disabled={submitting || updating}
              onClick={handleEditDetails}
            >
              {showEditDetails ? t('update') : t('edit')}
            </Button>
          </LoaderWrapper>
        </Grid>
        <Collapse in={showEditDetails} timeout={collapseDuration} className={classes.collapseContainer}>
          <div className={classes.item}>
            <EditPersonalDetails userData={userData} register={register} control={control} errors={errors} />
          </div>
        </Collapse>
        <Collapse in={showDetails} timeout={collapseDuration} className={classes.collapseContainer}>
          <Item label={`${t('name')}:`} value={userData.fullName} />
          <Item label={`${t('email')}:`} value={userData.email} />
          <Item label={`${t('dateOfBirth')}:`} value={new Date(userData.dateOfBirth).toISOString().split('T')[0]} />
        </Collapse>
      </Grid>
      <Grid container className={classes.section}>
        <Grid item container justify="space-between">
          <Typography variant="h6" className={classes.bold}>
            {t('portfolio')}
          </Typography>
        </Grid>
        <Item label={t('yourPortfolio')} value={t(`portfolios.${data.portfolio}`)} />
      </Grid>
      <Grid container className={classNames(classes.section, classes.noBorder)}>
        <Grid item container justify="space-between">
          <Typography variant="h6" className={classes.bold}>
            COCOA {t('fees').toUpperCase()}
          </Typography>
        </Grid>
        <Typography className={classes.disclaimer}>{t('register-review.step2.fee-disclaimer')}</Typography>
        <Item label={t('accountBalance')} value={t('annualFees')} />
        {investmentLimits?.managementFees.map((fee, i) => {
          const betweenValuesFormatted = [
            formatPrice(fee.rangeStart),
            i === investmentLimits.managementFees.length - 1 ? null : formatPrice(fee.rangeEnd),
          ].filter(Boolean); // fee.between.map((x) => (typeof x === 'number' ? formatPrice(x) : x));
          const feePercent = (fee.feeFraction * 100).toFixed(2);
          const label =
            betweenValuesFormatted.length > 1 ? betweenValuesFormatted.join(' - ') : `${betweenValuesFormatted} +`;
          return <Item key={`cocoa-fee-${fee.feeFraction}`} label={label} value={`${feePercent}%`} />;
        })}
      </Grid>
      <Grid className={classes.verifyBtn}>
        <LoaderWrapper loading={updating}>
          <Button disabled={showEditDetails || updating} onClick={handleNext}>
            {t('verifyDetails')}
          </Button>
        </LoaderWrapper>
      </Grid>
    </div>
  );
};

ReviewDetails.propTypes = {
  data: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  updateUserSettings: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
};

export default ReviewDetails;

// eslint-disable-next-line
const EditPersonalDetails = ({ register, errors, control }) => {
  const { t } = useTranslation();

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <TextField
          name="firstName"
          label={t('firstName')}
          inputRef={register}
          type="text"
          error={Boolean(errors.firstName)}
          helperText={errors.firstName?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="lastName"
          label={t('lastName')}
          inputRef={register}
          error={Boolean(errors.lastName)}
          helperText={errors.lastName?.message}
          type="text"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="email"
          label={t('email')}
          disabled
          inputRef={register}
          type="email"
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          as={
            <DatePicker
              disableFuture
              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
              openTo="year"
              format="dd/MM/yyyy"
              label={t('dateOfBirth')}
              views={['year', 'month', 'date']}
              error={Boolean(errors.dateOfBirth)}
              helperText={errors.dateOfBirth?.message}
            />
          }
          control={control}
          name="dateOfBirth"
        />
      </Grid>
    </Grid>
  );
};

EditPersonalDetails.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};
