import React, { useEffect } from 'react';
import { List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { updateBackPath } from 'store/reducers/UI';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import DelayLink from 'components/DelayLink';

const useStyles = makeStyles(() => ({
  list: {
    backgroundColor: 'white',
    padding: 0,
  },
  icon: {
    '& path': {
      fill: '#8e8e93',
      stroke: '#8e8e93',
    },
  },
}));

const Security = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateBackPath({ path: '/user/settings' }));
  }, [dispatch]);

  return (
    <>
      <List className={classes.list}>
        <ListItem
          divider
          button
          component={DelayLink}
          to="/user/settings/security/change-password"
          transition="slide-right"
        >
          <ListItemText primary={t('changeLoginPassword')} />
          <ListItemSecondaryAction>
            <ArrowRightIcon className={classes.icon} />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          divider
          button
          component={DelayLink}
          to="/user/settings/security/legal-notices"
          transition="slide-right"
        >
          <ListItemText primary={t('legalNotices')} />
          <ListItemSecondaryAction>
            <ArrowRightIcon className={classes.icon} />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
};

export default Security;
