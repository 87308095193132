import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Grid, Typography, Checkbox, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useUpdateLegalCompliance } from 'store/reducers/user';
import LoaderWrapper from 'components/LoaderWrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.accent.regentBlue,
    padding: 30,
    transition: theme.transitions.create(['background-color']),
  },
  container: {
    height: 'fit-content',
  },
  complianceActiveBg: {
    backgroundColor: theme.palette.accent.pinkLady,
  },
  switchLabel: {
    width: 30,
    fontSize: 16,
    fontWeight: 500,
  },
  list: {
    paddingInlineStart: '15px',
    marginBlockEnd: 0,
    marginBlockStart: 0,
    '& li': {
      marginBottom: 20,
      textAlign: 'justify',
    },
  },
  checkedIcon: {
    '& svg': {
      fill: theme.palette.primary.dark,
    },
  },
}));

const LegalCompliance = ({ handleNext, data, updateData, setDisabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [{ loading }, updateLegalCompliance] = useUpdateLegalCompliance();

  const handleToggleQuestion = (ev) => {
    updateData({
      ...data,
      [ev.target.name]: ev.target.checked,
    });
  };

  const handleShowLegalCompliance = () => {
    updateData({
      ...data,
      showLegalCompliance: true,
      politicallyExposed: false,
      controlPersonOfPublicCompany: false,
      affiliatedWithBrokerage: false,
    });
  };

  const handleUpdateLegalCompliance = async () => {
    setDisabled(true);
    const res = await updateLegalCompliance({
      politicallyExposed: data.politicallyExposed,
      controlPersonOfPublicCompany: data.controlPersonOfPublicCompany,
      affiliatedWithBrokerage: data.affiliatedWithBrokerage,
    });
    setDisabled(false);
    if (res) {
      handleNext();
    }
  };

  const questionKeys = ['politicallyExposed', 'controlPersonOfPublicCompany', 'affiliatedWithBrokerage'];

  return (
    <Grid container className={classNames(classes.root, { [classes.complianceActiveBg]: data.showLegalCompliance })}>
      <Grid item container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            {t('identity-verification.legal-compliance.title')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ul className={classes.list}>
            {questionKeys.map((key) => (
              <li key={key}>
                <Grid item xs={12}>
                  <Typography>{t(`identity-verification.legal-compliance.${key}`)}</Typography>
                </Grid>
                {data.showLegalCompliance && (
                  <Grid item xs={12} container justify="flex-end">
                    {/* Switch option used in an earlier version. Referenced here if we want to revert back to it in the future.
                    <FormControlLabel
                      control={<Switch color="default" name={key} checked={data[key]} />}
                      onClick={handleToggleQuestion}
                      label={data[key] ? t('yes') : t('No')}
                      classes={{
                        label: classes.switchLabel,
                      }}
                    /> */}
                    <Checkbox
                      color="primary"
                      name={key}
                      checked={data[key]}
                      onChange={handleToggleQuestion}
                      disabled={loading}
                      classes={{
                        checked: classes.checkedIcon,
                      }}
                    />
                  </Grid>
                )}
              </li>
            ))}
          </ul>
        </Grid>
        {data.showLegalCompliance ? (
          <Grid item xs={12}>
            <LoaderWrapper loading={loading}>
              <Button onClick={handleUpdateLegalCompliance} disabled={loading}>
                {t('next')}
              </Button>
            </LoaderWrapper>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Button onClick={handleNext}>{t('identity-verification.legal-compliance.continueButton')}</Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" onClick={handleShowLegalCompliance}>
                {t('yes')}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

LegalCompliance.propTypes = {
  handleNext: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  setDisabled: PropTypes.func.isRequired,
};

export default LegalCompliance;
