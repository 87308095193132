import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import useAsyncFn from 'hooks/useAsyncFn';
import { clearPlans } from 'store/reducers/plans';
import {
  authenticateUser,
  loginUser,
  logoutUserAsync,
  registerWithEmail,
  resetPasswordAsync,
} from 'transfer/accountApi';
import {
  createBankAccountAsync,
  updateAddressAsync,
  updateFinancialInfoAsync,
  updateLegalComplianceAsync,
  updateNotifications,
  updatePersonalSettingsAsync,
  updateSuitabilityQuestionAnswersAsync,
  updateTaxInfoAsync,
} from 'transfer/user/settingsApi';
import { createOnfidoCheckAsync } from 'transfer/user/onfidoApi';
import {logAnalyticsSignup, setAppWrapperCookies} from 'utils/helpers';
import { APP_COOKIES } from 'utils/constants';

const initialState = {
  fetched: false,
  authenticated: false,
  data: {
    id: '',
    roles: [],
    email: '',
    emailConfirmed: false,
    familyName: '',
    givenName: '',
    phoneNumber: '',
    dateOfBirth: null,
    client: {
      countryOfBirth: null,
      nationality: null,
      totalTimeWeightedReturnPercent: 0,
      occupation: 0, // See occupation enum in config/enum.js
      profession: '',
      enableSmsNotifications: false,
      plansCount: false,
      address: {
        country: null,
        nationality: null,
        line1: '',
        line2: '',
        line3: '',
        city: '',
        region: '',
        zipCode: '',
      },
      bankAccount: {
        accountHolderName: '',
        accountNumber: '',
        bankName: '',
        swiftCode: '',
      },
      legalCompliance: {
        politicallyExposed: false,
        controlPersonOfPublicCompany: false,
        affiliatedWithBrokerage: false,
      },
      onfidoVerification: {
        onfidoCheckStatus: 0, // See onfidoCheckStatus enum in config/enum.js
        documentReportUploaded: false,
        facialSimilarityPhotoUploaded: false,
        proofOfAddressUploaded: false,
      },
      suitabilityQuestionAnswers: {
        answeredAt: '',
        answers: [],
        identifier: '',
        version: '',
      },
    },
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState: { ...initialState },
  reducers: {
    update(state, { payload }) {
      return {
        ...state,
        authenticated: true,
        fetched: true,
        data: {
          ...payload,
        },
      };
    },
    updatePlansCount(state, { payload }) {
      return {
        ...state,
        data: {
          ...state.data,
          client: {
            ...state.data.client,
            plansCount: payload,
          },
        },
      };
    },
    removeAuth() {
      return {
        ...initialState,
        fetched: true,
        authenticated: false,
      };
    },
  },
});

export const { update, updatePlansCount, removeAuth } = userSlice.actions;

export default userSlice.reducer;

export function useRegisterUser() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (data) => {
      const user = await registerWithEmail(data);
      if (user) {
        setAppWrapperCookies({
          [APP_COOKIES.EMAIL]: data.email,
        });
        logAnalyticsSignup();
        dispatch(update(user));
      }
      return user;
    },
    [dispatch]
  );
}

export function useResetPassword() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (email, password, token) => {
      const user = await resetPasswordAsync(email, password, token);
      if (user) {
        dispatch(update(user));
      }
      return user;
    },
    [dispatch]
  );
}

export function useAuthenticateUser() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const user = await authenticateUser();
    console.log('authenticate', user);
    if (user) {
      dispatch(update(user));
    } else {
      dispatch(removeAuth());
    }
    return user;
  }, [dispatch]);
}

export function useUpdateUser() {
  return useAuthenticateUser();
}

export function useLoginUser() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (email, password) => {
      const user = await loginUser(email, password);
      if (user) {
        setAppWrapperCookies({
          [APP_COOKIES.EMAIL]: email,
        });
        dispatch(update(user));
      }
      return user;
    },
    [dispatch]
  );
}

export function useLogoutUser() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const loggedOut = await logoutUserAsync();
    if (loggedOut) {
      dispatch(removeAuth());
      dispatch(clearPlans());
    }
    return loggedOut;
  }, [dispatch]);
}

export function useUpdateAddress() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (data) => {
      const user = await updateAddressAsync(data);
      if (user) {
        dispatch(update(user));
      }
      return user;
    },
    [dispatch]
  );
}

export function useUpdatePersonalSettings() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (data) => {
      const user = await updatePersonalSettingsAsync(data);
      if (user) {
        dispatch(update(user));
      }
      return user;
    },
    [dispatch]
  );
}

export function useUpdateTaxInfo() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (data) => {
      const user = await updateTaxInfoAsync(data);
      if (user) {
        dispatch(update(user));
      }
      return user;
    },
    [dispatch]
  );
}

export function useUpdateFinancialInfo() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (data) => {
      const user = await updateFinancialInfoAsync(data);
      if (user) {
        dispatch(update(user));
      }
      return user;
    },
    [dispatch]
  );
}

export function useUpdateLegalCompliance() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (data) => {
      const user = await updateLegalComplianceAsync(data);
      if (user) {
        dispatch(update(user));
      }
      return user;
    },
    [dispatch]
  );
}

export function useUpdateSuitabilityQuestionAnswers() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (data) => {
      const user = await updateSuitabilityQuestionAnswersAsync(data);
      if (user) {
        dispatch(update(user));
      }
      return user;
    },
    [dispatch]
  );
}

export function useUpdateNotifications() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (data) => {
      const user = await updateNotifications(data);
      if (user) {
        dispatch(update(user));
      }
      return user;
    },
    [dispatch]
  );
}

export function useCreateBankAccount() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (data) => {
      const user = await createBankAccountAsync(data);
      if (user) {
        dispatch(update(user));
      }
      return user;
    },
    [dispatch]
  );
}

export function useCreateOnfidoCheck() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const user = await createOnfidoCheckAsync();
    if (user) {
      dispatch(update(user));
    }
    return user;
  }, [dispatch]);
}
