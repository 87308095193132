import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, makeStyles, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as GrowthPositiveIcon } from 'assets/icons/GrowthPositive.svg';
import { ReactComponent as GrowthNegativeIcon } from 'assets/icons/GrowthNegative.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import { ReactComponent as TopupIcon } from 'assets/icons/TopupIcon.svg';
import { ReactComponent as WithdrawIcon } from 'assets/icons/WithdrawIcon.svg';

import { generatePortfoliosMap } from 'utils/portfolios';
import { formatPrice } from 'utils/helpers';
import CustomLinearProgress from 'components/UI/CustomLinearProgress';
import { DelayLinkButton } from 'components/DelayLinkButtons';
import PlanGoalDialog from 'components/Dialogs/PlanGoalDialog';
import useToggle from 'hooks/useToggle';
import { ReactComponent as EditIcon } from 'assets/icons/Edit.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 20,
    backgroundColor: 'white',
    border: '1px solid #ebebeb',
    marginBottom: 10,
    width: '100%',
  },
  nameButtonLink: {
    maxWidth: '100%',
  },
  nameButton: {
    padding: 0,
    minHeight: 'unset',
    marginBottom: 5,
    width: '100%',
  },
  nameButtonText: {
    fontWeight: 400,
    lineHeight: '30px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
  },
  content: {
    padding: '0 15px 5px',
  },
  valueContainer: {
    padding: '10px 0',
    '& div:last-child': {
      textAlign: 'right',
    },
  },
  valueTitle: {
    fontSize: 16,
  },
  valueSubtitle: {
    color: 'rgba(0,0,0,0.54)',
    fontSize: 12,
  },
  footer: {
    padding: '0 10px',
    borderTop: '1px solid #dfdfdf',
  },
  button: {
    color: 'black',
    textTransform: 'uppercase',
    fontSize: '12px',
    padding: '6px 4px',
  },
  icon: {
    '& path': {
      fill: 'rgba(0, 0, 0, 0.87)',
    },
  },
  forwardIcon: {
    '& path': {
      stroke: theme.palette.secondary.contrastText,
    },
  },
  growthText: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& span': {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '18px',
      color: '#2db82d',
    },
  },
  growthTextNegative: {
    '& span': {
      color: '#FF2F2F',
      fontSize: 16,
      lineHeight: '16px',
      fontWeight: 450,
    },
  },
  growthIcon: {
    marginRight: 5,
  },
  editButton: {
    border: '1px solid #888',
    padding: 2,
    marginLeft: 10,
    '& svg': {
      stroke: theme.palette.primary.main,
    },
  },
  targetAmount: {
    marginBottom: 5,
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
  },
}));

const PlanCard = ({ plan }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const portfoliosMap = generatePortfoliosMap(theme);
  const [showEditGoal, toggleShowEditGoal] = useToggle();

  const progress = plan.targetValue === 0 ? 0 : 100 * (plan.value / plan.targetValue);

  return (
    <Grid container className={classes.root}>
      <PlanGoalDialog
        key={`plan-goal-dialog-${plan.id}-${plan.targetValue}`}
        open={showEditGoal}
        handleClose={toggleShowEditGoal}
        plan={plan}
      />
      <Grid item xs={12} container justify="space-between" alignItems="center" className={classes.content}>
        <Grid
          item
          component={DelayLinkButton}
          linkClassName={classes.nameButtonLink}
          variant="text"
          className={classes.nameButton}
          to={`/user/plans/${plan.id}/overview`}
          endIcon={<ArrowRightIcon className={classes.forwardIcon} />}
          transition="slide-right"
        >
          <Typography variant="h3" color="textPrimary" className={classes.nameButtonText}>
            {plan.name}
          </Typography>
        </Grid>
        <Grid item xs={12} container justify="space-between" className={classes.valueContainer}>
          <Grid item>
            <Typography className={classes.valueTitle}>{formatPrice(plan.value)}</Typography>
            <Typography className={classes.valueSubtitle}>{t('value')}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.valueTitle}>{formatPrice(plan.totalGrowth)}</Typography>
            <Typography className={classes.valueSubtitle}>{t('growth')}</Typography>
          </Grid>
          <Grid item>
            <Typography
              className={classNames(classes.growthText, {
                [classes.growthTextNegative]: plan.totalTimeWeightedReturnPercent < 0,
              })}
            >
              {plan.totalTimeWeightedReturnPercent >= 0 ? (
                <GrowthPositiveIcon className={classes.growthIcon} />
              ) : (
                <GrowthNegativeIcon className={classes.growthIcon} />
              )}
              <span>{Math.abs(plan.totalTimeWeightedReturnPercent).toFixed(2)}%</span>
            </Typography>
            <Typography className={classes.valueSubtitle}>{t('allTimeReturn')}</Typography>
          </Grid>
        </Grid>
        {plan.targetValue > 0 ? (
          <>
            <Grid item xs={12}>
              <CustomLinearProgress
                barcolor={
                  plan.portfolio || plan.portfolioName
                    ? portfoliosMap[(plan.portfolio?.name ?? plan.portfolioName).toLowerCase()].color
                    : 'primary'
                }
                value={progress > 100 ? 100 : progress}
                variant="determinate"
              />
            </Grid>
            <Grid item xs={12} container justify="space-between">
              <Grid item>{formatPrice(0)}</Grid>
              <Grid item>
                <Typography>{formatPrice(plan.targetValue)}</Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid item>
            <Typography variant="subtitle2">
              <span>{t('noTargetSet')}</span>
              <IconButton className={classes.editButton} onClick={toggleShowEditGoal}>
                <EditIcon />
              </IconButton>
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} container className={classes.footer} justify="space-between">
        <Grid item>
          <DelayLinkButton
            to={`/user/transfer/top-up/${plan.id}`}
            transition="slide-right"
            variant="text"
            className={classes.button}
            startIcon={<TopupIcon className={classes.icon} />}
          >
            {t('topUp')}
          </DelayLinkButton>
        </Grid>
        <Grid item>
          <DelayLinkButton
            to={`/user/transfer/withdraw/${plan.id}`}
            transition="slide-right"
            variant="text"
            className={classes.button}
            startIcon={<WithdrawIcon className={classes.icon} />}
          >
            {t('withdraw')}
          </DelayLinkButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

PlanCard.propTypes = {
  plan: PropTypes.object.isRequired,
};

export default PlanCard;
