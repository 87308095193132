import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Grid, Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import { useTranslation } from 'react-i18next';
import { accountTypes } from 'utils/portfolios';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'auto',
    padding: 20,
    width: '100vw',
    '& button': {
      textAlign: 'left',
    },
    '& > button:nth-child(2)': {
      backgroundColor: theme.palette.accent.seaMist,
    },
    '& > button:nth-child(3)': {
      backgroundColor: theme.palette.accent.regentBlue,
    },
    '& > button:nth-child(4)': {
      backgroundColor: theme.palette.accent.pinkLady,
    },
  },
  title: {
    marginBottom: 15,
  },
  item: {
    cursor: 'pointer',
    margin: '12px 0',
    padding: 15,
    borderRadius: 6,
    height: 135,
    backgroundColor: theme.palette.accent.seaMist,
  },
  arrowRightIcon: {
    '& path': {
      stroke: 'black',
      fill: 'black',
    },
  },
  itemTitle: {
    fontSize: 16,
  },
  itemSubtitle: {
    fontSize: 12,
  },
  itemContent: {
    flexGrow: 1,
  },
}));

const SelectAccountType = ({
  handleNext,
  data,
  updateData,
  /*skipQuestionnaire,
  verifiedUser,*/
  recommendedPortfolio,
  hasQuestionnaireBeenAnswered = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleNextStep = (type) => {
    if (type === 'regular') {
      if (hasQuestionnaireBeenAnswered) {
        updateData({
          ...data,
          recommendedPortfolio,
          selectedPortfolio: recommendedPortfolio,
          accountType: type,
        });
      } else {
        updateData({
          ...data,
          accountType: type,
        });
      }
      handleNext();
    } /* else if (type === 'sukuk') {
      updateData({
        ...data,
        accountType: type,
        selectedPortfolio: 'sukuk',
        recommendedPortfolio: 'sukuk',
      });
      const nextStepIndex = (skipQuestionnaire ? 1 : 3) + (verifiedUser ? 1 : 0);
      handleNext(null, nextStepIndex);
    }*/
  };

  return (
    <Grid container className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        {t('accountTypes.selectAccountType')}
      </Typography>
      {accountTypes.map((type) => (
        <Grid
          key={type}
          item
          xs={12}
          container
          direction="column"
          className={classes.item}
          onClick={() => handleNextStep(type)}
          component={ButtonBase}
        >
          <Grid item container justify="space-between">
            <span>
              <span className={classes.itemTitle}>{t(`accountTypes.${type}.title`)} </span>
              <span className={classes.itemSubtitle}>{t(`accountTypes.${type}.subtitle`)}</span>
            </span>
            <ArrowRightIcon className={classes.arrowRightIcon} />
          </Grid>
          <Grid item className={classes.itemContent} container alignItems="center">
            <Typography variant="body2">{t(`accountTypes.${type}.body`)}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

SelectAccountType.propTypes = {
  handleNext: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  skipQuestionnaire: PropTypes.bool.isRequired,
  verifiedUser: PropTypes.bool.isRequired,
  recommendedPortfolio: PropTypes.string,
  hasQuestionnaireBeenAnswered: PropTypes.bool.isRequired,
};

export default SelectAccountType;
