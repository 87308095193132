import React, { useMemo } from 'react';
import { AreaClosed } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { scaleTime, scaleLinear } from '@visx/scale';
import { LinearGradient } from '@visx/gradient';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { max, extent } from 'd3-array';

// accessors
const getDate = (d) => new Date(d.date);
const getStockValue = (d) => d.close;

const LineChart = ({ data, width, height, margin = { top: 0, right: 0, bottom: 0, left: 0 } }) => {
  // bounds
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  // scales
  const dateScale = useMemo(
    () =>
      scaleTime({
        range: [margin.left, innerWidth + margin.left],
        domain: extent(data, getDate),
      }),
    [data, innerWidth, margin.left]
  );
  const stockValueScale = useMemo(
    () =>
      scaleLinear({
        range: [innerHeight + margin.top, margin.top],
        domain: [0, (max(data, getStockValue) || 0) + innerHeight / 3],
        nice: true,
      }),
    [innerHeight, margin.top, data]
  );

  if (width < 10) return null;

  return (
    <div>
      <svg width={width} height={height}>
        <rect x={0} y={0} width={width} height={height} fill="transparent" rx={14} />
        <LinearGradient
          id="area-gradient"
          from="rgba(41,128,112, 0)"
          to="rgb(41,128,112)"
          toOpacity={0.9}
          fromOffset={0.24}
        />
        <AreaClosed
          data={data}
          x={(d) => dateScale(getDate(d)) ?? 0}
          y={(d) => stockValueScale(getStockValue(d)) ?? 0}
          yScale={stockValueScale}
          strokeWidth={2}
          stroke="#298070"
          fill="url(#area-gradient)"
          curve={curveMonotoneX}
        />
      </svg>
    </div>
  );
};

const LineGradientFixedChart = ({ data, className, margin = { top: 0, right: 0, bottom: 0, left: 0 } }) => {
  return (
    <ParentSize className={className}>
      {({ width, height }) => <LineChart data={data} height={height} width={width} margin={margin} />}
    </ParentSize>
  );
};

export default LineGradientFixedChart;
