import UI from './UI';
import misc from './misc';
import user from './user';
import plans from './plans';

export default {
  misc,
  UI,
  user,
  plans,
};
