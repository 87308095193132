import { init } from 'onfido-sdk-ui';

/**
 * Documentation regarding report names: https://documentation.onfido.com/#report-names-in-api
 */

export function initOnfido(token, onComplete, steps, reportNames, useMemoryHistory) {
  return init({
    token,
    containerId: 'onfido-mount',
    onError: async (data) => {
      console.log('Onfido error');
      console.log(data);
    },
    onComplete: async (data) => {
      const postObject = {
        report_names: reportNames,
      };
      console.log('Onfido complete', data, postObject);
      await onComplete();
    },
    useMemoryHistory: useMemoryHistory,
    steps,
  });
}
