import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

import PlanPerformanceCard from 'components/Cards/PlanPerformanceCard';
// import ButtonGroup from 'components/ButtonGroup';
import PlanCard from 'components/Cards/PlanCard';
import { DelayLinkFab } from 'components/DelayLinkButtons';
import { ReactComponent as PlusIcon } from 'assets/icons/PlusIcon.svg';
import { useScrollPosition } from 'hooks/useScrollPosition';
import { useSelector } from 'react-redux';

const animationTimeInMS = 300;
const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 25,
  },
  performanceCard: {
    paddingBottom: 20,
  },
  planHeader: {
    height: 40,
    lineHeight: '40px',
    textAlign: 'center',
    backgroundColor: 'white',
    marginBottom: 10,
    borderBottom: '1px solid #dfdfdf',
    fontSize: 16,
  },
  plansContainer: {
    padding: '0 10px',
  },
  createFabWrapper: {
    position: 'sticky',
    bottom: 60,
    right: 10,
    marginLeft: 'auto',
    minWidth: 34,
    whiteSpace: 'nowrap',
    '& button > span:first-child': {
      willChange: 'padding',
      transition: `padding ${animationTimeInMS}ms ease-in-out`,
      padding: 0,
    },
    '& .create-plan-fab-label': {
      willChange: 'opacity',
      opacity: 0,
      display: 'none',
      transition: `opacity ${animationTimeInMS}ms ease-in-out`,
    },
    '& svg': {
      willChange: 'margin',
      transition: `margin ${animationTimeInMS}ms ease-in-out`,
      marginRight: 0,
    },
  },
  createFabWrapperExtended: {
    maxWidth: '100vw',
    '& svg': {
      marginRight: 5,
    },
    '& button > span:first-child': {
      padding: '0 8px 0 0',
    },
    '& button': {
      maxWidth: '100vw',
    },
    '& .create-plan-fab-label': {
      opacity: 1,
      display: 'block',
    },
  },
  createFab: {
    padding: '0 6px !important',
    maxWidth: 34,
    justifyContent: 'flex-start',
    willChange: 'max-width',
    transition: `max-width ${animationTimeInMS}ms ease-in-out`,
  },
  plusIcon: {
    overflow: 'inherit',
    width: 22,
    height: 22,
  },
}));

const PlansIndex = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [extended, setExtended] = useState(false);
  const [selectedData /*, setSelectedData */] = useState(0);
  const totalTimeWeightedReturnPercent = useSelector((state) => state.user.data.client.totalTimeWeightedReturnPercent);
  const { plans } = useSelector((state) => state.plans);

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y + window.innerHeight >= document.body.scrollHeight - 10) {
        setExtended(true);
      } else {
        setExtended(false);
      }
    },
    [],
    null,
    true,
    300
  );

  const timeSpans = [
    { name: t('allTime') },
    { name: t('helpers.monthWithCount', { count: 6 }) },
    { name: t('helpers.monthWithCount', { count: 3 }) },
  ];
  const totalGrowth = plans?.reduce((acc, curr) => acc + curr.totalGrowth, 0);
  const totalValue = plans?.reduce((acc, curr) => acc + curr.value, 0);

  /* const handleOnClick = (data, index) => {
    setSelectedData(index);
  }; */

  useEffect(() => {
    setExtended(window.scrollY + window.innerHeight >= document.body.scrollHeight - 10);
  }, []);

  return (
    <Grid container className={classes.root}>
      <PlanPerformanceCard
        subtitle={timeSpans[selectedData].name}
        totalGrowth={totalGrowth}
        totalValue={totalValue}
        performancePercent={totalTimeWeightedReturnPercent}
        className={classes.performanceCard}
      />
      {/*<ButtonGroup buttons={timeSpans} handleOnClick={handleOnClick} selectedButton={selectedData} />*/}
      <Grid item xs={12} className={classes.planHeader}>
        {t('yourPlans')}
      </Grid>
      <Grid item xs={12} className={classes.plansContainer}>
        {plans?.map((plan) => (
          <PlanCard key={plan.id} plan={plan} />
        ))}
      </Grid>

      <DelayLinkFab
        to="/plans/create"
        color="primary"
        linkClassName={classNames(classes.createFabWrapper, { [classes.createFabWrapperExtended]: extended })}
        className={classes.createFab}
        size="small"
        variant="expanded"
      >
        <PlusIcon className={classes.plusIcon} />
        <span className="create-plan-fab-label">{t('createNewPlan')}</span>
      </DelayLinkFab>
    </Grid>
  );
};

export default PlansIndex;
