import React from 'react';
import { allCountries } from 'country-telephone-data';
import PropTypes from 'prop-types';
import { Typography, FormControl, Grid, Select, makeStyles, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/ArrowDown.svg';
import { DelayButton, DelayIconButton } from 'components/DelayButtons';
import useTransition from 'hooks/useTransition';
import Transition from 'components/Transition';
import { useSelector } from 'react-redux';
import { ReactComponent as ArrowBack } from 'assets/icons/PointerBack.svg';
import useDelayHistory from 'hooks/useDelayHistory';
import { useSetAppSafeArea } from 'hooks/useSetAppSafeArea';
import { useSetAppNotchColor } from 'hooks/useSetAppNotchColor';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.accent.pinkLady,
    color: theme.palette.secondary.contrastText,
    padding: 20,
    paddingTop: '18vh',
    margin: 0,
    width: '100%',
  },
  header: {
    marginBottom: 50,
  },
  countrySelect: {
    '& select': {
      color: `${theme.palette.accent.rockBlu} !important`,
    },
    '& option': {
      color: 'black',
    },
    '& ::before': {
      borderColor: `${theme.palette.accent.rockBlu} !important`,
    },
    '& ::after': {
      borderColor: `${theme.palette.accent.rockBlu} !important`,
    },
  },
  languageSelect: {
    textAlignLast: 'center',
    '& path': {
      stroke: '#DDDDDD',
    },
  },
  backButton: {
    position: 'absolute',
    height: 50,
    top: 0,
    left: 10,
  },
}));

const GetStarted = ({ handleNext, userData, updateUserData }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { showTransition, delay, handleHideAnimation } = useTransition();
  const classes = useStyles();
  const countries = useSelector((state) => state.misc.countries);
  const delayHistory = useDelayHistory();
  useSetAppSafeArea(true);
  useSetAppNotchColor(theme.palette.accent.pinkLady, '#FFFFFF');

  /* const languages = i18n.options.supportedLngs.filter((lang) => lang !== 'cimode');
  const handleLanguageChange = async (ev) => {
    await i18n.changeLanguage(ev.target.value);
  }; */

  const handleUpdateCountry = (ev) => {
    const countryCode = ev.target.value;
    const { dialCode } = allCountries.find((c) => c.iso2.toLowerCase() === countryCode.toLowerCase());
    if (['us', 'um'].includes(countryCode)) {
      // eslint-disable-next-line no-alert
      window.alert('Thanks but we can not accept US customers at this time');
    } else {
      updateUserData({
        ...userData,
        country: countryCode,
        dialCode: `+${dialCode}`,
      });
    }
  };

  const handleNextStep = () => {
    handleHideAnimation(handleNext);
  };

  const handleBack = () => {
    if (delayHistory.action === 'PUSH') {
      delayHistory.goBack();
    } else {
      delayHistory.replace('/');
    }
  };

  return (
    <Transition show={showTransition} delay={delay}>
      <Grid container className={classes.root}>
        <DelayIconButton className={classes.backButton} onClick={handleBack}>
          <ArrowBack />
        </DelayIconButton>
        <Grid item container direction="column">
          <Grid item className={classes.header}>
            <Typography variant="h1" gutterBottom>
              {t('getting-started.title')}
            </Typography>
            <Typography variant="body1" color="primary">
              {t('getting-started.subtitle')}
            </Typography>
          </Grid>

          <Grid item container spacing={2} justify="center" direction="column">
            <Grid item>
              <Typography variant="body1">{t('countryOfResidence')}</Typography>
              <FormControl className={classes.countrySelect} fullWidth>
                <Select
                  native
                  inputProps={{
                    name: 'country',
                  }}
                  value={userData.country}
                  onChange={handleUpdateCountry}
                  IconComponent={ArrowDownIcon}
                >
                  {countries.map((country) => (
                    <option key={`country-${country.id}`} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <DelayButton onClick={handleNextStep}>{t('getStarted')}</DelayButton>
            </Grid>
            {/* <Grid item>
              <FormControl fullWidth className={classes.languageSelect} variant="filled" color="secondary">
                <Select
                  onChange={handleLanguageChange}
                  native
                  inputProps={{
                    name: 'language',
                  }}
                  value={i18n.language}
                  disableUnderline
                  IconComponent={ArrowDownIcon}
                >
                  {languages.map((lang) => (
                    <option key={`language-${lang}`} value={lang}>
                      {t(`languages.${lang}`)}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Transition>
  );
};

GetStarted.propTypes = {
  handleNext: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  updateUserData: PropTypes.func.isRequired,
};

export default GetStarted;
