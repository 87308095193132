import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import { ReactComponent as TopupIcon } from 'assets/icons/TopupIcon.svg';
import { ReactComponent as WithdrawIcon } from 'assets/icons/WithdrawIcon.svg';
import { formatISODateString, formatPrice } from 'utils/helpers';

const useStyles = makeStyles(() => ({
  listItem: {
    backgroundColor: 'white',
  },
  primaryText: {
    fontSize: 14,
  },
  secondaryText: {
    fontSize: 12,
  },
  rejectionComment: {
    whiteSpace: 'pre',
  },
}));

const Activity = ({ plan }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const allTransactions = [
    ...(plan?.paymentTransactions ?? []).map((t) => ({ ...t, transactionType: 'payment' })),
    ...(plan?.planTransactions ?? []).map((t) => ({ ...t, transactionType: 'plan' })),
    ...(plan?.managementFees ?? []).map((t) => ({ ...t, transactionType: 'fee' })),
  ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

  const statusMessage = (status, type) => {
    switch (status) {
      case 'Pending':
        return t(`user.plans.activity.${type?.toLowerCase()}-pending-status`);
      case 'Failed':
        return t('user.plans.activity.failed-status');
      case 'Completed':
        return t(`user.plans.activity.${type?.toLowerCase()}-completed-status`);
      case 'Cancelled':
        return t('user.plans.activity.cancelled-status');
      case 'WaitingForOrder':
        return t(`user.plans.activity.${type?.toLowerCase()}-waiting-for-order-status`);
      case 'WaitingForOrderFulfillment':
        return t(`user.plans.activity.${type?.toLowerCase()}-waiting-for-order-fulfillment-status`);
      case 'Fulfilled':
        return t(`user.plans.activity.${type?.toLowerCase()}-fulfilled-status`);
      default:
        return '';
    }
  };

  const amount = (transaction) => {
    if (transaction.method === 'WireTransfer' && transaction.status !== 'Completed') {
      return formatPrice(transaction.wirePromisedAmount, transaction.wirePromisedAmountCurrency?.code);
    }
    if (transaction.transactionType === 'plan') {
      return formatPrice(transaction.amount ?? transaction.requestedAmount ?? 0, plan.portfolio?.currency?.code);
    }
    if (transaction.transactionType === 'fee') {
      return formatPrice(transaction.amount, plan.portfolio?.currency?.code);
    }
    return formatPrice(transaction.amount, transaction.currencyCode);
  };

  if (allTransactions.length === 0) return t('no-activity');
  return (
    <List>
      {allTransactions.map((transaction) => {
        let method = transaction.method,
          createdAt = transaction.createdAt,
          completedAt = transaction.completedAt,
          type = transaction.transactionType === 'fee' ? 'Fee' : transaction.type,
          status = transaction.status;
        return (
          <ListItem key={transaction.id} divider className={classes.listItem}>
            <ListItemAvatar>
              {['Deposit', 'Buy'].includes(transaction.type) ? <TopupIcon /> : <WithdrawIcon />}
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{
                className: classes.primaryText,
              }}
              primary={`${t(`transaction-types.${type}`)} (${amount(transaction)})`}
              secondaryTypographyProps={{
                component: 'div',
                className: classes.secondaryText,
              }}
              secondary={
                <>
                  {method && (
                    <div>
                      {t('method')}: {t(`transaction-methods.${method}`)}
                    </div>
                  )}
                  {createdAt && (
                    <div>
                      {t('created')}: {formatISODateString(transaction.createdAt)}
                    </div>
                  )}
                  {completedAt && (
                    <div>
                      {t('completed')}: {formatISODateString(transaction.completedAt)}
                    </div>
                  )}
                  {status && (
                    <div>
                      {t('status')}: {statusMessage(status, transaction.type)}
                    </div>
                  )}
                  {status === 'Failed' && transaction.wireRejectionComment && (
                    <div>
                      {t('rejection-comment')}:{' '}
                      <div className={classes.rejectionComment}>{transaction.wireRejectionComment}</div>
                    </div>
                  )}
                </>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};

Activity.propTypes = {
  plan: PropTypes.object.isRequired,
};

export default Activity;
