import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import RegisterTopBar from 'components/Layout/RegisterTopBar';
import { REGISTER_TOP_BAR_HEIGHT } from 'utils/constants';
import { useUpdateUser } from 'store/reducers/user';
import LoaderWrapper from 'components/LoaderWrapper';
import useAsyncFn from 'hooks/useAsyncFn';
import { resendEmailConfirmationAsync } from 'transfer/accountApi';
import { redirectUser } from 'utils/helpers';
import { useSetAppSafeArea } from 'hooks/useSetAppSafeArea';
import { useSetAppNotchColor } from 'hooks/useSetAppNotchColor';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: REGISTER_TOP_BAR_HEIGHT,
    height: '100vh',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.accent.pinkLady,
  },
  content: {
    padding: 20,
    paddingTop: '6vh',
    flexGrow: 1,
    overflowX: 'auto',
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: 20,
  },
  body: {
    marginBottom: 20,
  },
  error: {
    marginBottom: 10,
  },
  resendContainer: {
    marginTop: 50,
  },
  textButtonWrapper: {
    width: 'unset',
    display: 'inline-block',
  },
  textButton: {
    ...theme.typography.body1,
    paddingLeft: 0,
    minHeight: 'unset',
  },
  list: {
    margin: 0,
    padding: '10px 20px',
    '& li': {
      marginBottom: 20,
    },
  },
  footer: {
    padding: '16px 20px',
    boxShadow: theme.shadows[4],
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  },
}));

const ConfirmEmail = () => {
  const userData = useSelector((state) => state.user.data);
  const history = useHistory();
  useSetAppSafeArea(true);
  useSetAppNotchColor('#FFFFFF');
  const [newUserData, setNewUserData] = useState();
  const [emailAlreadyConfirmed, setEmailAlreadyConfirmed] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [{ loading }, updateUser] = useUpdateUser();
  const [{ loading: resendingEmail }, resendEmailConfirmation] = useAsyncFn(resendEmailConfirmationAsync);
  const [resendDisabledTimeLeft, setResendDisabledTimeLeft] = useState(0);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleNext = async () => {
    const user = await updateUser();
    if (user.emailConfirmed) {
      redirectUser(history.push, user);
    } else {
      setNewUserData(user);
      setEmailAlreadyConfirmed(false);
    }
  };

  const handleResendingEmailConfirmation = async () => {
    try {
      await resendEmailConfirmation(userData.id);
      setResendDisabledTimeLeft(30);
    } catch (error) {
      // Email has already been confirmed so we show a different message.
      if (error.response?.data?.type === 'invalid_operation') {
        setEmailAlreadyConfirmed(true);
        handleNext();
      }
    }
  };

  useEffect(() => {
    if (resendDisabledTimeLeft > 0) {
      setTimeout(() => {
        setResendDisabledTimeLeft((prev) => prev - 1);
      }, 1000);
    }
  }, [resendDisabledTimeLeft]);

  useEffect(() => {
    redirectUser(history.push, userData);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      setShowLoader(false);
      const user = await updateUser();
      setShowLoader(true);
      if (user.emailConfirmed) {
        redirectUser(history.push, user);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [updateUser, history.push]);

  return (
    <Grid container className={classes.root} direction="column" wrap="nowrap">
      <RegisterTopBar title={t('confirmEmail')} hideBackBtn progressValue={20} />
      <div className={classes.content}>
        <Typography variant="h2" className={classes.title}>
          {t('register-confirm-email.title')}
        </Typography>
        <Typography variant="body1" className={classes.body}>
          {t('register-confirm-email.body1', { email: userData.email })}
        </Typography>
        <Trans i18nKey="register-confirm-email.list" components={{ li: <li />, ol: <ol className={classes.list} /> }} />
        <Typography variant="body1" className={classes.body}>
          <Trans i18nKey="register-confirm-email.body2" />
        </Typography>

        {!emailAlreadyConfirmed && (
          <div className={classes.resendContainer}>
            <Grid container alignItems="center">
              <Typography variant="body1">
                {t('register-confirm-email.didnt-receive-confirm-email')}
                &nbsp;
              </Typography>
              <LoaderWrapper loading={resendingEmail} className={classes.textButtonWrapper}>
                <Button
                  variant="text"
                  fullWidth={false}
                  className={classes.textButton}
                  disabled={resendingEmail || resendDisabledTimeLeft > 0}
                  onClick={handleResendingEmailConfirmation}
                >
                  {t('resendEmail')}
                </Button>
              </LoaderWrapper>
            </Grid>
            {resendDisabledTimeLeft > 0 && (
              <Typography>
                {t('register-confirm-email.resend-timeout-text', {
                  secondsLeft: resendDisabledTimeLeft,
                })}
              </Typography>
            )}
          </div>
        )}
      </div>
      <Grid item container alignItems="flex-end" justify="center" direction="column" className={classes.footer}>
        <Typography className={classes.error}>
          {newUserData && !newUserData.emailConfirmed
            ? t('register-confirm-email.error')
            : emailAlreadyConfirmed
            ? t('register-confirm-email.already-confirmed')
            : t('register-confirm-email.continue-button-description')}
        </Typography>
        <LoaderWrapper loading={loading}>
          <Button onClick={handleNext} disabled={loading && showLoader}>
            {t('continue')}
          </Button>
        </LoaderWrapper>
      </Grid>
    </Grid>
  );
};

export default ConfirmEmail;
