export const portfolios = ['cautious', 'tentative', 'confident', 'ambitious', 'adventurous'];
export const accountTypes = ['regular', 'sukuk', 'customized'];

export const generatePortfoliosMap = (theme) => ({
  cautious: {
    color: theme.palette.accent.seaMist,
  },
  tentative: {
    color: theme.palette.accent.pinkLady,
  },
  confident: {
    color: theme.palette.accent.regentBlue,
  },
  ambitious: {
    color: theme.palette.accent.melanie,
  },
  adventurous: {
    color: theme.palette.accent.gainsboro,
  },
  sukuk: {
    color: theme.palette.accent.pinkLady,
  },
  Cautious: {
    color: theme.palette.accent.seaMist,
  },
  Tentative: {
    color: theme.palette.accent.pinkLady,
  },
  Confident: {
    color: theme.palette.accent.regentBlue,
  },
  Ambitious: {
    color: theme.palette.accent.melanie,
  },
  Adventurous: {
    color: theme.palette.accent.gainsboro,
  },
  Sukuk: {
    color: theme.palette.accent.pinkLady,
  },
});
