import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import { refreshCookieAsync } from 'transfer/accountApi';
import { useAuthenticateUser } from 'store/reducers/user';
import { redirectUser } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    padding: 30,
    paddingTop: '12vh',
    backgroundColor: theme.palette.accent.pinkLady,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& > div:first-child': {
      flexGrow: 1,
    },
    '& button': {
      fontWeight: 500,
    },
  },
  title: {
    marginBottom: 30,
  },
  button: {
    marginTop: 20,
  },
  timelineRoot: {
    '& > li:before': {
      content: 'unset',
    },
  },
  timelineConnectorDone: {
    backgroundColor: theme.palette.primary.main,
  },
  timelineContent: {
    paddingTop: 4,
    paddingBottom: 20,
  },
  timelineContentTitle: {
    fontSize: 18,
    lineHeight: '20px',
    fontWeight: 'bold',
  },
  timelineConnectorIncomplete: {
    backgroundColor: '#777',
  },
  timelineDotIncomplete: {
    borderColor: '#777',
  },
  timelineContentTitleIncomplete: {
    color: '#777',
  },
  pendingApprovalTitle: {
    margin: '5px 0',
  },
}));

const Finished = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [, authenticate] = useAuthenticateUser();
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(async () => {
      await refreshCookieAsync();
      const user = await authenticate();
      if (user.roles.includes('VerifiedClient')) {
        redirectUser(history.push, user);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [authenticate, history.push]);

  /**
   * Needed to trigger an initial refresh. Due to the structure in parent component this component
   * is mounted twice therefore the timeout is there to only trigger one initial request.
   */
  useEffect(() => {
    const timer = setTimeout(async () => {
      await refreshCookieAsync();
      const user = await authenticate();
      if (user.roles.includes('VerifiedClient')) {
        redirectUser(history.push, user);
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [authenticate, history.push]);

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h3" className={classes.title}>
          {t('register-final.finished.title')}
        </Typography>
        <Timeline className={classes.timelineRoot}>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot variant="default" color="primary" />
              <TimelineConnector className={classes.timelineConnectorDone} />
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContent}>
              <Typography variant="h4" className={classes.timelineContentTitle}>
                {t('accountSetup')}
              </Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary" />
              <TimelineConnector className={classes.timelineConnectorDone} />
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContent}>
              <Typography variant="h4" className={classes.timelineContentTitle}>
                {t('planCreated')}
              </Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot variant="outlined" color="primary" />
              <TimelineConnector className={classes.timelineConnectorIncomplete} />
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContent}>
              <Typography variant="h4" className={classes.timelineContentTitle}>
                {t('identityVerification')}
              </Typography>
              <Typography color="primary">
                <div className={classes.pendingApprovalTitle}>{t('pendingApproval')}</div>
                <i>{t('register-final.finished.pending-approval-message')}</i>
              </Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot variant="outlined" className={classes.timelineDotIncomplete} />
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContent}>
              <Typography
                variant="h4"
                className={classNames(classes.timelineContentTitle, classes.timelineContentTitleIncomplete)}
              >
                {t('fundYourPlan')}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </div>
      <Typography align="center" variant="h5">
        {t('register-final.finished.close-app-text')}
      </Typography>
    </div>
  );
};

export default Finished;
