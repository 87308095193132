import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Onfido from 'components/Onfido';

const DocumentVerification = ({ handleNext, handleBack }) => {
  const countryOfResidence = useSelector((state) => state.user.data?.client?.address?.country?.isO3);
  return (
    <Onfido
      handleNext={handleNext}
      handleBack={handleBack}
      steps={{ poa: true, document: true, face: true }}
      country={countryOfResidence}
    />
  );
};

DocumentVerification.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default DocumentVerification;
