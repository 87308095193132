export default {
  root: {
    '&.Mui-expanded': {
      margin: '0 0 10px',
    },
    '&:before': {
      height: 0,
    },
  },
};
