export default {
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: 8,
  },
  colorPrimary: {
    color: '#298070',
    '&$checked': {
      color: 'white',
    },
  },
  switchBase: {
    padding: 1,
    color: 'rgb(189,189,189)',
    '&$checked': {
      transform: 'translateX(16px)',
      color: 'white',
      '& + $track': {
        backgroundColor: '#1E6557',
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    position: 'relative',
    top: 2,
    left: 2,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid #BDBDBD`,
    backgroundColor: '#FAFAFA',
    opacity: 1,
    transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  checked: {},
};
