import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Button, TextField, Typography, makeStyles, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useSelector } from 'react-redux';

import { useLoginUser } from 'store/reducers/user';
import LoaderWrapper from 'components/LoaderWrapper';
import { getCookie, redirectUser } from 'utils/helpers';
import { DelayLinkButton } from 'components/DelayLinkButtons';
import { ReactComponent as CocoaLogo } from 'assets/icons/CocoaLogo.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import { forgotPasswordAsync } from 'transfer/accountApi';
import { useSetAppSafeArea } from 'hooks/useSetAppSafeArea';
import { APP_COOKIES } from 'utils/constants';

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    width: '100%',
    background: 'url("/assets/images/home-image.png") no-repeat, linear-gradient(-1deg, #FFFFFF, #298070)',
    backgroundSize: 'cover',
    backgroundBlendMode: 'multiply',
    minHeight: '100vh',
  },
  container: {
    display: 'flex',
    minHeight: '100vh',
    padding: 20,
  },
  logo: {
    height: 100,
    marginBottom: 50,
  },
  underline: {
    '&:before, &:after': {
      borderColor: 'white !important',
    },
  },
  forgotBtn: {
    minHeight: 'unset',
    fontSize: 14,
  },
  findOutMoreBtn: {
    color: 'white',
    textShadow: '0px 0px 2px black',
    '& svg': {
      filter: 'drop-shadow( 0px 0px 1px rgba(0, 0, 0, .9))',
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const [helperText, setHelperText] = useState(null);
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [{ loading: loadingLoginUser }, loginUser] = useLoginUser();
  const { authenticated, data: userData } = useSelector((state) => state.user);
  useSetAppSafeArea(false);
  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email(t('form-validations.email')).required(t('form-validations.required')),
        password: yup.string().required(t('form-validations.required')),
      }),
    [t]
  );
  const { register, errors, setError, watch, clearErrors, handleSubmit } = useForm({
    defaultValues: {
      email: getCookie(APP_COOKIES.EMAIL) ?? '',
      password: '',
    },
    resolver: yupResolver(schema),
  });
  const email = watch('email');
  const loading = loadingLoginUser || submitting;

  const onSubmit = async (data) => {
    setHelperText(null);
    try {
      const userData = await loginUser(data.email, data.password);
      redirectUser(history.push, userData);
    } catch {
      setHelperText(t('error-messages.incorrectLogin'));
    }
  };

  const handleForgotPassword = async () => {
    clearErrors();
    setHelperText(null);
    setSubmitting(true);
    try {
      await yup
        .object()
        .shape({
          email: yup.string().email(t('form-validations.email')).required(t('form-validations.required')),
        })
        .validate({ email });
      const emailSent = await forgotPasswordAsync(email);
      if (emailSent) {
        setHelperText(t('success-messages.forgotPassword'));
      } else {
        setHelperText(t('error-messages.forgotPassword'));
      }
    } catch (error) {
      setError('email', {
        type: 'manual',
        message: error.message,
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (authenticated && userData) {
      redirectUser(history.push, userData);
    }
  }, [authenticated, history.push, userData]);

  return (
    <Grid container className={classes.root} justify="center" alignItems="center">
      <Container
        component={Grid}
        maxWidth="sm"
        className={classes.container}
        container
        direction="column"
        justify="space-between"
        wrap="nowrap"
        alignItems="center"
      >
        <Grid item>
          <CocoaLogo className={classes.logo} />
        </Grid>
        <Grid item container style={{ flexGrow: 1 }} alignItems="center" justify="center">
          <Grid container spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12}>
              <TextField
                type="email"
                name="email"
                inputRef={register}
                label={t('email')}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                disabled={loading}
                color="secondary"
                InputProps={{ classes: { underline: classes.underline } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                name="password"
                inputRef={register}
                label={t('password')}
                color="secondary"
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                disabled={loading}
                InputProps={{
                  classes: {
                    underline: classes.underline,
                  },
                  endAdornment: (
                    <Button
                      variant="text"
                      color="secondary"
                      fullWidth={false}
                      className={classes.forgotBtn}
                      disabled={loading}
                      onClick={handleForgotPassword}
                    >
                      {t('forgot?')}
                    </Button>
                  ),
                }}
              />
            </Grid>
            {helperText && (
              <Grid item xs={12}>
                <Typography color="secondary">{helperText}</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <LoaderWrapper loading={loading} color="secondary">
                <Button color="primary" fullWidth type="submit" disabled={loading}>
                  {t('login')}
                </Button>
              </LoaderWrapper>
            </Grid>
            <Grid item xs={12}>
              <DelayLinkButton color="secondary" fullWidth to="/register" disabled={loading}>
                {t('openAccount')}
              </DelayLinkButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <DelayLinkButton
            variant="text"
            fullWidth
            to="/find-out-more"
            disabled={loading}
            className={classes.findOutMoreBtn}
            endIcon={<ArrowRightIcon />}
          >
            {t('find-out-more')}
          </DelayLinkButton>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Home;
