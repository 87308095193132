import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Can be used to set the safe area of the app wrapper
export function useSetAppSafeArea(showSafeArea, toggleOnUnmount = false) {
  const isWebViewApp = useSelector((state) => state.misc.isWebViewApp);
  useEffect(() => {
    if (isWebViewApp) {
      window.flutter_inappwebview.callHandler('safe_area', `${showSafeArea}`);
    }
    return () => {
      if (toggleOnUnmount && isWebViewApp) {
        window.flutter_inappwebview.callHandler('safe_area', `${!showSafeArea}`);
      }
    };
  }, [showSafeArea, toggleOnUnmount, isWebViewApp]);
}
