import React from 'react';
import PropTypes from 'prop-types';
import { Grid, List, ListItem, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import { CustomDelayButton } from 'components/DelayButtons';
import CustomButton from 'components/UI/CustomButton';
import { useSelector } from 'react-redux';
import { formatPrice } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 40px - 60px)',
    paddingTop: '12vh',
    paddingBottom: 10,
    backgroundColor: theme.palette.primary.main,
  },
  header: {
    margin: '0 20px',
  },
  title: {
    marginBottom: 15,
    fontWeight: 300,
  },
  body: {
    fontWeight: 300,
    marginBottom: 30,
  },
  list: {
    padding: 0,
    background: 'white',
    '& li': {
      height: 50,
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  button: {
    marginTop: 10,
    borderRadius: 0,
    height: 50,
    textTransform: 'uppercase',
  },
  icon: {
    height: 13,
    '& path': {
      fill: '#000',
      stroke: '#000',
    },
  },
}));

const Summary = ({ data, handleNext, plan }) => {
  const classes = useStyles();
  const { bankAccount } = useSelector((state) => state.user.data.client);
  const { t } = useTranslation();
  return (
    <Grid container className={classes.root} direction="column" justify="space-between">
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.header}>
            <Typography variant="h3" align="center" color="secondary" className={classes.title}>
              {t('user.transfer.withdraw.create.summary.title')}
            </Typography>
            <Typography variant="body1" align="center" color="secondary" className={classes.body}>
              {t('user.transfer.withdraw.create.summary.body', {
                value: formatPrice(data.amount, 'USD'),
                planName: plan.name.toUpperCase(),
              })}
            </Typography>
          </div>
          <List className={classes.list}>
            <ListItem divider>
              <div>{t('accountNumber')}</div>
              <div>{bankAccount.accountNumber}</div>
            </ListItem>
            <ListItem divider>
              <div>{t('swiftCode')}</div>
              <div>{bankAccount.swiftCode}</div>
            </ListItem>
          </List>
        </Grid>
      </Grid>

      <Grid item container>
        <Grid item xs={6}>
          <CustomDelayButton onClick={handleNext}>
            {({ onClick }) => (
              <CustomButton
                bgcolor="melanie"
                onClick={onClick}
                className={classes.button}
                endIcon={<ArrowRightIcon className={classes.icon} />}
              >
                {t('continue')}
              </CustomButton>
            )}
          </CustomDelayButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

Summary.propTypes = {
  data: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  plan: PropTypes.object.isRequired,
};

export default Summary;
