import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import DonutCard from 'components/Cards/DonutCard';
import { useTranslation } from 'react-i18next';

import StockAccordion from 'components/StockAccordion';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import { useFetchPortfolio } from 'store/reducers/misc';
import { useSelector } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 20,
  },
  selectPortfolioButton: {
    margin: 20,
  },
  tabs: {
    marginTop: -15,
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%)',
  },
}));

const PortfolioDetails = ({ data, updateTitle, handleNext, selectPortfolio }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  // Can be either assetClassBreakdown, sectionalBreakdown or regionalBreakdown.
  const [selectedBreakdown, setSelectedBreakdown] = useState('assetClassBreakdown');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const portfolios = useSelector((state) => state.misc.portfolios);
  const [portfolio, setPortfolio] = useState();

  const selectedPortfolio = data.accountType === 'sukuk' ? 'sukuk' : data.selectedPortfolio;
  const recommendedPortfolio = data.accountType === 'sukuk' ? 'sukuk' : data.recommendedPortfolio;

  useEffect(() => {
    updateTitle(t('portfolioDetails'));
    return () => updateTitle('');
  }, [updateTitle, t]);

  const [, fetchPortfolio] = useFetchPortfolio();
  useEffect(() => {
    const portfolioId = portfolios.find((p) => p.name?.toLowerCase() === data.selectedPortfolio?.toLowerCase())?.id;
    fetchPortfolio(portfolioId).then(setPortfolio);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scroll({ top: 0, left: 0, behavior: 'auto' });
    }, 0);
  }, []);

  const handleSelectPortfolio = () => {
    if (selectedPortfolio !== recommendedPortfolio) {
      setShowConfirmDialog(true);
    } else {
      selectPortfolio();
    }
  };

  const formatAssetData = (port, breakdown) => {
    if (!port || !port[breakdown] || !port?.assets) return;
    return Object.entries(port[breakdown]).map(([key, fraction]) => ({
      percentage: fraction * 100,
      label: breakdown === 'assetClassBreakdown' ? t(`asset-class-enum.${key}`) : key,
      assets: breakdown === 'assetClassBreakdown' ? port.assets.filter((a) => a.assetClass === key) : [],
    }));
  };

  if (!portfolio) return null;

  return (
    <div className={classes.root}>
      <ConfirmDialog
        onConfirm={() => {
          setShowConfirmDialog(false);
          selectPortfolio();
        }}
        open={showConfirmDialog}
        onCancel={() => setShowConfirmDialog(false)}
        message={t('dialog-messages.confirmSelectNonRecommendedPortfolio')}
      />
      <DonutCard
        key={`chart-${selectedBreakdown}`}
        data={Object.entries(portfolio[selectedBreakdown] ?? {}).map(([key, value]) => ({
          label: key,
          y: value,
        }))}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        title={t(`portfolios.${selectedPortfolio}`)}
        labelTitle={
          typeof selectedIndex === 'number' &&
          `${Number.parseFloat(Object.values(portfolio[selectedBreakdown] ?? {})[selectedIndex] * 100).toFixed(2)}%`
        }
        labelSubtitle={
          typeof selectedIndex === 'number' && Object.keys(portfolio[selectedBreakdown] ?? {})[selectedIndex]
        }
      />
      <AppBar color="primary" position="relative" className={classes.tabs}>
        <Tabs
          variant="fullWidth"
          value={selectedBreakdown}
          onChange={(e, newValue) => {
            setSelectedIndex(0);
            setSelectedBreakdown(newValue);
          }}
        >
          <Tab label={t('asset-class')} value="assetClassBreakdown" />
          <Tab label={t('sector')} value="sectionalBreakdown" />
          <Tab label={t('region')} value="regionalBreakdown" />
        </Tabs>
      </AppBar>
      <StockAccordion
        stockData={formatAssetData(portfolio, selectedBreakdown)}
        setSelectedAccordion={setSelectedIndex}
        selectedAccordion={selectedIndex}
        handleStockClick={(asset) => handleNext(null, 'stock-details', { asset })}
      />
      <div className={classes.selectPortfolioButton}>
        <Button onClick={handleSelectPortfolio}>{t('plan.portfolio.selectPortfolio')}</Button>
      </div>
    </div>
  );
};

PortfolioDetails.propTypes = {
  data: PropTypes.object.isRequired,
  updateTitle: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  selectPortfolio: PropTypes.func.isRequired,
};

export default PortfolioDetails;
