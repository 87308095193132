import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Grid, TextField, Typography, makeStyles, InputAdornment } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import Select from '@material-ui/core/Select';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import CustomButton from 'components/UI/CustomButton';
import LoaderWrapper from 'components/LoaderWrapper';
import { formatPrice, getCurrencySymbol } from 'utils/helpers';
import { useCreatePlanTransaction } from 'store/reducers/plans';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 40px - 60px)',
    paddingTop: '12vh',
    backgroundColor: theme.palette.primary.main,
  },
  header: {
    margin: '0 20px',
  },
  title: {
    lineHeight: '29px',
    marginBottom: 15,
    fontWeight: 300,
  },
  body: {
    fontWeight: 300,
    marginBottom: 30,
  },
  inputContainer: {
    position: 'relative',
    backgroundColor: 'white',
    height: 80,
  },
  errorMsg: {
    width: '100%',
    padding: '1px 5px 0',
    textAlign: 'center',
    position: 'absolute',
  },
  textField: {
    height: '100%',
  },
  input: {
    paddingLeft: 20,
    height: '100%',
    fontSize: 25,
    fontWeight: 300,
  },
  selectInputContainer: {
    position: 'relative',
    backgroundColor: 'white',
    height: 40,
  },
  selectInput: {
    flexGrow: 1,
    height: '100%',
    '& > div': {
      height: '100%',
    },
    '& select': {
      height: '100%',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 20,
    },
  },
  buttonWrapper: {
    marginTop: -15,
  },
  button: {
    borderRadius: 0,
    height: 50,
    textTransform: 'uppercase',
  },
  buttonDisabled: {
    backgroundColor: `${theme.palette.accent.melanie} !important`,
  },
  icon: {
    height: 13,
    '& path': {
      fill: '#000',
      stroke: '#000',
    },
  },
}));

const SetAmount = ({ data, updateData, handleNext }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { detailedCurrencies } = useSelector((state) => state.misc);
  const { planId } = useParams();
  const [, createPlanTransaction] = useCreatePlanTransaction();

  const schema = useCallback(
    (data) =>
      yup.object().shape({
        amount: yup
          .number()
          .min(
            detailedCurrencies.find((c) => c.code === data?.currency)?.minimumDepositAmount ?? 0,
            t('form-validations.greaterThanValue', {
              value: formatPrice(
                detailedCurrencies.find((c) => c.code === data?.currency)?.minimumDepositAmount,
                data?.currency
              ),
            })
          )
          .max(
            detailedCurrencies.find((c) => c.code === data?.currency)?.maximumDepositAmount ?? Infinity,
            t('form-validations.maximumTransactionLimit', {
              value: formatPrice(
                detailedCurrencies.find((c) => c.code === data?.currency)?.maximumDepositAmount,
                data?.currency
              ),
            })
          )
          .required(t('form-validations.required'))
          .typeError(t('form-validations.required')),
      }),
    [detailedCurrencies, t]
  );

  const { register, handleSubmit, errors, watch, formState, trigger } = useForm({
    defaultValues: {
      amount: data.amount,
      currency: data.currency,
    },
    resolver: async (data) => yupResolver(schema(data))(data),
  });
  const isSubmitting = formState.isSubmitting;
  const currency = watch('currency');

  useEffect(() => {
    trigger();
  }, [currency, trigger]);

  const onSubmit = async (newData) => {
    if (isSubmitting) return;
    updateData({
      ...data,
      ...newData,
    });
    handleNext();
  };

  return (
    <Grid container className={classes.root} direction="column" component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12} className={classes.header}>
        <Typography variant="h3" align="center" color="secondary" className={classes.title}>
          {t('user.transfer.top-up.create.set-amount.title')}
        </Typography>
        <Typography variant="body1" align="center" color="secondary" className={classes.body}>
          {t('user.transfer.top-up.create.set-amount.body', {
            value: formatPrice(
              detailedCurrencies.find((c) => c.code === currency)?.minimumDepositAmount ?? 0,
              currency
            ),
          })}
        </Typography>
      </Grid>
      <Grid item container>
        <Grid item xs={8} className={classes.selectInputContainer}>
          <FormControl fullWidth className={classes.selectInput}>
            <Select
              native
              inputProps={{
                name: 'currency',
              }}
              inputRef={register}
              disableUnderline
            >
              {detailedCurrencies.map((currency) => (
                <option key={currency.code} value={currency.code}>
                  {currency.code}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={8} className={classes.inputContainer}>
          <Typography color="error" className={classes.errorMsg} variant="body2">
            {errors.amount?.message}
          </Typography>
          <TextField
            variant="standard"
            inputRef={register}
            name="amount"
            error={Boolean(errors.amount)}
            classes={{
              root: classes.textField,
            }}
            InputProps={{
              classes: {
                root: classes.input,
              },
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start" disablePointerEvents disableTypography>
                  {getCurrencySymbol(currency)}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={4} />
        <Grid item xs={6}>
          <LoaderWrapper loading={isSubmitting} className={classes.buttonWrapper}>
            <CustomButton
              bgcolor="melanie"
              type="submit"
              className={classes.button}
              classes={{
                disabled: classes.buttonDisabled,
              }}
              disabled={isSubmitting}
              endIcon={<ArrowRightIcon className={classes.icon} />}
            >
              {t('continue')}
            </CustomButton>
          </LoaderWrapper>
        </Grid>
      </Grid>
    </Grid>
  );
};

SetAmount.propTypes = {
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
};

export default SetAmount;
