import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Divider, Button, makeStyles } from '@material-ui/core';
import { ReactComponent as AddressProofIcon } from 'assets/icons/AddressProof.svg';
import { ReactComponent as FaceCaptureIcon } from 'assets/icons/FaceCapture.svg';
import { ReactComponent as IdentityDocumentIcon } from 'assets/icons/IdentityDocument.svg';
import Onfido from 'components/Onfido';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    width: '100%',
    padding: 20,
  },
  subtitle: {
    marginBottom: 15,
  },
  itemTitle: {
    marginBottom: 5,
  },
  item: {
    width: '100%',
    height: '0',
    paddingBottom: '100%',
    border: `1px solid ${theme.palette.accent.red}`,
    color: theme.palette.accent.red,
    position: 'relative',
    '& > div:first-child': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      '& > span': {
        marginTop: 10,
      },
    },
  },
  itemProcessing: {
    color: theme.palette.accent.orange,
    borderColor: theme.palette.accent.orange,
  },
  divider: {
    margin: '20px 0',
    backgroundColor: theme.palette.accent.silver,
  },
  button: {
    marginTop: 40,
  },
}));

const DocumentsCheck = ({ handleNext, updateTitle, documents, step, country }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { view } = useParams();
  const identityDocuments = documents.filter((d) => d.category === 'identity');
  const addressDocuments = documents.filter((d) => d.category === 'address');

  useEffect(() => {
    updateTitle(t('register-final.final-check.header-title'));
    return () => updateTitle('');
  }, [t, updateTitle]);

  const renderIcon = (type) => {
    switch (type) {
      case 'face':
        return <FaceCaptureIcon />;
      case 'document':
        return <IdentityDocumentIcon />;
      case 'poa':
        return <AddressProofIcon />;
      default:
        return null;
    }
  };

  const handleShowOnfido = () => {
    handleNext(null, step, '/onfido');
  };

  if (view) {
    /**
     * TODO: Should only be poa set to true here but if that's not the case the Onfido steps will have to be divided
     * since the poa step can't be combined with the other steps in Onfido's web sdk.
     */
    return (
      <Onfido
        handleNext={handleNext}
        steps={{
          document: !documents.find((d) => d.type === 'document').uploaded,
          face: !documents.find((d) => d.type === 'face').uploaded,
          poa: !documents.find((d) => d.type === 'poa').uploaded,
        }}
        country={country}
      />
    );
  }
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.subtitle}>
          {t('register-final.final-check.subtitle')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" className={classes.itemTitle}>
          {t('identityCheck')}
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={3}>
        {identityDocuments.map((d) => (
          <Grid item xs={6} sm={3} key={d.type} container direction="column" justify="center" alignItems="center">
            <div className={classNames(classes.item, { [classes.itemProcessing]: d.uploaded })}>
              <div>
                {renderIcon(d.type)}
                <Typography variant="body2" component="span">
                  {d.uploaded ? t('processing') : t('required')}
                </Typography>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Typography variant="h3" className={classes.itemTitle}>
        {t('addressProof')}
      </Typography>

      <Grid item xs={12} container spacing={3}>
        {addressDocuments.map((d) => (
          <Grid item xs={6} sm={3} key={d.type} container direction="column" justify="center" alignItems="center">
            <div className={classNames(classes.item, { [classes.itemProcessing]: d.uploaded })}>
              <div>
                {renderIcon(d.type)}
                <Typography variant="body2" component="span">
                  {d.uploaded ? t('processing') : t('required')}
                </Typography>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button className={classes.button} onClick={handleShowOnfido}>
          {t('register-final.final-check.button-upload')}
        </Button>
      </Grid>
    </Grid>
  );
};

DocumentsCheck.propTypes = {
  handleNext: PropTypes.func.isRequired,
  updateTitle: PropTypes.func.isRequired,
  documents: PropTypes.array.isRequired,
  country: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
};

export default DocumentsCheck;
