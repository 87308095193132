import React, { useEffect } from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import { updateBackPath } from 'store/reducers/UI';
import TextCard from 'components/Cards/TextCard';
import DelayLink from 'components/DelayLink';
import { formatPrice } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  icon: {
    '& path': {
      fill: '#8e8e93',
      stroke: '#8e8e93',
    },
  },
  list: {
    backgroundColor: theme.palette.common.white,
    padding: 0,
  },
  listItem: {
    minHeight: 80,
  },
}));

const Index = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { plans } = useSelector((state) => state.plans);

  useEffect(() => {
    dispatch(updateBackPath({ path: '/user/transfer' }));
  }, [dispatch]);

  return (
    <>
      <TextCard text={t('user.transfer.top-up.index.title')} />
      <List className={classes.list}>
        {plans?.map((plan) => (
          <ListItem
            key={plan.id}
            className={classes.listItem}
            button
            divider
            component={DelayLink}
            to={`/user/transfer/top-up/${plan.id}`}
            transition="slide-right"
          >
            <ListItemText primary={plan.name} secondary={`${t('planValue')}: ${formatPrice(plan.value)}`} />
            <ListItemSecondaryAction>
              <ArrowRightIcon className={classes.icon} />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default Index;
