import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormControl, Grid, Select, TextField, Fab, InputLabel, makeStyles } from '@material-ui/core';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/ArrowDown.svg';
import { ReactComponent as PointerForwardIcon } from 'assets/icons/PointerForward.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    marginBottom: 20,
  },
  fab: {
    position: 'sticky',
    bottom: 10,
    marginLeft: 'auto',
    marginTop: 20,
    boxShadow: theme.shadows[4],
    '&:active': {
      boxShadow: 'none',
    },
  },
}));

const AddressForm = ({ handleNext, userData, updateUserData }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { countries } = useSelector((state) => state.misc);
  const [submitting, setSubmitting] = useState(false);
  const schema = useMemo(
    () =>
      yup.object().shape({
        line1: yup.string().required(t('form-validations.required')),
        line2: yup.string(),
        line3: yup.string(),
        city: yup.string().required(t('form-validations.required')),
        region: yup.string(),
        zipCode: yup.string().required(t('form-validations.required')),
        countryId: yup.string().required(t('form-validations.required')),
      }),
    [t]
  );

  const { register, handleSubmit, setValue, watch, errors, formState } = useForm({
    defaultValues: {
      line1: userData.line1,
      line2: userData.line2,
      line3: userData.line3,
      city: userData.city,
      region: userData.region,
      zipCode: userData.zipCode,
      countryId: userData.countryId,
    },
    resolver: yupResolver(schema),
  });
  const { isSubmitting } = formState;
  const countryCode = watch('countryId');

  const onSubmit = (data) => {
    setSubmitting(true);
    if (isSubmitting || submitting) return;
    updateUserData({
      ...userData,
      ...data,
    });

    handleNext(() => setSubmitting(false));
  };

  // eslint-disable-next-line
  useEffect(() => register('countryId'), []);

  const handleUpdateCountry = (ev) => {
    const countryCode = ev.target.value;
    if (['us', 'um'].includes(countryCode)) {
      // eslint-disable-next-line no-alert
      window.alert('Thanks but we can not accept US customers at this time');
    } else {
      setValue('country', countryCode);
    }
  };
  return (
    <Grid
      container
      component="form"
      direction="column"
      justify="space-between"
      onSubmit={handleSubmit(onSubmit)}
      className={classes.root}
    >
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="line1"
            required
            label={t('addressLineNumber', { number: 1 })}
            inputRef={register}
            type="text"
            error={Boolean(errors.line1)}
            helperText={errors.line1?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="line2"
            label={t('addressLineNumber', { number: 2 })}
            inputRef={register}
            type="text"
            error={Boolean(errors.line2)}
            helperText={errors.line2?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="line3"
            label={t('addressLineNumber', { number: 3 })}
            inputRef={register}
            type="text"
            error={Boolean(errors.line3)}
            helperText={errors.line3?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="city"
            required
            label={t('town/city')}
            inputRef={register}
            type="text"
            error={Boolean(errors.city)}
            helperText={errors.city?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="region"
            label={t('state/province/region')}
            inputRef={register}
            type="text"
            error={Boolean(errors.region)}
            helperText={errors.region?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="zipCode"
            required
            label={t('zip/postcode')}
            inputRef={register}
            type="text"
            error={Boolean(errors.zipCode)}
            helperText={errors.zipCode?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.countrySelect} fullWidth disabled>
            <InputLabel htmlFor="countryId">{t('country')} *</InputLabel>
            <Select
              native
              inputProps={{
                name: 'countryId',
              }}
              value={countryCode}
              onChange={handleUpdateCountry}
              IconComponent={ArrowDownIcon}
            >
              {countries.map((country) => (
                <option key={`country-${country.id}`} value={country.id}>
                  {country.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Fab color="primary" type="submit" size="small" className={classes.fab}>
        <PointerForwardIcon />
      </Fab>
    </Grid>
  );
};

AddressForm.propTypes = {
  userData: PropTypes.object.isRequired,
  updateUserData: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
};

export default AddressForm;
