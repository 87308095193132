import React from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import DelayLink from '../../components/DelayLink';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    padding: '20px 10px 30px',
    width: '100%',
    margin: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  hiddenIcon: {
    visibility: 'hidden',
  },
  buttonLabel: {
    justifyContent: 'space-between',
  },
}));

const Contact = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const userId = useSelector((state) => state.user.data.id);
  return (
    <Grid container spacing={2} className={classes.root}>
      {/*<Grid item xs={12}>
        <Button
          endIcon={<ArrowRightIcon />}
          startIcon={<ArrowRightIcon />}
          classes={{ label: classes.buttonLabel, startIcon: classes.hiddenIcon }}
        >
          <div>WhatsApp</div>
        </Button>
      </Grid>*/}
      <Grid item xs={12}>
        <Button
          endIcon={<ArrowRightIcon />}
          startIcon={<ArrowRightIcon />}
          classes={{ label: classes.buttonLabel, startIcon: classes.hiddenIcon }}
          component="a"
          href={`mailto:support@cocoainvest.com?subject=Support ticket from user with id: ${userId}`}
        >
          <div>{t('email')}</div>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          endIcon={<ArrowRightIcon />}
          startIcon={<ArrowRightIcon />}
          classes={{ label: classes.buttonLabel, startIcon: classes.hiddenIcon }}
          component={DelayLink}
          to="/user/info/faq"
          transition="slide-right"
        >
          <div>{t('FAQ')}</div>
        </Button>
      </Grid>
    </Grid>
  );
};

export default Contact;
