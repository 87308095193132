import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams, Redirect } from 'react-router-dom';

import { updateBackPath, setGoBackOverride, unsetGoBackOverride } from 'store/reducers/UI';
import useDelayHistory from 'hooks/useDelayHistory';
import { useStepValidation } from 'hooks/useStepValidation';
import useTransition from 'hooks/useTransition';
import Transition from 'components/Transition';

import SetAmount from './create/SetAmount';
import Details from './create/Details';
import Finished from './create/Finished';
import { useCreatePlanTransaction } from 'store/reducers/plans';
import LinearProgress from 'components/UI/CustomLinearProgress';

const Create = () => {
  const history = useHistory();
  const delayHistory = useDelayHistory();
  const { planId } = useParams();
  const { state } = useLocation();
  const { plans, fetched } = useSelector((state) => state.plans);
  const { currencies } = useSelector((state) => state.misc);
  const [
    { loading: creatingTransaction, value: valueTransaction, error: errorTransaction },
    createPlanTransaction,
  ] = useCreatePlanTransaction();
  const dispatch = useDispatch();
  const { toggleAnimation, showTransition, delay } = useTransition();
  const [done, setDone] = useState(false);
  const [data, setData] = useState({
    amount: 100,
    currency: currencies.length > 0 ? currencies[0].code : undefined,
  });

  const step = history.location.state?.step ?? 0;
  const steps = [
    (handleNext) => <SetAmount handleNext={handleNext} data={data} updateData={setData} />,
    (handleNext) => <Details handleNext={handleNext} data={data} updateData={setData} />,
    (handleNext) => <Finished handleNext={handleNext} data={data} />,
  ];
  const { stepsValidated, validateStep } = useStepValidation(steps.length);

  const handleNext = async (callback = () => {}, customStep) => {
    if (!customStep && step + 1 === steps.length) {
      setDone(true);
    } else {
      toggleAnimation(() => {
        if (typeof callback === 'function') callback();
        validateStep(step);
        let nextStep = step + 1;
        if (customStep) {
          nextStep = customStep;
        }
        if (typeof nextStep === 'number') {
          dispatch(nextStep > 0 ? setGoBackOverride() : unsetGoBackOverride());
        }
        history.push(`/user/transfer/top-up/${planId}`, { step: nextStep });
      });
    }
  };

  useEffect(() => {
    if (done && !creatingTransaction && !valueTransaction && !errorTransaction) {
      createPlanTransaction(planId, data.amount, data.currency).catch(() => {
        setDone(false);
      });
    } else {
      setDone(false);
    }
  }, [
    delayHistory,
    done,
    data,
    planId,
    creatingTransaction,
    valueTransaction,
    errorTransaction,
    createPlanTransaction,
  ]);

  useEffect(() => {
    if (valueTransaction) {
      delayHistory.push(`/user/plans`);
    } else if (errorTransaction) {
      console.log('error', errorTransaction);
    }
  }, [delayHistory, valueTransaction, errorTransaction, planId]);

  useEffect(() => {
    if (step > 0 && !stepsValidated[step]) {
      history.push(`/user/transfer/top-up/${planId}`);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { from } = state ?? {};
    dispatch(updateBackPath({ path: from ?? '/user/transfer/top-up' }));
    // eslint-disable-next-line
  }, [dispatch]);

  if (!fetched) return <LinearProgress />;

  if (fetched && !plans.find((p) => p.id === planId)) {
    return <Redirect to="/user/transfer/top-up" from={window.location.pathname} />;
  }

  return (
    <Transition show={showTransition} delay={delay}>
      {steps[step](handleNext)}
    </Transition>
  );
};

export default Create;
