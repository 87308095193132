import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { updateBackPath } from 'store/reducers/UI';
import PlanPerformanceCard from 'components/Cards/PlanPerformanceCard';
import ButtonGroup from 'components/ButtonGroup';
import useDelayHistory from 'hooks/useDelayHistory';
import { fetchPlanAsync } from 'transfer/user/plansApi';
import Investments from './show/Investments';
import Overview from './show/Overview';
import Activity from './show/Activity';

const PlansShow = () => {
  const delayHistory = useDelayHistory(false);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { planId, view } = useParams();
  const views = ['overview', 'activity', 'investments'];
  const [plan, setPlan] = useState(); // undefined for fetching, null for no result

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateBackPath({ path: '/user/plans' }));
  }, [dispatch]);

  useEffect(() => {
    if (planId && plan === undefined) {
      fetchPlanAsync(planId)
        .then((plan) => {
          setPlan(plan);
        })
        .catch(() => {
          setPlan(null);
        });
    }
  }, [planId, plan]);

  if (plan === undefined) return null;
  if (plan === null) return <Redirect to="/user/plans" from={window.location.pathname} />;

  const onButtonClick = (button) => {
    if (pathname.includes(button.view)) return;
    delayHistory.push(`/user/plans/${planId}/${button.view}`);
  };

  const buttons = views.map((view) => ({ name: t(view), view }));

  return (
    <>
      <PlanPerformanceCard
        totalValue={plan.value}
        totalGrowth={plan.totalGrowth}
        performancePercent={plan.totalTimeWeightedReturnPercent}
        subtitle={plan.name}
      />
      <ButtonGroup
        buttons={buttons}
        handleOnClick={onButtonClick}
        selectedButton={views.includes(view) ? views.indexOf(view) : 0}
      />
      <Switch>
        <Route
          exact
          path="/user/plans/:planId/overview"
          component={() => <Overview plan={plan} handleUpdatePlan={setPlan} />}
        />
        <Route exact path="/user/plans/:planId/activity" component={() => <Activity plan={plan} />} />
        <Route
          exact
          path="/user/plans/:planId/investments"
          component={() => <Investments portfolio={plan.portfolio} />}
        />
      </Switch>
    </>
  );
};

export default PlansShow;
