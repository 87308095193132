import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { updateBackPath, setGoBackOverride, unsetGoBackOverride } from 'store/reducers/UI';
import useDelayHistory from 'hooks/useDelayHistory';
import { useStepValidation } from 'hooks/useStepValidation';
import useTransition from 'hooks/useTransition';
import Transition from 'components/Transition';
import SetAmount from './create/SetAmount';
import Summary from './create/Summary';
import Finished from './create/Finished';
import LinearProgress from 'components/UI/CustomLinearProgress';
import { useSellPlanShares } from 'store/reducers/plans';
import { useTranslation } from 'react-i18next';

const Create = () => {
  const history = useHistory();
  const delayHistory = useDelayHistory();
  const { t } = useTranslation();
  const { planId } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { plans, fetched } = useSelector((state) => state.plans);
  const [
    { loading: sellingShares, value: valueSell, error: errorSell },
    sellPlanShares,
    resetSellReqState,
  ] = useSellPlanShares();
  const { toggleAnimation, showTransition, delay } = useTransition();
  const [done, setDone] = useState(false);
  const [data, setData] = useState({
    amount: 100,
    password: '',
  });

  const errorSellType = errorSell?.response?.data?.type;
  const plan = plans?.find((p) => p.id === planId);
  const step = history.location.state?.step ?? 0;
  const steps = [
    (handleNext) => <SetAmount handleNext={handleNext} data={data} updateData={setData} plan={plan} />,
    (handleNext) => <Summary handleNext={handleNext} data={data} plan={plan} />,
    (handleNext) => (
      <Finished
        handleNext={handleNext}
        data={data}
        plan={plan}
        updateData={setData}
        error={errorSellType === 'invalid_password' ? t(`error-messages.${errorSellType}`) : false}
      />
    ),
  ];
  const { stepsValidated, validateStep } = useStepValidation(steps.length);

  const handleNext = async (callback = () => {}, customStep) => {
    if (!customStep && step + 1 === steps.length) {
      resetSellReqState();
      setDone(true);
    } else {
      toggleAnimation(() => {
        if (typeof callback === 'function') callback();
        validateStep(step);
        let nextStep = step + 1;
        if (customStep) {
          nextStep = customStep;
        }
        if (typeof nextStep === 'number') {
          dispatch(nextStep > 0 ? setGoBackOverride() : unsetGoBackOverride());
        }
        history.push(`/user/transfer/withdraw/${planId}`, { step: nextStep });
      });
    }
  };

  useEffect(() => {
    if (done && !sellingShares && !valueSell && !errorSell) {
      sellPlanShares(planId, data.amount, data.password).catch(() => {
        setDone(false);
      });
    }
  }, [delayHistory, done, data, planId, sellPlanShares, valueSell, errorSell, sellingShares]);

  useEffect(() => {
    if (valueSell) {
      delayHistory.push(`/user/plans`);
    } else if (errorSell) {
      console.log('error', errorSell);
    }
  }, [delayHistory, valueSell, errorSell]);

  useEffect(() => {
    if (step > 0 && !stepsValidated[step]) {
      history.push(`/user/transfer/withdraw/${planId}`);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { from } = state ?? {};
    dispatch(updateBackPath({ path: from ?? '/user/transfer/withdraw' }));
  }, [dispatch, state]);

  if (!fetched) return <LinearProgress />;

  if (fetched && !plan) {
    return <Redirect to="/user/transfer/top-up" from={window.location.pathname} />;
  }

  return (
    <Transition show={showTransition} delay={delay}>
      {steps[step](handleNext)}
    </Transition>
  );
};

export default Create;
