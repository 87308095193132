import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NavPrompt from 'components/NavPrompt';

import { useStepValidation } from 'hooks/useStepValidation';
import useTransition from 'hooks/useTransition';
import Transition from 'components/Transition';
import { occupations } from 'utils/enums';
import { PLATFORM, REGISTER_TOP_BAR_HEIGHT } from 'utils/constants';
import { redirectUser } from 'utils/helpers';
import { useUpdateUser } from 'store/reducers/user';
import RegisterTopBar from 'components/Layout/RegisterTopBar';

import DocumentVerification from './identityVerification/DocumentVerification';
import Intro from './identityVerification/Intro';
import Permissions from './identityVerification/Permissions';
import LegalCompliance from './identityVerification/LegalCompliance';
import AdditionalQuestions from './identityVerification/AdditionalQuestions';
import ProofOfAddress from './identityVerification/ProofOfAddress';
import { useSetAppSafeArea } from 'hooks/useSetAppSafeArea';
import { useSetAppNotchColor } from 'hooks/useSetAppNotchColor';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.contrastText,
    height: '100vh',
  },
  content: {
    minHeight: `calc(100vh - ${REGISTER_TOP_BAR_HEIGHT}px)`,
    width: '100vw',
    paddingTop: REGISTER_TOP_BAR_HEIGHT,
  },
}));

const IdentityVerification = () => {
  const classes = useStyles();
  const { view } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  useSetAppSafeArea(true);
  useSetAppNotchColor('#FFFFFF');
  const [, updateUser] = useUpdateUser();
  const [disabled, setDisabled] = useState(false);
  const [done, setDone] = useState(false);
  const userData = useSelector((state) => state.user.data);
  const { toggleAnimation, showTransition, delay } = useTransition();
  const { legalCompliance } = userData.client;
  const [data, setData] = useState({
    nationalityCountryId: userData.client.nationality?.id ?? userData.client?.address?.country?.id,
    countryOfBirthId: userData.client.countryOfBirth?.id ?? userData.client?.address?.country?.id,
    occupation: userData.client.occupation ?? Object.values(occupations)[0],
    profession: userData.client.profession ?? '',
    showLegalCompliance: Boolean(
      legalCompliance?.politicallyExposed ||
        legalCompliance?.controlPersonOfPublicCompany ||
        legalCompliance?.affiliatedWithBrokerage
    ),
    politicallyExposed: Boolean(legalCompliance?.politicallyExposed),
    controlPersonOfPublicCompany: Boolean(legalCompliance?.controlPersonOfPublicCompany),
    affiliatedWithBrokerage: Boolean(legalCompliance?.affiliatedWithBrokerage),
  });
  const buildNumber = useSelector((state) => state.misc.buildNumber);
  const platform = useSelector((state) => state.misc.platform);

  const step = history.location.state?.appStep ?? 0;
  const onfidoStep = history.location.state?.step;

  // Add Permissions step for newer builds
  // Allow buildnNumber ==== 0 to circumvent issues with build number not getting set
  let steps;
  if ((buildNumber > 25 || buildNumber === 0) && platform !== PLATFORM.WEB) {
    steps = [
      (handleNext) => <Intro handleNext={handleNext} />,
      (handleNext) => (
        <AdditionalQuestions handleNext={handleNext} data={data} updateData={setData} setDisabled={setDisabled} />
      ),
      (handleNext) => (
        <LegalCompliance handleNext={handleNext} data={data} updateData={setData} setDisabled={setDisabled} />
      ),
      (handleNext) => <Permissions handleNext={handleNext} />,
      (handleNext, handleBack) => <DocumentVerification handleNext={handleNext} handleBack={handleBack} />,
    ];
  } else {
    steps = [
      (handleNext) => <Intro handleNext={handleNext} />,
      (handleNext) => (
        <AdditionalQuestions handleNext={handleNext} data={data} updateData={setData} setDisabled={setDisabled} />
      ),
      (handleNext) => (
        <LegalCompliance handleNext={handleNext} data={data} updateData={setData} setDisabled={setDisabled} />
      ),
      (handleNext, handleBack) => <DocumentVerification handleNext={handleNext} handleBack={handleBack} />,
    ];
  }

  const { stepsValidated, validateStep, invalidateStep } = useStepValidation(steps.length);
  const onfidoDocStepIndex = steps.length - 1;
  const onfidoPoaStepIndex = steps.length;

  const handleNext = (callback = () => {}, customStep, customView) => {
    if (!customStep && step + 1 === steps.length) {
      setDone(true);
    } else {
      toggleAnimation(() => {
        if (typeof callback === 'function') callback();
        validateStep(step);
        const nextStep = customStep ?? step + 1;
        const view = customView ?? (nextStep === onfidoDocStepIndex ? '/onfido-doc' : '');
        history.push(`/identity-verification${view}`, {
          appStep: nextStep,
        });
      });
    }
  };

  const handleBack = () => {
    if (step === 0 && !onfidoStep) {
      history.goBack();
    } else {
      toggleAnimation(() => {
        invalidateStep(step);
        history.goBack();
      });
    }
  };

  useEffect(() => {
    redirectUser(history.push, userData);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function updateAndRedirectUser() {
      const newUserData = await updateUser();
      if (newUserData) {
        redirectUser(history.push, newUserData);
      }
    }
    if (done) {
      setDone(false);
      updateAndRedirectUser();
    }
    // eslint-disable-next-line
  }, [done]);

  useEffect(() => {
    if (
      (view === 'onfido-doc' && !stepsValidated[onfidoDocStepIndex]) ||
      (view === 'onfido-poa' && !stepsValidated[onfidoPoaStepIndex]) ||
      (step > 0 && !stepsValidated[step])
    ) {
      history.push('/identity-verification');
    }
    // eslint-disable-next-line
  }, []);

  const [progressPercent, setProgressPercent] = useState(step / steps.length);
  useEffect(() => {
    if (typeof step === 'number') {
      setProgressPercent((step + 1) / steps.length);
    }
  }, [step, steps]);

  const renderStep = (step) => {
    if (view === 'onfido-doc') return <DocumentVerification handleNext={handleNext} handleBack={handleBack} />;
    if (view === 'onfido-poa')
      return (
        <ProofOfAddress
          handleNext={handleNext}
          handleBack={handleBack}
          countryOfResidence={userData.client.address.country.isO3}
          step={step}
        />
      );
    return steps[step](handleNext, handleBack);
  };

  const hideBackButton =
    (!['onfido-doc', 'onfido-poa'].includes(view) && step === onfidoDocStepIndex + 1) ||
    step === onfidoPoaStepIndex + 1 ||
    step === 0; // step + 1 === steps.length ||;

  return (
    <Grid container className={classes.root}>
      <NavPrompt
        when={(crntLocation, nextLocation) =>
          step !== 0 &&
          !nextLocation.pathname.startsWith('/register/final') &&
          (!nextLocation || !nextLocation.pathname.startsWith('/identity-verification'))
        }
      />
      <RegisterTopBar
        title={t('identityVerification')}
        handleBack={handleBack}
        hideBackBtn={hideBackButton}
        disabled={disabled}
        progressValue={{ min: 50, max: 75, percent: progressPercent }}
      />
      <Grid item className={classes.content}>
        <Transition show={showTransition} delay={delay}>
          {renderStep(step)}
        </Transition>
      </Grid>
    </Grid>
  );
};

export default IdentityVerification;
