import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Typography, Fab, Grid, Select, FormControl, InputLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { occupations } from 'utils/enums';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PointerForwardIcon } from 'assets/icons/PointerForward.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/ArrowDown.svg';
import { useUpdateFinancialInfo, useUpdateTaxInfo } from 'store/reducers/user';
import ProgressFab from 'components/ProgressFab';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: 0,
    padding: 30,
  },
  input: {
    '& select, input': {
      fontSize: 16,
      color: `${theme.palette.accent.darkBlue} !important`,
    },
    '& path': {
      stroke: theme.palette.accent.silver,
    },
    '& label': {
      color: `${theme.palette.accent.silver} !important`,
    },
    '& option': {
      color: 'black',
    },
    '& ::before': {
      borderColor: `${theme.palette.accent.silver} !important`,
    },
    '& ::after': {
      borderColor: `${theme.palette.accent.silver} !important`,
    },
  },
  nextButton: {
    marginTop: 20,
  },
  fab: {
    boxShadow: 'none',
    '&:active': {
      boxShadow: 'none',
    },
  },
}));

const AdditionalQuestions = ({ handleNext, data, updateData, setDisabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const countries = useSelector((state) => state.misc.countries);
  const [{ loading: updatingFinancial }, updateFinancialInfo] = useUpdateFinancialInfo();
  const [{ loading: updatingTax }, updateTaxInfo] = useUpdateTaxInfo();

  const handleUpdateSelect = (ev) => {
    updateData({
      ...data,
      [ev.target.name]: ev.target.value,
    });
  };

  const handleNextStep = async (ev) => {
    ev.preventDefault();
    setDisabled(true);
    const taxRes = await updateTaxInfo(data);
    const financialRes = await updateFinancialInfo({
      ...data,
      occupation: parseInt(data.occupation, 10),
    });
    setDisabled(false);
    if (taxRes && financialRes) {
      handleNext();
    }
  };

  return (
    <Grid container className={classes.root} spacing={3} component="form" onSubmit={handleNextStep}>
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          A few more questions
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.input} fullWidth disabled={updatingFinancial || updatingTax}>
          <InputLabel htmlFor="nationalityCountryId">{t('nationality')}</InputLabel>
          <Select
            native
            inputProps={{
              name: 'nationalityCountryId',
            }}
            value={data.nationalityCountryId}
            onChange={handleUpdateSelect}
            IconComponent={ArrowDownIcon}
          >
            {countries.map((country) => (
              <option key={`country-${country.id}`} value={country.id}>
                {country.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.input} fullWidth disabled={updatingFinancial || updatingTax}>
          <InputLabel htmlFor="countryOfBirthId">{t('countryOfBirth')}</InputLabel>
          <Select
            native
            inputProps={{
              name: 'countryOfBirthId',
            }}
            value={data.countryOfBirthId}
            onChange={handleUpdateSelect}
            IconComponent={ArrowDownIcon}
          >
            {countries.map((country) => (
              <option key={`country-${country.id}`} value={country.id}>
                {country.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.input} fullWidth disabled={updatingFinancial || updatingTax}>
          <InputLabel htmlFor="occupation">{t('occupation')}</InputLabel>
          <Select
            native
            inputProps={{
              name: 'occupation',
            }}
            value={data.occupation}
            onChange={handleUpdateSelect}
            IconComponent={ArrowDownIcon}
          >
            {Object.entries(occupations).map(([occupation, value]) => (
              <option key={occupation} value={value}>
                {t(`occupations.${occupation}`)}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.input}
          label={t('profession')}
          name="profession"
          value={data.profession}
          disabled={updatingFinancial || updatingTax}
          onChange={handleUpdateSelect}
          required={[occupations.Employed, occupations.FinanciallyIndependent, occupations.SelfEmployed].includes(
            parseInt(data.occupation, 10)
          )}
        />
      </Grid>
      <Grid item xs={12} container justify="flex-end">
        <ProgressFab loading={updatingFinancial || updatingTax} className={classes.nextButton}>
          <Fab
            color="primary"
            type="submit"
            size="small"
            className={classes.fab}
            disabled={updatingFinancial || updatingTax}
          >
            <PointerForwardIcon />
          </Fab>
        </ProgressFab>
      </Grid>
    </Grid>
  );
};

AdditionalQuestions.propTypes = {
  handleNext: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  setDisabled: PropTypes.func.isRequired,
};

export default AdditionalQuestions;
