import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as PointerForwardIcon } from 'assets/icons/PointerForward.svg';
import { useTranslation, Trans } from 'react-i18next';
import { DelayFab } from 'components/DelayButtons';
import { REGISTER_TOP_BAR_HEIGHT } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.accent.pinkLady,
    padding: 20,
    paddingTop: '12vh',
    height: `calc(100vh - ${REGISTER_TOP_BAR_HEIGHT}px - 80px)`,
  },
  title: {
    marginBottom: 50,
  },
  footer: {
    padding: 20,
    boxShadow: theme.shadows[4],
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  },
}));

const Intro = ({ handleNext }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={classes.content} container>
        <div>
          <Typography variant="h2" className={classes.title}>
            {t('plan.create.intro.title')}
          </Typography>
          <Typography variant="body1" component="div">
            <Trans i18nKey="plan.create.intro.body" />
          </Typography>
        </div>
      </Grid>
      <Grid item container alignItems="flex-end" direction="column" className={classes.footer}>
        <DelayFab color="primary" onClick={() => handleNext()} size="small">
          <PointerForwardIcon />
        </DelayFab>
      </Grid>
    </Grid>
  );
};

Intro.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default Intro;
