export default {
  primary: {
    contrastText: '#FFF',
    dark: '#1E6557',
    main: '#298070',
    light: '#5db09e',
  },
  background: {
    default: '#F8F9F9',
    paper: '#FFF',
  },
  secondary: {
    contrastText: 'rgba(0, 0, 0, 0.87)',
    dark: '#9b9b9b',
    main: '#FFF ',
    light: '#cccccc',
  },
  accent: {
    pinkLady: '#F5D6BA',
    rockBlu: '#77869e',
    seaMist: '#cbd7cb',
    gainsboro: '#e0dfdf',
    melanie: '#e5c3c7',
    regentBlue: '#A4C5D8',
    darkBlue: '#042c5c',
    silver: '#b8b8b8',
    red: '#dc1207',
    orange: '#f5a623',
    paleBlue: '#a4c5d8',
  },
};
