import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemSecondaryAction, Grid, Typography, makeStyles, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EditIcon } from 'assets/icons/Edit.svg';
import { ReactComponent as TopupIcon } from 'assets/icons/TopupIcon.svg';
import { ReactComponent as WithdrawIcon } from 'assets/icons/WithdrawIcon.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';

import CustomLinearProgress from 'components/UI/CustomLinearProgress';
import { formatPrice } from 'utils/helpers';
import DelayLink from 'components/DelayLink';
import PlanGoalDialog from 'components/Dialogs/PlanGoalDialog';
import useToggle from 'hooks/useToggle';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  title: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    fontSize: 14,
    fontWeight: 500,
    padding: '20px 10px 5px',
    textTransform: 'uppercase',
  },
  list: {
    padding: 0,
    backgroundColor: 'white',
  },
  listItem: {
    minHeight: 50,
  },
  iconDisabled: {
    '& path': {
      fill: `${theme.palette.divider} !important`,
    },
  },
  arrowIconDisabled: {
    '& path': {
      stroke: `${theme.palette.divider} !important`,
    },
  },
  valueText: {
    fontSize: 13,
    fontWeight: 400,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
  },
  targetAmountTitle: {
    marginBottom: 5,
  },
  editButton: {
    border: '1px solid #888',
    padding: 2,
    marginLeft: 10,
    '& svg': {
      stroke: theme.palette.primary.main,
    },
  },
  targetAmount: {
    marginBottom: 5,
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    '& path': {
      fill: 'rgba(0, 0, 0, 0.87)',
    },
  },
  arrowIcon: {
    '& path': {
      stroke: 'rgba(0, 0, 0, 0.87)',
    },
  },
  notActiveMessage: {
    padding: '4px 8px',
    backgroundColor: '#F3F4F6',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const Overview = ({ plan, handleUpdatePlan }) => {
  const classes = useStyles();
  const [showEditGoal, toggleShowEditGoal] = useToggle();
  const { t } = useTranslation();
  const { plans } = useSelector((state) => state.plans);
  const { investmentLimits } = useSelector((state) => state.misc);
  const totalValue = plans?.reduce((acc, curr) => acc + curr.value, 0);
  const feeFraction = investmentLimits?.managementFees?.find(
    (f) => totalValue >= f.rangeStart && totalValue <= f.rangeEnd
  )?.feeFraction;
  console.log(totalValue, feeFraction);
  const progress = plan.targetValue === 0 ? 0 : 100 * (plan.value / plan.targetValue);
  return (
    <>
      <PlanGoalDialog
        key={`plan-goal-dialog-${plan.id}-${plan.targetValue}`}
        open={showEditGoal}
        handleClose={toggleShowEditGoal}
        handleUpdateCurrentPlan={handleUpdatePlan}
        plan={plan}
      />
      <Typography variant="h6" className={classes.title}>
        {t('details')}
      </Typography>
      <List className={classes.list}>
        <ListItem divider className={classes.listItem}>
          <Grid container justify="space-between">
            {plan.targetValue === 0 ? (
              <Grid item>
                <Typography className={classes.targetAmountTitle}>{t('yourTargetAmount')}</Typography>
                <Typography variant="subtitle2">
                  <span>{t('noTargetSet')}</span>
                  <IconButton className={classes.editButton} onClick={toggleShowEditGoal}>
                    <EditIcon />
                  </IconButton>
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item>
                  <Typography className={classes.targetAmountTitle}>{t('yourTargetAmount')}</Typography>
                  <Typography variant="subtitle2" className={classes.targetAmount}>
                    <span>{formatPrice(plan.targetValue)}</span>
                    <IconButton className={classes.editButton} onClick={toggleShowEditGoal}>
                      <EditIcon />
                    </IconButton>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CustomLinearProgress value={progress > 100 ? 100 : progress} variant="determinate" />
                </Grid>
                <Grid item xs={12} container justify="space-between">
                  <Grid item>{formatPrice(0)}</Grid>
                  <Grid item>
                    <Typography>{formatPrice(plan.targetValue)}</Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </ListItem>
        <ListItem divider className={classes.listItem}>
          <Grid container justify="space-between">
            <Grid item>{t('name')}</Grid>
            <Grid item className={classes.valueText}>
              {plan.name}
            </Grid>
          </Grid>
        </ListItem>
        {/* <ListItem divider className={classes.listItem}>
          <Grid container justify="space-between">
            <Grid item>{t('planType')}</Grid>
            <Grid item className={classes.valueText}>
              {plan.type}
            </Grid>
          </Grid>
        </ListItem> */}
        <ListItem divider className={classes.listItem}>
          <Grid container justify="space-between">
            <Grid item>{t('investmentStyle')}</Grid>
            <Grid item className={classes.valueText}>
              {t(`portfolios.${plan.portfolio?.name?.toLowerCase()}`)}
            </Grid>
          </Grid>
        </ListItem>
        {feeFraction && (
          <ListItem divider className={classes.listItem}>
            <Grid container justify="space-between">
              <Grid item>{t('cocoaMgtFee')}</Grid>
              <Grid item className={classes.valueText}>
                {(feeFraction * 100).toFixed(2)}%
              </Grid>
            </Grid>
          </ListItem>
        )}
      </List>
      <Typography variant="h6" className={classes.title}>
        {t('manage')}
      </Typography>
      <List className={classes.list}>
        <ListItem
          divider
          className={classes.listItem}
          button
          component={DelayLink}
          to={`/user/transfer/top-up/${plan.id}`}
          transition="slide-right"
        >
          {t('topUp')}
          <ListItemSecondaryAction>
            <TopupIcon className={classes.icon} />
          </ListItemSecondaryAction>
        </ListItem>
        {!plan.isActive && (
          <Typography className={classes.notActiveMessage}>
            {t('user.plans.overview.actions-disabled-due-to-inactive-status', {
              count: plan?.paymentTransactions.length ?? 0,
            })}
          </Typography>
        )}
        <ListItem
          divider
          className={classes.listItem}
          button
          component={DelayLink}
          disabled={!plan.isActive}
          to={`/user/transfer/withdraw/${plan.id}`}
          transition="slide-right"
        >
          {t('withdraw')}
          <ListItemSecondaryAction>
            <WithdrawIcon className={classNames(classes.icon, { [classes.iconDisabled]: !plan.isActive })} />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          divider
          className={classes.listItem}
          button
          disabled={!plan.isActive}
          component={DelayLink}
          to={`/user/plans/close/${plan.id}`}
          transition="slide-right"
        >
          {t('closePlan')}
          <ListItemSecondaryAction>
            <ArrowRightIcon
              className={classNames(classes.arrowIcon, { [classes.arrowIconDisabled]: !plan.isActive })}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
};

Overview.propTypes = {
  plan: PropTypes.object.isRequired,
  handleUpdatePlan: PropTypes.func,
};

export default Overview;
